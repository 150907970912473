@use '../common/colors';
@use "../common/typography";

.sales-trend-line-chart-view-widget {
  .chart-title {
    @include typography.h2-headline;
    color: colors.$blue-gray-900;
    padding: 24px 0 32px 0;
  }

  .recharts-responsive-container {
    margin: 0 auto;

    .line-chart {
      .recharts-surface {
        // this displays the full text of the last x-axis label
        overflow: visible;

        .recharts-xAxis {
          .x-axis-title {
            @include typography.body-2-500;
            font-weight: 400;
            text-align: center;
          }
        }

        .recharts-brush {
          .recharts-brush-slide {
            color: red;
            :active {
              fill-opacity: 1;
            }
          }
          .recharts-brush-traveller {
            display: none;
          }

          .recharts-brush-texts {
            display: none;
          }
        }
      }

      .recharts-tooltip-wrapper {
        .tooltip {
          border-radius: 8px;
          width: fit-content !important;
          box-shadow: 0px 2px 16px 0px #0000001f;
          padding: 12px 13.5px;
          text-align: center;
          background-color: colors.$white;
          transform: translate(-50%);
          border: 1px solid colors.$blue-gray-100;

          .sales {
            @include typography.h1-headline;
            color: colors.$blue-gray-900;
            padding-bottom: 4px;
          }

          .period {
            @include typography.caption-1-400;
            color: colors.$blue-gray-500;
          }
        }
      }
    }
  }
}
