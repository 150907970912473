@use "../common/colors";
@use "../common/elements";
@use "../common/payment-summary";

.linkly-payment-summary-widget {
  > .header {
    display: flex;
    align-items: center;
    cursor: pointer;

    > .label {
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      color: colors.$blue-gray-900;
    }

    > .icon {
      margin-left: 5px;
      @include elements.icon;
    }
  }

  > .transaction-infos {
    &:not(:first-child) {
      margin-top: 12px;
    }

    > .payment-transaction-info {
      @include payment-summary.payment;
    }

    > .refund-transaction-info {
      @include payment-summary.payment;
    }
  }
}
