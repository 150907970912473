@use "../common/typography";
@use "../common/colors";
@use "../common/elements";

.cart-header-info-widget {
  display: flex;
  align-items: center;

  .item {
    display: flex;

    &.table-details {
      flex-basis: 50%;
    }

    > .icon {
      padding: 0px 7px 0px 13px;
      @include elements.icon;
    }
  
    > .label {
      @include typography.h5;
      font-weight: 400;
      color: colors.$white;
      margin-left: 8px;
      word-break: break-word;
    }
  }
}