@use '../common/order-view';
@use '../common/colors';

@mixin _button {
  width: 100%;
  padding: 14px;
  border: solid 1px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.website.order-view-widget {
  @include order-view.container;
  @include order-view.content;

  .website-order-view-actions-widget {
    display: grid;
    margin-top: auto;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;

    &.refundable:not(.cancelled) {
      > .print-order-button {
        grid-column: 1 / 2;
      }

      > .refund-order-button {
        grid-column: 2 / 2;
      }
    }

    &.cancelled {
      > .refund-order-button {
        grid-column-start: 1;
        grid-row-start: 1;
      }
    }

    > * {
      grid-column: 1 / -1;
      @include _button;
      margin-top: 16px;
    }

    > .accept-order-button {
      border-color: colors.$teal-600;
      background-color: colors.$teal-600;
      color: colors.$white;
    }

    > .make-payment-button {
      border-color: colors.$teal-600;
      background-color: colors.$teal-600;
      color: colors.$white;
    }

    > .edit-order-button {
      border-color: colors.$blue-gray-100;
      background-color: colors.$blue-gray-100;
      color: colors.$blue-gray-900;
    }

    > .print-order-button {
      background-color: transparent;
      border-color: colors.$blue-gray-500;
      color: colors.$blue-gray-500;
    }

    > .refund-order-button {
      background-color: transparent;
      border-color: colors.$blue-gray-500;
      color: colors.$blue-gray-500;
    }

    > .reject-order-button {
      background-color: transparent;
      border-color: transparent;
      color: colors.$rose-800;
      text-decoration: underline;
    }

    > .cancel-order-button {
      background-color: transparent;
      border-color: transparent;
      color: colors.$rose-800;
      text-decoration: underline;
    }

    > .reverse-transaction-button {
      border-color: colors.$blue-gray-100;
      background-color: colors.$blue-gray-100;
      color: colors.$blue-gray-900;
    }
  }

  &.loading {
    display: block;
  }
}
