@use '../common/order-history-list-item.scss';
@use '../common/colors';
@use '../common/ghost';
@use "../common/constants";
@use "../common/typography";

.uber-eats.order-history-list-item-widget {
  padding: 0 24px 16px;

  .order-details-info {
    @include order-history-list-item.container;
    @include order-history-list-item.content;
  }

  .orders-list-item-collection-info {
    .past-pickup-time {
      color: colors.$rose-800;
    }
  }

  .orders-list-item-order-status {
    &.to-be-confirmed {
      @include ghost.yellow;
    }

    &.accepted,
    &.handed-off,
    &.delivered {
      @include ghost.green;
    }

    &.failed,
    &.cancelled,
    &.rejected,
    &.unknown {
      @include ghost.red;
    }
  }

  .orders-list-item-store-name {
    margin-left: 0;
  }
}
