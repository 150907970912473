@use '../common/colors';

@mixin _header-content {
  display: flex;
  align-items: center;
  padding: 24px 24px;

  .icon {
    margin-right: 12px;
  }

  .label {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: colors.$white;
  }
}

.uber-eats-order-delivery-status-widget {
  .completed-status {
    border-radius: 16px;
    background-color: colors.$green-500;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 2px 1px 0px rgba(0, 0, 0, 0.05);

    @include _header-content();
  }

  .cancelled-status {
    .header {
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      background-color: colors.$rose-500;

      @include _header-content();
    }

    .info {
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 2px 1px 0px rgba(0, 0, 0, 0.05);

      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.08px;
      color: colors.$blue-gray-900;
      padding: 15px 25px;
    }
  }
}
