@use '../common/colors' as colors;

.table-booking-activity-logs-widget {
  display: grid;
  .activity-logs-button {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: colors.$blue-gray-900;
    margin-left: 26px;

    svg {
      transform: rotate(-90deg);
    }

    &.close {
      svg {
        transform: rotate(0);
      }
    }

    span {
      margin-left: 16px;
    }
  }
}
