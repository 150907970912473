@use '../common/colors';
@use '../common/typography';
@use '../common/drop-down-input';
@use '../forms/form';

.store-filter-widget {
  position: absolute;
  top: 20px;
  right: 456px;
  z-index: 6;
  max-width: 500px;
  width: 35vw;

  .form-item {
    display: flex;
  }

  .form-label {
    @include typography.body-2-400;
    color: colors.$blue-gray-500;
    padding: 9.5px 16px;
    min-width: fit-content;
  }

  .form-input {
    @include form.form-input;
    @include typography.body-2-400;
    border: 1px solid colors.$blue-gray-100;
    color: colors.$blue-gray-900;
    min-width: 123px;
    max-width: 400px;
    width: 100%;
  }

  .drop-down-input {
    @include drop-down-input.form-drop-down-input;
    display: flex;
    justify-content: space-between;
    position: relative;

    .value {
      @include typography.body-2-400;
      color: colors.$blue-gray-900;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .options {
      position: absolute;
      margin-top: 15px;
      top: 100%;
      left: -15px;
      right: -15px;
      width: auto;
    }

    .option {
      border-bottom: 1px solid colors.$blue-gray-100;
      padding: 10px 12px;

      > .option-label {
        overflow: hidden;
        display: block !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
        max-width: 89%;
      }

      > img {
        position: absolute;
        right: 12px;
      }
    }

    .down-arrow-icon {
      margin: 0;
    }
  }
}
