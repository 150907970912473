@use '../common/colors' as colors;
@use "../common/typography" as typography;

.table-booking-activity-logs-list-item-widget {
  .field {
    border-bottom: 1px solid colors.$blue-gray-100;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  }

  .date {
    @include typography.h5-medium;
  }

  .employee {
    text-transform: capitalize;
  }

  .old-value {
    @include typography.h5-medium;
  }

  .new-value {
    @include typography.h5-medium;
  }
}
