@use '../common/colors' as colors;
@use '../common/constants' as constants;

.date-range-picker-widget {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: max-content max-content;
  .start-date-picker,
  .end-date-picker {
    display: grid;
    grid-gap: 12px;
    .value {
      border-radius: constants.$border-radius;
      border: 1px solid #{colors.$blue-gray-100};
      color: #{colors.$blue-gray-600};
      padding: 10px 14px;
      cursor: pointer;
    }
  }

  .start-date-picker {
    grid-area: start-date-picker;
  }
  .end-date-picker {
    grid-area: end-date-picker;
  }
}

.date-range {
  &.end-date-picker,
  &.start-date-picker {
    padding: 24px;
    display: grid;
    grid-gap: 32px;
  }
}
