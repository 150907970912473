@use './notification-widget';
@use '../common/colors' as colors;

.cancelled-order-notification-widget {
  @extend %notification-widget;
  background-color: #{colors.$orange-600};
  color: #{colors.$white};
  // 'Approx width of the notification' - 'sidebar width'
  // 244px - 70px
  left: -174px;
  .link {
    padding: 0;
    margin: 0;
  }

  .cancelled-order-notification-warning-icon {
    padding: 16px 18px 16px 23px;
  }

  .count {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    padding-right: 16px;
  }
}
