@use "../common/typography";
@use "../common/colors";
@use "../common/cta";
@use "../common/default-theme";

.split-equally-count-select-widget {
  width: 60vw;
  max-width: 900px;
  padding: 60px 48px;
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
  place-items: center;

  .title {
    @include typography.h1;
    // margin-bottom: 0;
    text-align: center;
    text-transform: capitalize;
  }

  .quantity {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    place-items: center;

    .button {
      width: 100%;
      height: 248px;
      cursor: pointer;
      display: grid;
      place-items: center;

      .btn {
        width: 56px;
        height: 56px;
        text-align: center;
        border-radius: 50%;
        display: grid;
        justify-content: center;
        align-items: center;
      }

      .icon {
        color: colors.$white;
        box-shadow: 0px 3px 10px -3px rgb(0 0 0 / 30%);

        i {
          font-size: 30px;
        }
      }

      .dec {
        @extend .btn;
        background: colors.$blue-gray-700;
        padding: 5px 8px;
      }

      .inc {
        @extend .btn;
        background: colors.$teal-500;
        padding: 5px 8px;
      }
    }

    .number {
      font-weight: 500;
      font-size: 120pt;
      line-height: 164px;
      text-align: center;
    }
  }

  .actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    margin-top: 10px;

    button {
      width: 100%;
      &.confirm {
        @include cta.primary-cta;
        border-radius: 4px;
      }

      &.back {
        @include cta.link-cta;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
        border-radius: 4px;
      }
    }
  }
}
