@use '../common/typography';
@use '../common/buttons';
@use '../common/colors';
@use '../common/constants';

@mixin header {
  .title {
    @include typography.h2;
    margin-bottom: 8px;
  }

  .description {
    @include typography.body-2-400;
    color: colors.$blue-gray-700;
  }
}

@mixin container {
  border: 1px solid colors.$blue-gray-100;
  border-radius: constants.$border-radius;
  padding: 20px 24px;
}

.anz-worldline-settings-widget {
  display: grid;
  gap: 32px;

  .terminal-connection-settings {
    > .header {
      @include header;
    }

    > .body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 32px 0px;

      // TODO centralise form styles
      form {
        margin-right: 64px;

        // form input section

        .form-items {
          display: unset;

          .form-item {
            label {
              position: absolute;
              top: 8px;
              left: 12px;
              margin: 0;
              color: #{colors.$blue-gray-500};
            }

            .form-input input {
              border: 1px solid colors.$blue-gray-100;
              border-radius: constants.$border-radius-medium;
              padding: 31px 0px 8px 12px;
            }

            .form-input input .invalid {
              border: 1px solid colors.$rose-800 !important;
            }

            .meta .error {
              font-size: 14px;
              color: colors.$rose-800;
            }

            ::placeholder {
              color: colors.$blue-gray-200;
            }
          }

          .meta {
            @include typography.caption-1-400;
            color: colors.$blue-gray-500;
            margin: 8px 0 0 0;
          }
        }

        .form-buttons {
          margin-top: 16px;

          button {
            border-radius: constants.$border-radius-small;
          }
        }

        // info section

        .info {
          @include container;

          .key-value-pair {
            display: grid;
            grid-template-columns: 154px max-content;
            gap: 4px;
            color: colors.$blue-gray-900;

            &.disconnected {
              color: colors.$blue-gray-300;
            }

            &.connected {
              color: colors.$teal-600;
            }

            .label {
              color: colors.$blue-gray-500;
            }
          }
        }
      }
    }
  }

  .transaction-settings {
    > .header {
      @include header;
    }

    > .body {
      .setting {
        @include container;
        display: grid;
        grid-template-areas:
          'label action'
          'description action'
          'meta action';
        grid-template-columns: 1fr min-content;
        margin-bottom: 12px;

        > .label {
          @include typography.body-2-500;
          color: colors.$blue-gray-900;
          grid-area: label;
          margin-bottom: 4px;
        }

        .description {
          @include typography.body-3-400;
          color: colors.$blue-gray-700;
          grid-area: description;
        }

        .meta {
          @include typography.caption-2-400;
          color: colors.$blue-gray-500;
          grid-area: meta;
        }

        .action {
          grid-area: action;
          display: flex;
          align-items: center;

          button {
            @include buttons.secondary-outlined-medium;
            display: unset;
          }
        }
      }
    }
  }
}
