@use "../common/typography" as typography;
@use "../common/constants" as constants;
@use "../common/cta" as cta;
@use "../common/scrollbar" as scrollbar;
@use '../common/colors' as colors;
@use "../common/default-theme" as default-theme;
@use "../common/card" as card;
@use "../common/start-order-iso-date-picker" as iso-date-picker;
@use "../common/start-order-time-of-day-picker" as time-of-day-picker;

.customer-info-and-requested-for-edit-widget {
  height: 90vh;
  min-width: 75vw;
  max-width: 92vw;
  display: grid;
  grid-template-rows: repeat(2, min-content) 1fr min-content;
  z-index: 100;
  overflow: scroll;
  @include scrollbar.hide;

  &.start-order {
    height: unset;
    min-width: unset;
    max-width: unset;

    &:not(.start-order-customer-info-card) .main-panel {
      padding: constants.$modal-horizontal-gap;
    }

    .iso-date-start-order-picker-container {
      @include iso-date-picker.start-order-iso-date-picker;
    }

    .time-of-day-start-order-picker-container {
      @include time-of-day-picker.start-order-time-of-day-picker;
    }

    &.start-order-customer-info-card {
      @include card.container;
      @include scrollbar.hide;
      display: grid;
      width: 900px;
      min-width: unset;
      max-width: unset;
      height: calc(100vh - 74px);
      margin-bottom: 0;
      box-shadow: 0px 0px 3px rgba(46, 47, 77, 0.05), 0px 10px 25px rgba(89, 88, 123, 0.15);
      border-radius: 20px;
      overflow: auto;
    }
  }

  > .header {
    padding: constants.$modal-horizontal-gap;
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;

    .collection-type {
      width: 150px;
      .icon {
        vertical-align: middle;
      }
      > .label {
        vertical-align: middle;
        margin-left: 10px;
        display: inline;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        color: #{colors.$blue-gray-500};
      }
    }

    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #{colors.$blue-gray-900};
      margin-left: -150px;
    }
  }

  .main-panel {
    padding: 0 constants.$modal-horizontal-gap;

    .requested-for-selector {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 16px;
      gap: 12px;

      .requested-for-asap,
      .request-another-time {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 12px 16px;
        background: colors.$modifier-gradient-bg;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.1);
        border-radius: 6px;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        cursor: pointer;

        &.selected {
          background: colors.$blue-gray-900;

          > .label {
            color: colors.$white;
          }
        }
      }

      /**
       * Checkbox icon
       */
      .requested-for-asap,
      .request-another-time {
        position: relative;

        .selected-icon {
          position: absolute;
          top: -6px;
          right: -8px;
          z-index: 2;
        }
      }

      .request-another-time {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        z-index: 999;

        > .label {
          text-align: center;
          flex-grow: 1;
        }

        > .request-another-time-options {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          background-color: colors.$white;
          border: solid 1px colors.$blue-gray-100;
          border-radius: 4px;

          > .option {
            padding: 10px 16px;
            border-bottom: 1px solid colors.$blue-gray-100;

            display: flex;
            align-items: center;

            img {
              margin-right: 14px;
            }

            // Remove bottom margin if last option
            &:last-child {
              border-bottom: 0;
            }

            &.selected {
              background-color: colors.$light-gray-100;
              font-weight: 500;
            }
          }
        }

        .drop-down-icon {
          position: absolute;
          right: 16px;
        }
      }
    }

    .suburb-list {
      height: inherit;
    }
    .empty-collection-times {
      height: inherit;
      width: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #{colors.$blue-gray-500};
    }

    .iso-date-picker,
    .time-of-day-picker {
      > .iso-date-picker-widget,
      .time-of-day-list-widget {
        grid-template-rows: unset;

        > .header {
          display: none;
        }
      }
    }
  }

  .actions {
    display: flex;
    grid-gap: 10px;
    height: fit-content;
    align-self: self-end;
    padding: constants.$modal-horizontal-gap;

    > button {
      flex-basis: 0;
      flex: 1 1 0px;
    }

    button.done-button,
    button.start-order-button {
      @include cta.primary-cta;
    }
    button.cancel-button,
    button.back-button {
      @include cta.link-cta;
      border: 2px solid colors.$blue-gray-300;
    }
  }
}
