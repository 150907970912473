@use "../common/colors";
@use '../common/typography';
@use "../common/scrollbar";
@use '../common/drop-down-input';

.period-coverage-form-iso-date-picker-input {
  &.date-range-end-date {
    > .DayPickerInput {
      > .DayPickerInput-OverlayWrapper {
        left: -22px;
      }
    }
  }

  > label {
    @include typography.caption-2-400;
    color: colors.$blue-gray-500;
  }

  > .DayPickerInput {
    padding-top: 8px;
    
    input {
      background-image: url('../images/icon-calendar.svg');
      background-repeat: no-repeat;
      background-position: 95% center;
      @include typography.caption-1-400;
      padding: 13.5px 14px;
      border-radius: 8px;
      min-width: 200px;

      &:focus {
        outline: none;
      }
    }

    > .DayPickerInput-OverlayWrapper {
      top: inherit;
      margin-top: 8px;

      > .DayPickerInput-Overlay {
        box-shadow: none;
        border: 1px solid colors.$blue-gray-100;

        > .DayPicker {
          margin-top: 40px;

          > .DayPicker-wrapper {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            padding: 0;
            position: relative;

            > .DayPicker-NavBar {
              position: absolute;
              right: 0;
              top: -40px;

              > .DayPicker-NavButton {
                width: 24px;
                height: 24px;
                background-repeat: no-repeat;
                background-size: 24px;
                background-position: center;

                &--prev {
                  background-image: url('../images/icon-chevron-down-filled.svg');
                  transform: rotate(90deg);
                }

                &--next {
                  right: 10px;
                  background-image: url('../images/icon-chevron-down-filled.svg');
                  transform: rotate(270deg);
                }
              }
            }

            .DayPicker-Month {
              table-layout: fixed;
              border-spacing: 14px 12px;
              border-collapse: separate;
              margin: 0;
              @include typography.body-2-500;

              .month-year-selectors {
                position: absolute;
                top: -28px;
                display: flex;

                .selector {
                  &.month-selector {
                    margin-right: 12px;
                  }

                  .form-label {
                    @include typography.h2;
                    color: colors.$blue-gray-500;
                  }

                  .drop-down-input {
                    @include typography.body-2-500;
                    @include drop-down-input.form-drop-down-input;
                    padding: 6px 8px;
                    border-radius: 8px;
                    color: colors.$blue-gray-900;
                    background-color: colors.$light-gray-100;
                    font-weight: 600;
                    text-align: center;

                    .value {
                      padding: 0;
                    }

                    .down-arrow-icon {
                      display: none;
                    }

                    .options {
                      margin-top: 5px;
                      max-height: 280px;
                      min-width: 200px;
                      overflow-y: scroll;
                      @include scrollbar.hide;
                      
                      .option {
                        font-weight: 500;
                      }
                    }
                  }
                }
              }

              .DayPicker-Weekday {
                color: colors.$blue-gray-500;
                @include typography.body-2-500;
              }

              .DayPicker-Day {
                color: colors.$blue-gray-900;
                @include typography.h4;
                font-weight: 500;

                &:hover {
                  background-color: unset !important;
                }

                &--disabled {
                  color: colors.$blue-gray-100;
                }

                &--selected {
                  background-color: transparent;
                  color: colors.$teal-500;
                }
              }
            }
          }

          &-Day {
            color: colors.$blue-gray-900;
            @include typography.h4;

            &:hover {
              background-color: unset !important;
            }

            &--disabled {
              color: colors.$blue-gray-100;
            }

            &--selected {
              background-color: transparent;
              color: colors.$teal-500;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1160px) {
  .period-coverage-form-iso-date-picker-input {
    &.date-range-end-date {
      > .DayPickerInput {
        > .DayPickerInput-OverlayWrapper {
          left: unset;
        }
      }
    }
  }
}

