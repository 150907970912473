@use "../common/typography" as typography;
@use "../common/default-theme" as default-theme;
@use "../common/cta" as cta;

.pos-grand-total-edit-widget {
  padding: 24px;
  .title {
    @include typography.h1;
    padding: 0 146px;
    margin-bottom: 36px;
  }

  .custom-grand-total-input-box {
    border-radius: 8px;
    display: grid;
    padding: 12.75px 20px;
    grid-template-columns: min-content 1fr;
    border: 1px solid #{default-theme.$form-element-border};

    .icon {
      @include typography.h1;
      padding-top: 10px;
    }

    .custom-grand-total-input {
      display: grid;
      align-items: center;

      input {
        outline: none;
        border: none;
        background: none;
        text-align: right;
        font-size: 25px;
        font-weight: normal;
        font-style: normal;
        line-height: 35px;
        overflow: hidden;
        width: 100%;
      }

      /*--- Hide number-type input spin box ---*/
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }
      /*--------------------------------------*/
    }
  }

  .summary {
    margin-top: 24px;
    background: #{default-theme.$secondary-bg};
    border-radius: 8px;
    @include typography.h5;
    font-weight: 400;
    color: #{default-theme.$secondary-fg};

    .info,
    .new-grand-total {
      padding: 16px;
    }

    .info > div,
    .new-grand-total {
      display: flex;
      justify-content: space-between;
    }

    .discount,
    .surcharge {
      margin-top: 8px;
    }

    .discount .amount {
      color: #{default-theme.$secondary};
    }

    .surcharge .amount {
      color: #{default-theme.$primary};
    }

    .new-grand-total {
      @include typography.h3;
      font-weight: 400;
      color: #{default-theme.$fg};
      border-top: 1px solid #{default-theme.$border-light};
    }
  }

  .actions {
    margin-top: 24px;
    display: grid;
    grid-auto-flow: column;
    gap: 12px;

    button {
      width: 100%;
    }

    .confirm-button {
      @include cta.primary-cta;
    }

    .cancel-button {
      @include cta.link-cta;
      border: 1px solid #{default-theme.$secondary-fg};
    }
  }
}
