@use "../common/scrollbar" as scrollbar;
@use "../common/typography" as typography;
@use "../common/colors" as colors;
@use "../common/constants" as constants;
@use "../common/cta" as cta;

.table-bookings-list-widget {
  color: #{colors.$blue-gray-900};
  padding: 25px 24px;

  .header {
    display: grid;
    align-items: center;
    grid-template-columns: 3fr max-content 1fr;
    margin-bottom: 30px;

    .title {
      @include typography.h1;
    }

    .add-new-table-booking-button {
      @include cta.secondary-cta;
      @include typography.button-1;
      margin: 0px 26px 0px 29.75px;
      border-radius: 5px;
      border: 1px solid colors.$teal-600;
      color: colors.$teal-600 !important;
      width: fit-content;
      padding: 13px 32.5px;
      letter-spacing: 0.5%;
    }

    input {
      @include typography.p;
      border: none;
      padding: 10px 12px 10px 12px;
      border-radius: constants.$border-radius;
      outline: none;
      box-shadow: 0px 3px 15px -5px rgb(0 0 0 / 30%);
      text-indent: 30px;
      background-image: url('../images/icon-search.svg');
      background-repeat: no-repeat;
      background-position: 15px center;
      width: 400px;
    }
  }

  .no-table-bookings {
    text-align: center;
    margin-top: 20vh;
  }

  .table-bookings-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
    align-items: center;
    border-radius: constants.$border-radius;
    column-gap: 10px;

    li {
      background-color: #{colors.$blue-gray-50};
      list-style: none;
      text-align: center;
      cursor: pointer;
      padding: 14px;
      font-size: 16px;
      font-weight: 400px;
      border-radius: constants.$border-radius;
      color: colors.$blue-gray-500;
    }

    li:nth-child(2) {
      border-radius: 0px;
      border-radius: constants.$border-radius;
    }

    li.active {
      box-shadow: colors.$card-shadow;
      background-color: #343553;
      color: colors.$white;
    }
  }

  .table-bookings {
    display: grid;
    grid-auto-rows: min-content;

    .wrapper {
      border-radius: 8px;
      display: grid;
      border: 1px solid colors.$blue-gray-100;
      grid-auto-rows: min-content;
    }

    .date-group {
      > .date {
        @include typography.h2;
        margin-top: 30px;
      }
    }

    .end-list {
      text-align: center;
      margin-top: 20px;
      color: #{colors.$blue-gray-300};
    }
  }

  .back-to-top-button {
    position: fixed;
    top: 90vh;
    right: 50px;
    padding: 13px;
    background-color: colors.$white;
    border-radius: 30px;
    box-shadow: 0 0 3px #687a9b40;
  }

  .load-more-button {
    margin: auto;
    margin-top: 20px;
    background-color: colors.$blue-gray-700;
    color: colors.$white;
    padding: 15px 110px;
    border-radius: 5px;
  }

  .end-of-the-list {
    margin-top: 16px;
    @include typography.p;
    font-size: 14px;
    text-align: center;
    color: colors.$blue-gray-500;
  }
}
