@use "../common/colors";

.removable-tag {
  background-color: colors.$green-50;
  color: colors.$teal-700;
  width: max-content;
  border-radius: 6px;
  padding: 4px 8px;
  font-weight: 500;
  display: flex;
  margin: 3px;

  .remove-button {
    padding-left: 6px;
    cursor: pointer;
  }
}
