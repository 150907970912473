@use "../common/typography";
@use "../common/cta";
@use "../common/constants";
@use "../common/colors";
@use "../common/elements";

.docket-type-select-widget {
  display: flex;
  flex-direction: column;
  width: 60vw;
  max-width: 900px;
  padding: 40px 32px 32px;
  margin: 0 auto;
  border-radius: constants.$border-radius;
  color: colors.$blue-gray-900;

  > .label {
    text-align: center;
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  > .docket-types {
    padding: 20px 0;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);

    > .docket-type {
      line-height: 27px;
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;
      border: 1px solid colors.$blue-gray-100;
      border-radius: constants.$border-radius-medium;
      display: grid;
      place-items: center;
      padding: 40px;
      box-shadow: 0 0 24px 0 rgba($color: #000000, $alpha: 0.08);

      &:last-child {
        margin-right: 0;
      }

      > .icon {
        @include elements.icon(48px);
        margin: auto;
        margin-bottom: 20px;
      }
    }
  }

  > .actions {
    margin-top: 20px;
    padding: 0 24px;

    > .back-button {
      width: 100%;
      @include cta.tertiary-cta;
      color: colors.$blue-gray-700;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      border-radius: constants.$border-radius-small;
    }
  }
}
