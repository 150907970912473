@use "../common/colors";

.dine-in-guest-count-covers-report-widget {
  .covers-charts {
    width: 100%;
    margin-top: 24px;
    border-radius: 8px;
    border: 1px solid colors.$blue-gray-100;
    padding: 24px 32px;
    background: colors.$modifier-gradient-bg-gray;
    gap: 24px;
    box-sizing: border-box;

    .recharts-responsive-container {
      width: 100% !important;
      height: 500px;
      min-width: 0px;
      margin-left: -24px;
    }
  }

  .covers-trend-table-view-widget {
    .covers-trend-table {
      .header-row {
        .header-cell {
          background-color: transparent;
          border-color: transparent;
        }
      }
      
      .table-row,
      .odd-table-row {
        background-color: colors.$white;
      }
    }
  }

  .covers-report-table-overview-widget {
    margin-top: 24px;

    .covers-table {
      .covers-header {
        .covers-header-cell {
          width: 50%;

          &:first-child {
            text-align: left;
            padding-left: 24px;
          }

          &:last-child {
            text-align: right;
            padding-right: 24px;
          }
        }
      }

      .covers-row {
        .covers-cell {
          width: 50%;

          &:first-child {
            text-align: left;
            padding-left: 24px;
          }
  
          &:last-child {
            text-align: right;
            padding-right: 24px;
            color: colors.$blue-gray-900;
          }
        }
      }
    }
  }
}