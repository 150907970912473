@use "../common/colors";
@use "../common/buttons";

@mixin page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon {
    height: 96px;
    aspect-ratio: 1/1;
  }

  .title {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    color: colors.$blue-gray-900;
    padding-top: 30px;
  }

  .body {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: colors.$blue-gray-500;
    padding-top: 16px;
    width: 60vh;
    text-align: center;
  }

  button {
    @include buttons.primary-large;
    margin: 30px 0 60px;
    padding: 14px 32px;
    color: colors.$white;

    &.logout-button {
      background-color: colors.$rose-800;
      border: 1px solid colors.$rose-800;
    }
  }

  a {
    color: colors.$blue-800;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}