@use '../common/scrollbar' as scrollbar;

.customer-reviews-page {
  height: 100vh;
  display: grid;
  grid-template-rows: min-content 1fr;
  @include scrollbar.hide;
  overflow: scroll;

  > .header {
    margin: 0;

    .wrapper {
      border-bottom: none;
      width: calc(100vw - 70px);
      padding: 20px 24px 0;
    }
  }

  > .body {
    height: 100%;
  }
}
