@use '../common/colors';
@use '../common/gap';

@mixin _button {
  width: 100%;
  padding: 14px 32px;
  border: solid 1px;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 14px;
}

.invalid-items-widget {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  max-width: 573px;
  max-height: 590px;
  overflow: hidden;

  > .header {
    margin: 24px 32px;
    text-align: center;

    > .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
    }

    > .description {
      margin-top: 12px;

      color: colors.$blue-gray-500;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  > .main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 24px 32px;
    overflow-y: auto;

    > .items {
      @include gap.vertical(24px);
      flex-grow: 1;
      overflow-y: auto;
      padding: 12px;
      border: 1px solid colors.$blue-gray-100;
      border-radius: 8px;
      margin-top: 8px;
    }
  }

  > .actions {
    display: grid;
    grid-template-areas:
      'go-back-button      accept-order-button'
      'reject-order-button reject-order-button';
    gap: 12;
    margin: 24px 32px;

    > .go-back-button {
      grid-area: go-back-button;
      @include _button;
      border: 1px solid colors.$blue-gray-500;
      background-color: transparent;
      color: colors.$blue-gray-700;
    }

    > .accept-order-button {
      grid-area: accept-order-button;
      @include _button;
      border: 1px solid colors.$teal-600;
      background-color: colors.$teal-600;
      color: colors.$white;
    }

    > .reject-order-button {
      grid-area: reject-order-button;
      @include _button;
      text-decoration: underline;
      border: none;
      background-color: transparent;
      color: colors.$rose-800;
    }
  }
}
