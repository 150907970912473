@use "../common/colors";
@use '../common/typography';
@use '../common/elements';
@use '../common/drop-down-input';

.swal2-container {
  .invalid-date-and-time-range-warning {
    padding: 0 !important;

    .swal2-content {
      padding: 0 !important;
    }
  
    .swal2-html-container {
      color: colors.$blue-gray-700;
    }
  }
}

.period-coverage-filter-widget {
  .date-time-range-label {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 0 24px;

    .label {
      font-size: 14px;
      color: colors.$blue-gray-700;
      border-bottom: 1px solid colors.$blue-gray-700;
      line-height: 0.9;
    }

    .chevron {
      @include elements.icon(24px);
      margin-left: 5px;
      cursor: pointer;
    }
  }
  
  .time-range-form {
    position: relative;
    z-index: 5;
    padding: 0 24px;
    margin-top: 16px;
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    overflow: hidden;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;

    &.date-time-range {
      .form-items {
        background-color: colors.$blue-gray-50;
        border-radius: 8px;
        padding: 12px;
      }

      .time-range-selector {
        background-color: unset;
        border-radius: unset;
        padding: unset;

        .form-input {
          padding: 8px 24px 0px 0px;
        }
      }
    }

    &.show {
      transform: scaleY(1);
      overflow: visible;
      opacity: 1;
    }

    .form-items {
      display: grid;
      grid-template-columns: 1.2fr repeat(2, 1fr) auto repeat(2, 1fr);
      grid-gap: 10px;
      margin-bottom: 24px;
    }

    .time-range-selector { 
      background-color: colors.$blue-gray-50;
      border-radius: 8px;
      align-self: end;
      padding: 12px;

      .form-label {
        @include typography.caption-2-400;
        color: colors.$blue-gray-500;
      }
  
      .form-input {
        padding: 8px 0px 0px 0px;
      }
  
      .drop-down-input {
        @include typography.body-2-500;
        @include drop-down-input.form-drop-down-input;
        background-color: colors.$white;
        min-width: 200px;
        padding: 12px 14px;
        border: 1px solid colors.$blue-gray-100;
        border-radius: 8px;
        z-index: 1;
  
        .value {
          padding: 0;
        }
  
        .down-arrow-icon {
          margin: 0;
        }
  
        .options {
          margin-top: 5px;
  
          .option {
            border-bottom: none;
          }
        }
      }
    }

    .start-date, .end-date {
      label {
        @include typography.caption-2-400;
        color: colors.$blue-gray-500;
      }
    }

    .start-time, .end-time {
      align-self: end;

      .time-input {
        background-color: colors.$white;
      }
    }

    .date-range-separator {
      align-self: end;
      padding-bottom: 15px;
    }
  }
}

@media screen and (max-width: 1160px) {
  .period-coverage-filter-widget {
    .time-range-form {
      &.show {
        max-height: 210px;
      }

      .form-items {
        grid-template-columns: 1.2fr repeat(2, 1fr) auto;
      }
    }
  }
}
