@use "../common/viewport";
@use "../common/constants";
@use "../common/item-styles";
@use "../common/item-option-styles";
@use "../common/colors";

.basic-item-widget {
  &.with-customization {
    @include item-styles.item-widget;
    max-width: 1200px;
    .info-panel {
      @include item-styles.info-panel;
      .description > .text.hide {
        display: none;
      }
      .spec-info {
        @include item-styles.spec-info;
      }

      .summary {
        padding-top: 12px;

        .item-description-view-widget {
          color: colors.$blue-gray-500;
        }
      }

      .empty-summary {
        @include item-styles.empty-info-panel-summary;
      }
    }

    .main-panel {
      @include item-styles.main-panel;

      > .modifiers {
        @include item-option-styles.modifiers;
      }
    }
  }

  &.without-customization {
    @include item-styles.info-panel;
    padding: 60px 160px 30px 160px;
    border-radius: 20px;
    max-height: 90vh;

    .summary {
      display: none;
    }

    .spec-info {
      margin-bottom: 24px;
      width: 430px;
      .title {
        color: colors.$blue-gray-900;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 14px;
      }
      .description {
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
      }
    }

    .actions {
      .quantity {
        height: 48px;
        padding: 10px 14px;
        > h4 {
          font-size: 15px;
          font-style: normal;
          font-weight: normal;
          line-height: 21px;
        }
        .inc,
        .dec {
          width: 28px;
          height: 28px;
          * {
            display: flex;
            align-items: center;
          }
        }
        .number {
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          line-height: 22.4px;
          text-align: center;
          width: 34px;
          margin: 0 10px;
        }
      }
      .add-to-cart {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        padding: 14px;
      }
      .cancel {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        padding: 4px;
      }
    }
  }
}
