@use "../common/viewport" as viewport;
@use "../common/typography" as typography;
@use "../common/default-theme" as default-theme;
@use "../common/constants" as constants;

.search-widget {
  margin-bottom: 20px;
  display: grid;

  .inputbox {
    @include typography.p;
    padding: 8px;
    height: 44px;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    border-radius: 8px;
    outline: none;
    box-shadow: 0px 3px 15px -5px rgb(0 0 0 / 30%);
    text-indent: 30px;
    background-image: url('../images/icon-search.svg');
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: 12px center;
    min-width: 112.59px;

    &:focus {
      outline: 2px solid #{default-theme.$primary};
    }
  }

  .clear-search-btn {
    margin-top: 10px;
  }
}
