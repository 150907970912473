@use "typography" as typography;
@use "buttons" as buttons;
@use "colors" as colors;
@use "default-theme" as default-theme;

.page {
  > .header {
    margin-bottom: 20px;

    .navigation {
      padding: 11.5px 0px 4px 30px;
      .back-button {
        cursor: pointer;
        column-gap: 12px;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
      }
    }

    .wrapper {
      display: grid;
      grid-template-areas:
        'title actions'
        'subtitle actions';
      justify-content: space-between;
      border-bottom: 1px solid #{default-theme.$border-light};
      padding: 20px 30px;
      align-items: center;
      width: calc(100vw - 84px);
    }

    .title {
      @include typography.h1;
      margin: 0;
      grid-area: title;
    }

    .subtitle {
      grid-area: subtitle;
      color: colors.$blue-gray-900;
      opacity: 0.6;
    }

    .actions {
      grid-area: actions;
      display: flex;

      > div:not(:last-child) {
        margin-right: 32px;
      }

      > .store-filter-widget {
        position: unset;
      }
    }
  }

  > .body {
    display: grid;
  }
}
