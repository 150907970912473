@use "../common/colors";

.employee-discount-pin-widget {
  .employee-pin-widget {
    padding: 25px 70px;

    .amount {
      font-weight: 500;
      padding-bottom: 6px;
    }

    .message {
      font-size: 16px;
    }

    .number-pad {
      margin-bottom: 0;
    }

    .cancel-button,
    .delete-button {
      color: colors.$blue-gray-700 !important;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }
}
