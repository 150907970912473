@use '../common/colors';
@use '../common/typography';

.order-source-filter-selector-widget {
  height: min-content;
  position: relative;

  .order-source-filter {
    label {
      @include typography.caption-2-400;
      color: colors.$blue-gray-500;
    }

    .form-input {
      padding-top: 5px;
      border-radius: 4px;

      input {
        max-width: 180px;
        @include typography.body-2-500;
        cursor: pointer;
        padding: 12px 14px;
        border: 1px solid colors.$blue-gray-100;
        border-radius: 8px;
        background-image: url('../images/icon-chevron-down-filled.svg');
        background-repeat: no-repeat;
        background-position: 95% center;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .order-source-filter-options {
    position: absolute;
    margin-top: 5px;
    width: 100%;
    border-radius: 8px;
    background-color: colors.$white;
    border: solid 1px colors.$blue-gray-100;
    font-weight: 400;
    @include typography.body-2-500;
    z-index: 1;

    > .option {
      cursor: pointer;
      padding: 12px;
      border-bottom: 1px solid colors.$blue-gray-100;

      &.selected {
        background-image: url('../images/icon-check-alt.svg');
        background-repeat: no-repeat;
        background-position: right 14px top 50%;
        background-size: 20px;
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }
  }
}
