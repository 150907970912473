@use '../common/colors';
@use '../common/buttons';

@mixin cancel-button {
  background: none;
  color: colors.$blue-gray-500;
  border: 1px solid colors.$blue-gray-500;
}

.alert-widget {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  text-align: center;
  align-items: center;
  width: 50vw;
  min-width: 357px;
  max-width: 573px;
  box-sizing: border-box;
  z-index: 1001;

  > .icon {
    width: 64px;
  }

  > .title {
    color: colors.$blue-gray-900;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 16px;
  }

  > .content {
    margin-top: 16px;
    color: colors.$blue-gray-700;
    font-size: 16px;
    font-weight: 500;
  }

  > .actions {
    width: 100%;
    height: min-content;
    margin-top: 24px;

    button {
      @include buttons.primary;
      font-size: 16px;
      font-weight: 500;
      border-radius: 4px;
      width: inherit;
    }
  }

  a {
    color: colors.$teal-600;
    text-decoration: none;
  }

  button {
    padding: 14px 32px;
  }

  &.retry,
  &.confirmation {
    width: 40vw;
    min-width: 573px;
    > .actions {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      .cancel-button {
        @include cancel-button();
      }
    }
  }

  &.success {
    .icon {
      width: unset;
    }
  }

  &.warning,
  &.info {
    max-width: 357px;

    > .title {
      font-size: 18px;
    }

    > .actions {
      display: grid;
      gap: 16px;

      .cancel-button {
        @include cancel-button();
      }
      .proceed-button {
        background-color: colors.$rose-800;
        color: colors.$white;
      }
    }
  }
}
