@use "../common/colors";

.customer-reviews-widget {
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 7px;

  .customer-reviews-list-info {
    margin-top: -122px;
    transition: margin 0.3s ease-out;

    &.show-date-time-filter {
      margin-top: 0;
    }
    
    .customer-review-bar {
      margin-bottom: 20px;
      padding: 24px 31px 2px;
      border-top: 1px solid colors.$blue-gray-100;
  
      .labels {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: colors.$blue-gray-100;
        
        .total-reviews-spinner-container {
          display: flex;
          align-items: center;
          flex-direction: row;

          .message {
            color: colors.$blue-gray-700;
            font-size: 14px;
            padding-left: 8px;
          }
        }
        
        .total-reviews {
          color: colors.$blue-gray-700;
        }
  
        .rating {
          font-size: 16px;
          color: colors.$blue-gray-900;
        }
  
        .sad-review-percentage, .happy-review-percentage {
          display: flex;
          align-items: center;
          
          span {
            font-weight: 600;
          }
  
          .divider {
            width: 1px;
            height: 24px;
            background-color: colors.$blue-gray-100;
            margin: 0 8px;
          }
  
          .percentage {
            font-weight: 600;
          }
        }
  
        .sad-review-percentage > .percentage {
          color: colors.$blue-500;
        }
  
        .happy-review-percentage > .percentage {
          color: colors.$yellow-800;
        }
      }
  
      .bar {
        margin: 15px 0px;
        display: flex;
  
        div {
          height: 8px;
        }
  
        .sad-review-bar {
          border-radius: 10px 0px 0px 10px;
          background-color: colors.$blue-500;
  
          &.full {
            border-radius: 10px;
          }
  
          &.no-reviews {
            background-color: colors.$blue-gray-100;
          }
        }
  
        .happy-review-bar {
          border-radius: 0px 10px 10px 0px;
          background-color: colors.$yellow-500;
  
          &.full {
            border-radius: 10px;
          }
  
          &.no-reviews {
            background-color: colors.$blue-gray-100;
          }
        }
      }
    }
  
    .rating-selector {
      display: flex;
      margin-bottom: 5px;
      padding: 0 24px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: colors.$blue-gray-500;
  
      > .rating {
        display: flex;
        flex: 1;
        padding: 12px 14px;
        margin: 0 4px;
        border-radius: 8px;
        background-color: colors.$blue-gray-50;
        text-align: center;
        justify-content: center;
        align-items: center;
        cursor: pointer;
  
        &:first-child {
          margin-left: 0;
        }
  
        &:last-child {
          margin-right: 0;
        }
  
        &.active {
          background-color: colors.$blue-gray-800;
          color: colors.$white;
        }
  
        img {
          margin-right: 8px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
