@use "../common/colors";
@use '../common/banner';
@use '../common/z-indexes';

.uber-eats-store-status-banner-widget {
  @include banner.base;
  z-index: z-indexes.$uber-eats-store-status-banner;
  background: colors.$rose-100;

  > .text > .highlight {
    color: colors.$rose-800;
  }

  button {
    @include banner.button;
  }
}
