@use "../common/colors";

.customer-reviews-list-widget {
  padding: 0 24px;
  flex-grow: 1;
  position: relative;

  .customer-reviews {
    .customer-sad-reviews-list {
      .customer-sad-reviews-list-item-widget {
        display: flex;
        border: 1px solid colors.$blue-gray-100;
        margin-top: 24px;
        border-radius: 8px;
  
        .info {
          padding: 16px;
          border-radius: 8px;
          background-color: colors.$light-gray-50;
          width: 25%;
  
          .customer-info {
            margin-bottom: 12px;
            border-bottom: 1px solid colors.$blue-gray-100;
  
            .name-and-phone-number {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 8px;
  
              .name {
                margin-right: 5px;
              }
  
              .phone-number {
                margin-left: 5px;
              }
  
              img {
                margin-left: 8.16px;
                width: 11.67px;
                height: 18.33px;
              }
            }
  
            .email {
              font-size: 14px;
              color: colors.$blue-gray-700;
              margin-bottom: 8px;
            }
          }
  
          .order-info {
            > div {
              font-size: 12px;
              margin-bottom: 8px;
              color: colors.$blue-gray-900;
              font-weight: 600;
  
              > span {
                color: colors.$blue-gray-700;
                font-weight: 400;
                margin-left: 8px;
              }
            }
          }
        }
  
        .customer-feedback {
          display: grid;
          grid-template-areas:
            'feedback     feedback'
            'additionalInfo action';
          width: 75%;
          padding: 16px 24px 16px 36px;
  
          .feedback {
            font-size: 16px;
            line-height: 24px;
            grid-area: feedback;
            overflow: hidden;
            max-height: calc(24px * 5); // line height * max number of lines
            transition: max-height 0.3s ease-out;
  
            &.expanded {
              max-height: 500px;
            }
          }
  
          .additional-info {
            grid-area: additionalInfo;
            margin-top: 14px;
            display: flex;
            align-items: center;
            font-size: 12px;
  
            .date-created {
              color: colors.$blue-gray-500;
              margin-right: 12px;
            }
  
            .review-source {
              color: colors.$blue-gray-700;
              padding: 4px 8px;
              border: 1px solid colors.$blue-gray-100;
              border-radius: 100px;
            }
          }
          
          .action {
            grid-area: action;
            display: flex;
            align-items: center;
            justify-self: end;
            font-size: 12px;
            margin-top: 14px;
            cursor: pointer;
            color: colors.$blue-800;
            font-weight: 600;
  
            img {
              &.up {
                transform: rotateX(180deg);
              }
            }
  
            span {
              margin-left: 10px;
            }
          }
        }
      }
    }
  
    .customer-happy-reviews-list {
      .labels {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr 1fr 0.5fr 0.8fr 0.5fr 0.8fr 0.8fr;
        align-items: center;
        gap: 12px;
        margin-top: 24px;
        padding: 12px 24px;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        white-space: nowrap;
        color: colors.$blue-gray-500;
      }
  
      .customer-happy-reviews-list-item-widget {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr 1fr 0.5fr 0.8fr 0.5fr 0.8fr 0.8fr;
        align-items: center;
        gap: 12px;
        padding: 12px 24px;
        border: solid 1px colors.$blue-gray-100;
        font-size: 12px;
        color: colors.$blue-gray-900;
  
        &:nth-child(2) {
          margin-top: 0;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
  
        &:nth-last-child(1) {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
  
        .customer-name-and-phone-number {
          font-size: 14px;
  
          .name {
            font-weight: 600;
          }
  
          .phone-number {
            color: colors.$blue-gray-500;
          }
        }
  
        .review-source {
          padding: 4px 8px;
          border: 1px solid colors.$blue-gray-100;
          color: colors.$blue-gray-700;
          border-radius: 100px;
          width: max-content;
        }
      }
    }
  }

  .no-reviews {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px colors.$blue-gray-100;
    border-radius: 8px;
    margin-top: 24px;
    height: 90%;
  
    > .label {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: colors.$blue-gray-700;
  
      margin-top: 8px;
    }
  }

  .back-to-top-button {
    position: fixed;
    top: 81vh;
    right: 20px;
    padding: 13px;
    background-color: colors.$white;
    border: 1px solid colors.$blue-gray-100;
    border-radius: 30px;
    box-shadow: 0 2px 8px #687a9b40;
  }
}

@media screen and (max-width: 1130px) {
  .customer-reviews-list-widget {
    .customer-reviews {
      .customer-sad-reviews-list {
        .customer-sad-reviews-list-item-widget {
          .customer-feedback {
            .feedback {
              max-height: calc(24px * 7);
            }
          }
        }
      }
    }
  }
}

