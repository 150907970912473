@use '../common/colors' as colors;
@use "../common/typography" as typography;

.table-booking-back-link-header-widget {
  background-color: colors.$white;
  margin-bottom: 16px;
  margin-top: 22px;
  border-bottom: 1px solid colors.$blue-gray-100;
  display: grid;

  .go-back-link {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    padding: 0 0 4px 0px;
    margin: 0px 30px;
    color: colors.$blue-gray-500;
    max-width: fit-content;

    > .arrow-left {
      margin-top: 3px;
      background-image: url('../images/icon-chevron-left.svg');
      background-repeat: no-repeat;
      background-position: center;
      width: 10px;
      height: 12px;
    }

    span {
      margin-left: 12px;
    }
  }

  .title {
    @include typography.h1;
    font-size: 24px;
    line-height: 29px;
    margin: 0px 26px;
    padding: 0px 0px 12px 0px;
  }
}
