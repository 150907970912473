@use "../common/colors";
@use '../common/banner';
@use '../common/z-indexes';

.service-worker-update-banner-widget {
  @include banner.base;
  z-index: z-indexes.$service-worker-update-banner;
  background: colors.$yellow-100;

  > .text > .highlight {
    color: colors.$rose-800;
  }
}
