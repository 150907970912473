$modal: 1000;
$toast: 10000;

$sidebar: 999;
$banner: 998;

// custom z-indexes
$uber-eats-store-status-banner: 997;
$audio-playback-banner: 996;
$service-worker-update-banner: 995;
$refresh-menu-banner: 994;
$uber-eats-manage-order-alert: 9999;
