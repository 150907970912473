@use "../common/colors" as colors;
@use "../common/typography" as typography;

.order-delivery-info-list-item-widget {
  display: grid;
  grid-template-columns: 2.5fr repeat(4, 1fr) 1.5fr;
  gap: 12px;
  align-items: center;

  border-bottom: 1px solid colors.$blue-gray-100;

  padding: 16px 24px;

  @include typography.h5;
  font-weight: 400;
  color: colors.$blue-gray-900;

  cursor: pointer;

  &.highlighted {
    border: 1px solid colors.$orange-500 !important;
    background: rgba(255, 251, 235, 0.5);

    // remove the top border of the next sibling
    // as it blocks the bottom orange border
    + div {
      border-top: none;
    }
  }

  &.delivery-complete,
  &.delivery-failed {
    grid-template-columns: 3fr repeat(3, 1fr) 1.5fr;
  }

  > .order-delivery-info-list-item-customer-info {
    .name {
      font-weight: 500;
      line-height: 24px;
    }

    .address,
    .mobile {
      color: colors.$blue-gray-500;
    }

    .mobile {
      font-weight: 500;
    }
  }

  > .order-delivery-info-list-item-grand-total {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0025em;
  }

  > .order-delivery-info-list-item-driver-status {
    width: max-content;
    padding: 4px 12px;
    border-radius: 100px;

    &.confirmed,
    &.in-transit-to-pickup,
    &.completed {
      background: colors.$green-50;
      color: colors.$green-700;
    }

    &.arrived-for-pickup {
      background: colors.$blue-100;
      color: colors.$blue-800;
    }

    &.out-for-delivery,
    &.arrived-at-dropoff {
      background: colors.$yellow-100;
      color: colors.$yellow-700;
    }

    &.failed {
      background: colors.$rose-100;
      color: colors.$rose-800;
    }
  }

  > .order-delivery-info-list-item-driver-eta {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0025em;
  }

  > .order-delivery-info-list-item-driver-info {
    display: grid;
    align-items: center;
    column-gap: 10px;
    grid-template-areas:
      'image    name'
      'image    vehicle'
      'image    mobile';
    grid-template-columns: max-content 1fr;

    img {
      grid-area: image;
      border-radius: 50%;
      width: 52px;
    }

    .name {
      grid-area: name;
      font-weight: 500;
      line-height: 24px;
    }

    .vehicle-type {
      grid-area: vehicle;
    }

    .mobile {
      grid-area: mobile;
    }

    .vehicle-type,
    .mobile {
      color: colors.$blue-gray-500;
    }
  }

  > .order-delivery-info-list-item-date-completed {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0025em;
  }
}
