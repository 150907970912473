@use "../common/constants";
@use "../common/default-theme";
@use "../common/typography";
@use '../common/elements';
@use "../common/colors";

@keyframes highlight {
  0%,
  100% {
    background-color: #{colors.$yellow-500}00;
  }

  50% {
    background-color: #{colors.$yellow-500}80;
  }
}

.cart-item-widget {
  display: flex;
  padding: 7px 12px;
  color: colors.$white;
  transition: all 0.2s ease-in-out;

  &.highlight {
    animation-name: highlight;
    animation-duration: 2s;
  }

  > div:first-child {
    min-width: 40px;
  }

  > div:last-child {
    min-width: 24px;
  }

  > .quantity {
    width: 40px;
    height: 24px;
    background-color: #{colors.$white}1a;
    border-radius: constants.$border-radius-small;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
  }

  > .more-actions-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    background-color: #{colors.$white}1a;
    border-radius: constants.$border-radius-small;
    cursor: pointer;

    > .icon {
      @include elements.icon(24px);
    }
  }

  > .cart-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 0 8px;
    cursor: pointer;

    > .header {
      display: flex;
      justify-content: space-between;

      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      > .payment-status {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 3px 8px;
        margin-bottom: 5px;
        background: #{colors.$teal-800}B3;
        border-radius: constants.$border-radius-small;

        font-size: 13px;
        line-height: 19px;
        color: colors.$white;

        > .icon {
          @include elements.icon(18px);
          margin-right: 8px;
        }
      }

      > .price {
        color: colors.$yellow-600;
        border-bottom: 1px solid #{colors.$yellow-600}40;
        height: fit-content;
        margin-left: 8px;
      }

      &:not(:has(.hasPaidItem)) {
        > .price {
          margin-left: auto;
          margin-left: 8px;
        }

        > .name {
          color: colors.$white;
          top: 500px;
        }
      }
    }

    > .item-description-view-widget {
      color: colors.$blue-gray-400;
    }

    > .name {
      color: colors.$blue-gray-400;
    }

    > .comments {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      padding: 4px 8px;

      border-radius: constants.$border-radius-small;
      background-color: #{colors.$teal-500}40;

      font-size: 13px;
      line-height: 19px;
      color: colors.$white;
      white-space: pre-wrap;

      > .extra-charge {
        margin-left: 8px;
      }
    }
  }
}
