@use "../common/colors";
@use "../common/scrollbar";
@use "./notification-widget";

%notifications {
  .link {
    padding-left: 28.5px;
  }

  .count {
    padding-right: 24px;
  }
}

%shifts-button-common {
  display: flex;
  justify-self: end;
  border-radius: 4px;
  padding: 14px 28px;
  margin: 25px 40px 10px 40px;
  font-weight: 500;
  width: fit-content;
  height: fit-content;
  cursor: pointer;

  img {
    margin-right: 10px;
  }

  &.disabled {
    border: 1px solid colors.$blue-gray-100;
    color: colors.$blue-gray-900;
    background-color: colors.$blue-gray-100;
    pointer-events: none;

    .shifts-image {
      filter: invert(0.86) sepia(1) saturate(5) hue-rotate(175deg);
    }
  }
}

%back-to-lock-screen-link-common {
  display: flex;
  padding: 40px 30px;
  min-width: 250px;
  height: min-content;
  align-items: center;
  font-size: 16px;
  color: colors.$blue-gray-100;
  cursor: pointer;

  img {
    margin-right: 8px;
    height: 24px;
  }
}

%timesheet-and-staff-records-common {
  padding-bottom: 20px;

  .labels {
    color: colors.$blue-gray-600;
    padding: 22px 40px 8px 40px;
    background-color: colors.$white;
  }

  .employee-time-clock-header {
    padding-bottom: 7.5px;

    .header-title {
      margin-top: 4px;
      margin-left: 16px;
      color: colors.$white;
    }

    .date-today {
      margin-right: 17px;
      color: colors.$white;

      span {
        color: colors.$white;
        margin-bottom: 4px;
      }
    }

    .go-back-link {
      margin-left: 15px;

      span {
        color: colors.$white;
      }

      > .arrow-left {
        background-image: url('../images/icon-chevron-left-filled.svg');
      }
    }
  }
}

.lock-screen-widget {
  position: relative;
  overflow: hidden;
  height: 100vh;
  z-index: 2000;
  background-image: url('../images/lock-screen-bg.png'), url('../images/lock-screen-bg.png'),
    url('../images/lock-screen-bg.png');
  background-repeat: no-repeat;
  background-size: cover;

  .login-screen {
    display: grid;
    grid-template-columns: 48% 1fr;
    grid-template-areas: 'date actions' 'weather actions';
    box-sizing: border-box;
    height: inherit;

    .login-actions {
      grid-area: actions;
      display: grid;
      grid-template-areas: 'shifts' 'pin' 'logout';
      grid-template-rows: min-content 1fr min-content;
    }

    .current-date-and-time-info-widget {
      grid-area: date;
      text-align: start;
      padding-left: 48px;
      padding-top: 96px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;

      .time {
        font-weight: 400;
        font-size: 72px;
        line-height: 115px;
        letter-spacing: 0.015em;
        color: colors.$white;
      }

      .date {
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        color: colors.$blue-gray-100;
      }
    }

    .employee-pin-widget {
      grid-area: pin;
    }

    .weather-info-widget {
      grid-area: weather;
      align-self: end;
      width: fit-content;
      padding: 0 0px 156px 48px;

      .temperature {
        color: colors.$white;
        font-weight: 500;
        font-size: 48px;
        line-height: 48px;
        letter-spacing: 0.005em;
      }

      .location {
        color: colors.$blue-gray-100;
        font-weight: 400;
        font-size: 32px;
        line-height: 29px;
        letter-spacing: 0.015em;
      }
    }

    .shifts-button {
      @extend %shifts-button-common;
      grid-area: shifts;
      border: 1px solid colors.$white;
      color: colors.$white;
    }

    .logout-link {
      display: flex;
      grid-area: logout;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.005em;
      color: colors.$white;
      text-decoration: underline;
      cursor: pointer;
      align-items: center;
      justify-content: end;
      padding-bottom: 20px;
      padding-right: 28px;
    }
  }

  .new-order-notification-widget,
  .new-table-booking-notification-widget,
  .failed-order-notification-widget,
  .cancelled-order-notification-widget {
    left: 0 !important;
  }

  .notification {
    display: grid;
    grid-template-columns: min-content 1fr;
    height: inherit;
    background-image: url('../images/lock-screen-bg-blurred.png');
    background-size: cover;

    .back-to-lock-screen-link {
      @extend %back-to-lock-screen-link-common;
    }

    .employee-pin-widget {
      grid-column: 2;
      justify-self: center;
      align-self: center;
    }
  }

  .employee-time-clock-pin {
    height: inherit;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    justify-items: center;

    .shifts-button {
      @extend %shifts-button-common;
    }

    .back-to-lock-screen-link {
      @extend %back-to-lock-screen-link-common;
    }
  }

  .employee-time-clock-actions {
    height: inherit;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    justify-items: center;

    .shifts-button {
      @extend %shifts-button-common;
    }

    .employee-time-clock-actions-widget {
      align-self: center;
    }

    .back-to-lock-screen-link {
      @extend %back-to-lock-screen-link-common;
    }
  }

  .employee-time-clock-timesheet-widget {
    @extend %timesheet-and-staff-records-common;

    .employee-time-clock-date-range-picker {
      background-color: colors.$white;
      border-top-left-radius: 8px;
      padding-left: 24px;
    }

    .actions {
      background-color: colors.$white;
      padding: 22px 28px 22px 0;
      border-top-right-radius: 8px;
    }

    .employee-time-clock-shift-list-widget {
      background-color: colors.$white;
      padding: 0 16px 32px;
      border-radius: 0 0 8px 8px;

      > .spinner-container {
        color: colors.$blue-gray-900;
      }

      > .no-records {
        margin-bottom: 0;
        border-color: colors.$blue-gray-600;
      }

      > .employee-time-clock-shift-list-item:not(:first-child.active) {
        border-color: colors.$blue-gray-600;
      }
    }
  }

  .employee-time-clock-staff-records-widget {
    @extend %timesheet-and-staff-records-common;

    .employee-time-clock-date-range-picker {
      background-color: colors.$white;
      margin-bottom: unset;
      padding: 30px 28px 16px 28px;
      border-radius: 12px 12px 0 0;
    }

    .staff-records-list-widget {
      background-color: colors.$white;
      padding: 0px 16px;
      flex: auto;
      border-radius: 0px 0px 12px 12px;

      .staff-records-list-item {
        border-color: colors.$blue-gray-600;

        &:nth-last-child(2) {
          border-bottom: 1px solid colors.$blue-gray-600;
        }
      }
    }

    .employee-time-clock-shift-list-edit-widget {
      background-color: colors.$white;
      padding: 0 16px 32px;
      border-radius: 0 0 8px 8px;

      > .spinner-container {
        color: colors.$blue-gray-900;
        border: 1px solid colors.$blue-gray-600;
        border-radius: 8px;
      }

      > .no-records {
        margin-bottom: 0;
        border-color: colors.$blue-gray-600;
      }

      > .employee-time-clock-shift-list-item-edit-widget {
        border-color: colors.$blue-gray-600;

        &.invalid {
          border-color: colors.$rose-500;
          border-bottom: 1px solid colors.$rose-500;

          &:last-child:not(:only-child) {
            border-bottom: 1px solid colors.$rose-500;
            border-radius: 0 0 8px 8px;
          }
        }

        &.invalid + div:has(.edit) {
          border-top: none;
        }

        &.edit:not(.invalid) {
          border-color: colors.$blue-500;
          border-bottom: 1px solid colors.$blue-500;

          &:last-child:not(:only-child) {
            border-bottom: 1px solid colors.$blue-500;
            border-radius: 0 0 8px 8px;
          }
        }

        &.edit + div:not(.invalid) {
          border-top: none;
        }

        &.edit + div:has(.edit):not(.invalid) {
          border-top: none;
        }
      }
    }
  }
}

.swal2-container {
  &.show-on-lockscreen,
  &.invalid-warning,
  &.confirm-clock-out-question,
  &.confirm-quit-editing-question {
    z-index: 2001 !important; //show SWAL above lock screen
  }
}

//show employee time clock toasts, modal container above lock screen
#toasts-container:has(.employee-time-clock-disabled-warning) {
  z-index: 2001 !important; //show toast above lock screen
}

#modal-container:has(.iso-date-range-picker-widget.employee-time-clock-date-range-picker-modal) {
  z-index: 2001;
  position: absolute;
}

#modal-container:has(.iso-date-picker-widget.shift-date-picker) {
  z-index: 2001;
  position: absolute;
}

@media only screen and (max-height: 700px) {
  .lock-screen-widget {
    .login-screen {
      grid-template-columns: unset;
      .current-date-and-time-info-widget {
        padding-top: 56px;
      }

      .login-actions {
        grid-area: actions;
        display: grid;
        grid-template-areas:
          'pin shifts'
          'pin .'
          'pin logout';
        grid-template-rows: min-content 1fr max-content;
        grid-template-columns: 1fr max-content;

        .shifts-button {
          margin: 25px 28px 10px 28px;
        }

        .employee-pin-widget {
          display: flex;
          align-self: center;
          justify-self: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .lock-screen-widget {
    .login-screen {
      .current-date-and-time-info-widget {
        .time {
          font-size: 45px;
          line-height: 60px;
          text-wrap: nowrap;
        }

        .date {
          font-size: 30px;
          line-height: 35px;
        }
      }
    }
  }
}
