@use "../common/typography";
@use "../common/elements";
@use "../common/cta";
@use "../common/scrollbar";
@use "../common/colors";

.split-by-item-select-widget {
  width: 60vw;
  max-width: 900px;
  padding: 48px;
  color: colors.$blue-gray-900;

  > .label {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    text-transform: capitalize;
  }

  > .unpaid-items {
    display: flex;
    flex-direction: column;

    > .label {
      margin-bottom: 12px;

      color: colors.$blue-gray-700;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }

    > .select-all-checkbox {
      display: grid;
      grid-template-columns: min-content 1fr;
      align-items: center;
      padding: 12px 20px;
      cursor: pointer;

      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }

    > .items {
      margin-top: 10px;
      background: colors.$blue-gray-50;
      border-radius: 8px;

      @include scrollbar.hide;
      overflow-y: scroll;
      max-height: 25vh;

      > .unpaid-split-bill-item {
        display: grid;
        grid-template-columns: auto 2fr 1fr 0.5fr;
        grid-template-areas:
          'checkbox name         payment-status price'
          '.        description  .              .    '
          '.        comments     .              .    ';
        column-gap: 4px;

        border-top: 1px solid colors.$blue-gray-100;
        padding: 16px 20px;

        cursor: pointer;

        &:first-child {
          border-top: none;
        }

        > .checkbox {
          grid-area: checkbox;
        }

        > .name {
          grid-area: name;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }

        > .payment-status {
          grid-area: payment-status;

          text-align: right;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }

        > .price {
          grid-area: price;
          text-align: end;

          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }

        > .item-description-view-widget {
          grid-area: description;
          color: colors.$blue-gray-500;
          margin-top: 8px;
        }

        > .split-bill-item-comments {
          grid-area: comments;
          margin-top: 4px;
          color: colors.$blue-gray-500;

          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }

  > .paid-items {
    display: flex;
    flex-direction: column;
    margin-top: 12px;

    > .label {
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }

    > .items {
      max-height: 20vh;
      margin-top: 10px;
      border-radius: 8px;
      background: colors.$blue-gray-50;

      @include scrollbar.hide;
      overflow-y: scroll;

      > .paid-split-bill-item {
        border-top: 1px solid colors.$blue-gray-100;

        &:first-child {
          border-top: none;
        }

        > .header {
          padding: 16px 20px 8px 20px;
          display: grid;
          grid-template-areas:
            'icon label                 amount            '
            '.    transaction-reference .                 '
            '.    surcharge-label       surcharge-amount  '
            '.    tip-label             tip-amount        ';
          grid-template-columns: min-content 1fr auto;
          align-items: center;
          justify-items: center;
          gap: 0 8px;

          font-weight: 400;
          font-size: 14px;
          line-height: 21px;

          > .icon {
            grid-area: icon;
            @include elements.icon(28px);
          }

          > .paid-split-bill-item-payment {
            display: contents;

            > .label {
              grid-area: label;
              display: flex;
              justify-self: start;
              align-items: center;

              > .type {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
              }

              > .bullet {
                margin: 0 8px;
                color: colors.$blue-gray-700;
              }
            }

            > .amount {
              grid-area: amount;

              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
            }
          }

          > .paid-split-bill-item-transaction-reference {
            grid-area: transaction-reference;
            justify-self: start;
            color: colors.$blue-gray-500;
          }

          > .paid-split-bill-item-surcharge {
            display: contents;
            color: colors.$blue-gray-500;

            > .label {
              grid-area: surcharge-label;
              justify-self: start;
            }

            > .amount {
              grid-area: surcharge-amount;
              justify-self: end;
            }
          }

          > .paid-split-bill-item-tip {
            display: contents;
            color: colors.$blue-gray-500;

            > .label {
              grid-area: tip-label;
              justify-self: start;
            }

            > .amount {
              grid-area: tip-amount;
              justify-self: end;
            }
          }
        }

        > .items {
          padding: 0px 20px 16px 80px;

          > .item {
            display: list-item;
            margin-top: 12px;

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  > .actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    margin-top: 40px;

    > .cancel-button {
      @include cta.link-cta;
      border: 2px solid colors.$blue-gray-500;
    }

    > .back-button {
      @include cta.secondary-cta;
      border-width: 2px;
    }

    > .back-to-cart-button {
      @include cta.link-cta;
      color: colors.$blue-gray-700;
      border: 1px solid colors.$blue-gray-500;
    }

    > .done-button {
      @include cta.primary-cta;
    }

    > .pay-button {
      @include cta.primary-cta;
    }
  }
}

.swal2-container {
  .finalize-payment-confirmation-dialog {
    .swal2-header {
      .swal2-title {
        margin-top: 0 !important;
      }
    }

    .swal2-content {
      .swal2-html-container {
        color: #{colors.$blue-gray-700};
      }
    }

    .swal2-actions {
      .confirm-button {
        text-transform: inherit !important;
      }
      .cancel-button {
        color: #{colors.$blue-gray-900} !important;
        border: 1px solid #{colors.$blue-gray-100} !important;
        border-radius: 4px !important;
        text-transform: inherit !important;
      }
    }
  }
}
