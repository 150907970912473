@use "./colors";

$_default-size: large;

@mixin _base {
  padding: 3px 6px;
  border-radius: 99px;
  white-space: nowrap;
  height: fit-content;
}

@mixin _base-secondary {
  border: 1px solid;
}

@mixin _small {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
}

@mixin _medium {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

@mixin _large {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

@mixin _size($size: $_default-size) {
  @if $size == small {
    @include _small;
  } @else if $size == medium {
    @include _medium;
  } @else if $size == large {
    @include _large;
  } @else {
    @error "Invalid `$size` in `_lozenge.scss`.";
  }
}

@mixin primary-gray($size: $_default-size) {
  @include _base;
  @include _size($size);
  color: colors.$white;
  background-color: colors.$blue-gray-700;
}

@mixin primary-light-gray($size: $_default-size) {
  @include _base;
  @include _size($size);
  color: colors.$blue-gray-700;
  background-color: colors.$light-gray-500;
}

@mixin secondary-gray($size: $_default-size) {
  @include _base;
  @include _base-secondary;
  @include _size($size);
  color: colors.$blue-gray-700;
  border-color: colors.$blue-gray-500;
}

@mixin secondary-light-gray($size: $_default-size) {
  @include _base;
  @include _base-secondary;
  @include _size($size);
  color: colors.$blue-gray-700;
  border-color: colors.$light-gray-400;
}

@mixin secondary-green($size: $_default-size) {
  @include _base;
  @include _base-secondary;
  @include _size($size);
  color: colors.$green-700;
  border-color: colors.$green-200;
}

@mixin secondary-rose($size: $_default-size) {
  @include _base;
  @include _base-secondary;
  @include _size($size);
  color: colors.$rose-800;
  border-color: colors.$rose-200;
}

@mixin secondary-yellow($size: $_default-size) {
  @include _base;
  @include _base-secondary;
  @include _size($size);
  color: colors.$yellow-700;
  border-color: colors.$yellow-200;
}

@mixin secondary-blue($size: $_default-size) {
  @include _base;
  @include _base-secondary;
  @include _size($size);
  color: colors.$blue-800;
  border-color: colors.$blue-200;
}

@mixin secondary-violet($size: $_default-size) {
  @include _base;
  @include _base-secondary;
  @include _size($size);
  color: colors.$violet-800;
  border-color: colors.$violet-100;
}

@mixin secondary-teal($size: $_default-size) {
  @include _base;
  @include _base-secondary;
  @include _size($size);
  color: colors.$teal-700;
  border-color: colors.$teal-100;
}
