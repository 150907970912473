@use '../common/colors';

.unpair-linkly-from-terminal-prompt {
  > .swal2-title {
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 26px !important;
    margin-top: 0 !important;
  }

  > .swal2-content {
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 21px !important;
  }

  > .swal2-actions {
    padding-top: 20px !important;

    > button {
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      text-transform: none !important;
    }

    > .confirm-button {
      background-color: colors.$rose-800 !important;
      border-radius: 5px !important;
    }

    > .swal2-cancel {
      &.cancel-button {
        color: colors.$blue-gray-900 !important;
        border: 1px solid colors.$blue-gray-100;
      }
    }
  }
}

.linkly-pinpad-pairing-widget {
  &.paired {
    border: 1px solid #{colors.$blue-gray-100};
    border-radius: 12px;
    padding: 24;
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-areas:
      'username     unpair-button'
      'paired-label unpair-button';
    height: min-content;
    max-width: 423.5px;

    .username {
      grid-area: username;
      color: #{colors.$blue-gray-900};
    }
    .paired-label {
      grid-area: paired-label;
      color: #{colors.$teal-600};
      font-size: 14px;
    }
    .unpair-button {
      grid-area: unpair-button;
      margin-left: 24px;
      height: min-content;
    }
  }

  &.unpaired {
    // remove background colour set for browser autocomplete
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    ::placeholder {
      color: #{colors.$blue-gray-400};
      font-size: 16px;
      font-weight: 400;
    }

    .form-input > div {
      display: flex;
      flex-direction: column;
      border: 1px solid #{colors.$blue-gray-100};
      border-radius: 8px;
      padding: 8px 12px;
      max-width: 423.5px;

      .prepend-text {
        all: unset;
        font-size: 14px;
        font-weight: 400;
        color: #{colors.$blue-gray-500};
      }
      input {
        outline: none;
        padding: 0;
        border: none;
        height: 24px;
        font-size: 16px;
        font-weight: 400;
        color: #{colors.$blue-gray-900};
      }
    }

    .form-item.password > .form-input > div {
      display: grid;
      grid-template-columns: 1fr min-content;
      grid-template-areas:
        'password-label     show-password'
        'password-input     show-password';
      .prepend-text {
        grid-area: password-label;
      }
      input {
        grid-area: password-input;
        display: block;
        transition: none;
      }
      .show-password {
        grid-area: show-password;
        cursor: pointer;
        align-self: center;
      }
      input[type='password'] {
        height: 24px;
        font-size: 30px;
      }
    }

    .form-input.invalid > div {
      border: 1px solid #{colors.$rose-800};
    }

    .error {
      color: #{colors.$rose-800};
    }

    .help-text {
      font-size: 14px;
      color: #{colors.$blue-gray-500};
      margin-top: 4px;
    }

    .submit-btn {
      border-radius: 4px;
      background-color: #{colors.$teal-600};
      padding: 9.5px 24px;
    }
  }
}
