@use "../common/typography" as typography;
@use "../common/default-theme" as default-theme;
@use "../common/cta" as cta;

.website-order-delivery-channel-selector-widget {
  display: grid;
  grid-template-areas:
    'title               title'
    'delivery-channels   delivery-channels'
    'back-button            back-button';
  padding: 65px 55px;
  width: 750px;
  height: 500px;

  .title {
    grid-area: title;
    @include typography.h1;
    font-size: 25px;
    text-align: center;
    align-self: start;
  }

  .delivery-channels {
    grid-area: delivery-channels;
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
  }

  .in-house-delivery-option,
  .uber-direct-option {
    @include typography.h3;
    background: #{default-theme.$modifier-gradient-bg};
    box-shadow: 0px 3px 10px -3px rgb(0 0 0 / 30%);
    color: #{default-theme.$fg};
    text-align: center;
    padding: 40px 0px;
    border-radius: 10px;
    -webkit-tap-highlight-color: transparent;
    align-self: center;

    .icon {
      width: 45px;
      margin: 0 130px;
      margin-bottom: 17px;
    }
  }

  .back-button {
    grid-area: back-button;
    @include cta.tertiary-cta;
    color: #{default-theme.$fg};
    text-align: center;
    align-self: end;
  }
}
