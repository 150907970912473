@use "../common/card";
@use "../common/colors";
@use "../common/typography";
@use "../common/buttons";

.dine-in-guest-count-covers-report-breakdown-widget {
  > .header {
    @include typography.h1;
    border-bottom: 1px solid colors.$light-gray-100;
    display: grid;
    grid-template-columns: 1fr max-content min-content;
    padding: 15px 24px;
    align-items: center;

    .print-button {
      @include buttons.primary;
      @include typography.body-3-500;
      padding: 9.5px 24px;
      border-radius: 4px;
    }
  }

  .body {
    padding: 24px;

    .filters {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 24px;
      justify-content: space-between;
    }

    .no-report {
      margin: 56px auto;
      text-align: center;
      color: colors.$blue-gray-700;
      opacity: 0.5;
    }

    .report {
      .dine-in-guest-count-covers-overview-widget {
        display: grid;
        gap: 12px;
        grid-template-areas:
          'total-covers total-covers total-covers';
      }

      .total-guest-count {
        grid-area: total-covers;
        display: grid;
        gap: 14px;
        padding: 24px;
        border-radius: 8px;
        background: linear-gradient(264deg, colors.$teal-600 0%, colors.$teal-800 100%);
        box-shadow: colors.$card-shadow;
        color: colors.$white;
        @include typography.body-2-500;

        .value {
          font-size: 32px;
        }
      }

      .total-guest-count ~ div {
        @include card.container;
        padding: 24px;
        border: 1px solid colors.$blue-gray-100;

        .header {
          @include typography.h3;
          padding-bottom: 16px;
        }

        .key-value-pair {
          @include typography.h5;
          grid-template-columns: repeat(2, 1fr);
          gap: 32px;

          .label {
            color: colors.$blue-gray-500;
            font-weight: 400;
          }

          .value {
            text-align: end;
          }
        }

        .key-value-pair:not(:last-of-type) {
          padding-bottom: 8px;
        }
      }
    }
  }
}

.swal2-container {
  .invalid-time-range-warning {
    padding: 0 !important;
  }
}
