@use "../common/colors";
@use '../common/order-history-list';

.order-history-widget {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .order-history-list-info {
    position: relative;
    z-index: 2;
    background-color: colors.$white;
    transform: translateY(-122px);
    transition: transform 0.3s ease-out;
    border-top: 1px solid colors.$blue-gray-100;

    &.show-date-time-filter {
      transform: translateY(0);
    }

    .order-sources {
      display: flex;
      margin-bottom: 24px;
      padding: 24px 24px 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: colors.$blue-gray-500;
  
      > .order-source {
        flex: 1;
        padding: 12px 14px;
        margin: 0 8px;
        border-radius: 8px;
        background-color: colors.$blue-gray-50;
        text-align: center;
        cursor: pointer;
  
        &:first-child {
          margin-left: 0;
        }
  
        &:last-child {
          margin-right: 0;
        }
  
        &.active {
          background-color: colors.$blue-gray-800;
          color: colors.$white;
        }
      }
    }
  
    .labels {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: order-history-list.$grid-template-columns;
      align-items: center;
      gap: 12px;
  
      margin-bottom: 10px;
      padding: 12px 48px;
  
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      white-space: nowrap;
      color: colors.$blue-gray-500;
    }
  }
}

  