@use '../common/colors';

.third-party-completed-deliveries-page {
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100vh;

  > .header {
    margin-bottom: 0;

    > .wrapper {
      border-bottom: none;
      grid-template-areas:
        'actions'
        'title'
        'subtitle';
      justify-content: start;
      align-items: baseline;
      column-gap: 24px;
    }

    .action-link {
      color: colors.$blue-gray-500;
      display: grid;
      grid-auto-flow: column;
      gap: 12px;
      align-items: center;
      margin-bottom: 13.5px;

      cursor: pointer;
    }
  }

  > .body {
    overflow: hidden;
  }

  .disabled {
    margin: 30px;
  }
}
