@use "../common/typography.scss";
@use "../common/colors";
@use "../common/default-theme";
@use "../common/elements";

%button {
  display: flex;
  align-items: center;
  padding: 14px 16px;
  cursor: pointer;
}

.popout-cart-header-more-actions-widget {
  display: flex;
  flex-direction: column;
  padding: 0;
  @include typography.h3;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;

  > .save-order-button,
  .print-invoice-button,
  .pay-for-items-button {
    @extend %button;
    border-bottom: 1px solid colors.$blue-gray-100;

    > .icon {
      @include elements.icon($size: 20px, $iconSize: 18px);
      margin-right: 12px;
    }
  }

  > .reset-order-button {
    @extend %button;
    color: colors.$rose-500;

    > .icon {
      @include elements.icon($size: 20px, $iconSize: 18px);
      margin-right: 12px;
    }
  }
}
