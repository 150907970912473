@use "../common/typography";
@use "../common/default-theme";
@use "../common/cta";
@use "../common/constants";
@use "../common/colors";
@use "../common/pill";
@use "../common/ghost";

.website-order-delivery-status-widget {
  margin-bottom: 15px;
  .cancelled-info {
    box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.15);
    padding: 24px;
    border-radius: constants.$border-radius;

    .courier-and-label-container {
      box-shadow: none;
      padding: 0;
      grid-auto-columns: unset;

      > .status-label {
        grid-column-start: 1; // reverse grid column order
        grid-row-start: 1; // reverse grid column order
        padding-right: 20px;
      }

      > .courier-info {
        border-left: 1px solid #{default-theme.$border-light};
        padding-left: 20px;
      }
    }
  }

  .delivered-info {
    box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.15);
    border-radius: constants.$border-radius;

    > .status-label {
      background-color: #{colors.$green-500};
      border-radius: constants.$border-radius constants.$border-radius 0 0;
      padding: 24px;
    }

    > .proof-of-delivery-label {
      padding: 24px;

      > .label {
        color: #{default-theme.$fg};
        font-weight: 400;
        font-size: 16;
        line-height: 24px;
        margin-bottom: 10px;
      }

      .view-link {
        color: #{colors.$blue-gray-700};
        text-decoration: underline;
        align-self: flex-end;
        cursor: pointer;
      }
    }
  }

  .courier-pickup-date-and-time {
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 16px;
    box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.15);
    padding: 16px;
    border-radius: constants.$border-radius;

    > img {
      width: 100px;
    }

    .title {
      color: #{default-theme.$fg};
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .subtitle {
      color: #{default-theme.$fg};
      font-weight: 500;
      font-size: 14px;
      display: grid;
      grid-auto-flow: column;
      gap: 4px;

      > .time-since-label-widget {
        color: #{default-theme.$primary} !important;
      }
    }
  }

  .tracking-map {
    margin-bottom: 16px;
    border-radius: constants.$border-radius;
    box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.15);
    overflow: hidden;

    .map-container {
      width: 100%;
      height: 240px;
      overflow: hidden;
      border-radius: constants.$border-radius constants.$border-radius 0 0;

      > iframe {
        overflow: hidden;
        width: 170%;
        height: 555px;
        position: relative;
        top: -45px; // remove header
        left: -1px; // remove black border on left
        transform: scale(0.6);
        transform-origin: 0 0;
      }
    }

    > .label {
      color: #{colors.$blue-gray-800};
      font-size: 14px;
      padding: 12px 14px;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: min-content 1fr;
      gap: 8px;
      align-items: center;
    }
  }

  .courier-and-label-container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr max-content;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.15);
    padding: 16px;
    border-radius: constants.$border-radius;
  }

  .courier-info {
    display: grid;
    grid-template-areas:
      'image   name'
      'image   vehicle'
      'image   contact';
    grid-auto-columns: min-content 1fr;
    gap: 0 12px;
    align-items: center;

    .courier-image {
      grid-area: image;
      width: 74px;
      border-radius: 50%;
    }

    .courier-name {
      grid-area: name;
      color: #{default-theme.$fg};
      font-size: 16px;
      font-weight: 500;
    }

    .courier-vehicle {
      grid-area: vehicle;
      color: #{colors.$blue-gray-500};
      font-size: 14px;
      font-weight: 400;
    }

    .courier-contact-number {
      grid-area: contact;
      text-decoration: none;
      color: #{colors.$blue-gray-500};
      font-size: 14px;
      font-weight: 500;
    }
  }

  .status-label {
    @include typography.h4;
    font-weight: 400;
    line-height: 22px;
    margin: 0;

    &.ongoing {
      @include ghost.green;
      color: #{colors.$blue-900};

      &::before {
        background-color: #{colors.$blue-900} !important;
      }
    }

    &.failed {
      color: #{colors.$rose-800};
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: min-content 1fr;
      gap: 12px;
    }

    &.success {
      font-size: 18px;
      font-weight: 500;
      color: #{colors.$white};
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: min-content 1fr;
      gap: 12px;
    }
  }
}
