@use '../common/order-view';
@use '../common/colors';

@mixin _button {
  width: 100%;
  padding: 14px;
  border: solid 1px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.google.order-view-widget {
  @include order-view.container;
  @include order-view.content;

  .order-details-info {
    .text {
      color: colors.$blue-gray-500;
    }
  }

  .split-bill-payment-summary-widget,
  .tyro-payment-summary-widget {
    margin-top: 16px;
  }

  .google-order-view-actions-widget {
    display: grid;
    margin-top: auto;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;

    > .print-order-button {
      background-color: transparent;
      border-color: colors.$blue-gray-500;
      color: colors.$blue-gray-500;
    }
  }
}
