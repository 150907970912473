@use "../common/colors";
@use '../common/banner';
@use '../common/z-indexes';

.refresh-menu-banner {
  @include banner.base;
  z-index: z-indexes.$refresh-menu-banner;
  background: colors.$yellow-100;

  > .text > .highlight {
    color: colors.$rose-800;
  }
}
