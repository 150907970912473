@use "../common/typography";
@use "../common/colors";
@use "../common/employee-time-clock" as employeeTimeClock;

.employee-time-clock-timesheet-widget {
  @include employeeTimeClock.container;

  > .labels {
    @include employeeTimeClock.labels;
    grid-template-columns: 1fr .9fr .7fr .2fr .7fr .9fr repeat(3, 1fr);
  }

  > .actions {
    grid-area: actions;
    display: grid;
    grid-auto-flow: column;
    gap: 25px;

    > .start-end-break-button {
      @include employeeTimeClock.timesheet-button(colors.$yellow-600, transparent, 1px solid colors.$yellow-600);
    }


    .clock-in-button {
      @include employeeTimeClock.timesheet-button(colors.$white, colors.$teal-600);
    }
  
    .clock-out-button {
      @include employeeTimeClock.timesheet-button(colors.$white, colors.$rose-800);
    }
    
  }
}
