@use "constants";
@use "default-theme";
@use '../common/colors';
@use '../common/typography';
@use '../common/z-indexes';

#toasts-container {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-gap: 15px;
  z-index: z-indexes.$toast;

  .wrapper {
    text-align: center;
  }
}

.toast {
  display: inline-grid;
  grid-template-areas: 'icon message';
  grid-template-columns: min-content max-content;
  align-items: center;
  padding: 10px 15px;
  border-radius: constants.$border-radius-small;
  box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);

  .icon {
    grid-area: icon;
    font-size: 30px;
    margin-right: 10px;
  }

  .message {
    grid-area: message;
    margin: 0;
    font-size: 0.85rem;
    font-weight: 400;
    max-width: 585px;
  }

  button.close {
    margin-left: 10px;
    padding: 0;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    opacity: 0.5;
    color: black;
    font-size: 1.4rem;
  }

  &.success {
    background-color: #{default-theme.$message-success-bg};
    color: #{default-theme.$message-success-text};
  }

  &.info {
    background-color: colors.$blue-100;
    color: colors.$blue-800;
    border: 1px solid colors.$blue-500;
  }

  &.error {
    background-color: colors.$rose-100;
    color: colors.$rose-800;
    border: 1px solid colors.$rose-500;
  }

  &.warning {
    background-color: colors.$yellow-100;
    color: colors.$yellow-800;
    border: 1px solid colors.$yellow-500;
  }

  &.loading {
    background-color: #{default-theme.$message-loading-bg};
    color: #{default-theme.$message-loading-text};

    .spinner {
      font-size: 30px;
      color: #{default-theme.$primary};
      text-align: center;
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    .icon {
      grid-area: icon;
      margin-top: -5px;
    }
  }
}
