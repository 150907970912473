@use '../common/colors';
@use '../common/elements';

.dine-in-widget {
  display: grid;
  grid-template-rows: min-content 1fr;

  > .header {
    padding: 14px 24px;
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    gap: 24px;
    align-items: center;
    border: 1px solid colors.$blue-gray-100;
    height: 80px;

    .title {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }

    .time {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    > .more-actions-button {
      cursor: pointer;

      .icon {
        display: grid;
        align-items: center;
      }
    }

    &.edit-mode {
      position: relative;
      grid-template-columns: 1fr max-content;
      border: none;
      background-color: colors.$blue-800;
      color: colors.$white;

      > .title {
        text-align: center;
      }

      > .close-button {
        cursor: pointer;
        position: absolute;
        right: 24px;
      }
    }
  }

  > .table-map-widget {
    background-color: colors.$light-gray-100;
  }

  > .actions {
    position: fixed;
    right: 16px;
    bottom: 16px;

    .start-order {
      border-radius: 4px;
      padding: 9.5px 24px;
      border: 1px solid colors.$blue-gray-500;
      background-color: colors.$blue-gray-50;

      cursor: pointer;

      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }
  }
}
