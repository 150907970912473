@use "elements";
@use "colors";
@use "typography";
@use "cta";
@use "z-indexes";

@mixin base {
  z-index: z-indexes.$banner;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 7.5px 32px;
  padding-left: 102px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);

  > .text {
    display: inherit;
    align-items: inherit;

    color: colors.$blue-gray-900;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    > .icon {
      @include elements.icon(24px);
      margin-right: 8px;
    }

    > .highlight {
      display: inherit;
      align-items: inherit;
      margin-right: 8px;
      font-weight: 500;
    }
  }

  > .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
      &:first-child {
        margin-left: 0;
      }

      margin-left: 12px;
    }

    .refresh-button {
      @include cta.primary-cta;
      @include typography.h5;
      border-radius: 4px;
      color: colors.$white;
      padding: 7px 16px;
      line-height: 18px;
    }

    .later-button {
      @include typography.h5;
      border-radius: 4px;
      border: 1px solid colors.$yellow-600;
      padding: 7px 16px;
      line-height: 18px;
      color: colors.$rose-800;
      background-color: transparent;
    }

    > .continue-transaction-button,
    .void-transaction-button {
      padding: 7px 16px;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }

    > .continue-transaction-button {
      background-color: colors.$teal-600;
      border: solid 1px colors.$teal-600;
      color: colors.$white;
    }

    > .void-transaction-button {
      background-color: transparent;
      border: solid 1px colors.$rose-500;
      color: colors.$rose-800;

      &.transaction-failed {
        background-color: colors.$rose-800;
        border: none;
        color: colors.$white;
      }
    }

    > .go-online-button {
      border: solid 1px transparent;
    }

    > .dismiss-button {
      background-color: transparent;
      border: solid 1px colors.$blue-gray-500;
      color: colors.$blue-gray-700;
    }

    > .enable-audio-button {
      background-color: colors.$teal-600;
      border: solid 1px colors.$teal-600;
    }
  }
}

@mixin button {
  padding: 7px 16px;
  border-radius: 4px;
  cursor: pointer;

  white-space: nowrap;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
