@use "../common/scrollbar" as scrollbar;
@use "../common/colors" as colors;

.new-table-booking-list-widget {
  .table-bookings {
    display: grid;
    grid-auto-rows: min-content;
    gap: 15px;
    padding: 24px;

    overflow: hidden scroll;
    max-height: calc(100vh - 126px);

    @include scrollbar.hide();
  }

  .table-bookings-page-link {
    text-align: center;

    span {
      cursor: pointer;
      color: #{colors.$teal-600};
    }

    &.no-table-bookings {
      margin-top: 20vh;
    }

    &.end-list {
      margin-top: 20px;
    }
  }
  
}