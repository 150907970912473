@use './notification-widget';
@use '../common/colors' as colors;

.failed-order-notification-widget {
  @extend %notification-widget;
  // 'Approx width of the notification' - 'sidebar width'
  // 236px - 70px
  left: -166;
  background-color: #{colors.$orange-600};
  color: #{colors.$white};

  .link {
    padding: 0;
    margin: 0;
  }

  .failed-delivery-warning-icon {
    padding: 16px 18px 16px 23px;
  }

  .count {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    padding-right: 16px;
  }
}
