@use "../common/constants";
@use "../common/typography";
@use "../common/colors";
@use '../common/text-input';
@use '../common/elements';

.order-history-page {
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100vh;

  > .header {
    > .wrapper {
      border-bottom: 0;
      padding: 20px 10px 0px 25px;
      margin-bottom: -30px;
    }

    .store-filter-widget {
      .form-label {
        visibility: hidden;
      }

      .form-input {
        background-image: url('../images/icon-store-front.svg');
        background-repeat: no-repeat;
        background-position: 15px center;
        background-size: 20.09px 18px;
      }

      .drop-down-input{
        padding-left: 34px;
      }
    }

    .search-input {
      position: relative;

      input {
        width: 44px;
        padding: 12px 14px; 
        border: 1px solid colors.$blue-gray-100;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.3s linear;

        text-indent: 32px;
        color: colors.$blue-gray-900;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
  
        background-image: url('../images/icon-search.svg');
        background-repeat: no-repeat;
        background-position: 9px center;
        background-size: 24px 24.41px;
        outline: none;
      }

      .clear-icon {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 12px;
      }

      &.open {
        input {   
          width: 310px;
          background-size: 16px 16.28px;
          background-position: 16px center;
          cursor: unset;
        }
      }

      &.has-input {
        input {
          text-indent: unset;
          padding: 12px 40px 12px 46px; 
          transition: unset;
        }
      }
    }
  }

  > .body {
    overflow: hidden; // let the child component handle scroll
  }
}
