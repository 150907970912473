.pos-home-quick-actions-colors-widget {
  .form-items {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    padding: 12px;
  }

  .form-item {
    margin-left: 24px;
    .form-input {
      .header {
        background: linear-gradient(180deg, #f2f7fd 0%, #ffffff 69.29%), #ffffff;
        flex-direction: column;
        align-items: center;

        .label {
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
        }
      }

      .color {
        box-sizing: border-box;
      }
    }
  }
}
