@use "../common/colors";
@use '../common/typography';

.marketing-campaign-widget {
  box-sizing: border-box;
  grid-column: 1/3;
  background-color: colors.$blue-gray-50;
  padding: 12px 20px;
  border-radius: 8px;
  color: colors.$blue-gray-900;

  > .info {
    display: flex;
    align-items: center;
    padding: unset !important;

    img {
      margin-right: 8px;
    }

    .label {
      @include typography.body-2-400;
    }
  }

  .description {
    padding: 12px 0 0 32px;
    @include typography.body-3-500;
    line-height: 21px;
  }
}
