@use '../common/typography';
@use "../common/colors";

.incoming-uber-eats-orders-from-selected-store-list-widget {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  .wrapper {
    display: flex;
    align-items: center;
    padding: 20px;
    padding-bottom: 0;

    .title {
      @include typography.h2;
      margin: 0;
      padding: 0 10px;
    }

    .incoming-uber-eats-orders-count {
      font-size: 14px;
      color: colors.$blue-gray-600;
    }
  }

  .incoming-uber-eats-orders-container::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .incoming-uber-eats-orders-container {
    overflow: hidden scroll;
    margin: 20px 0;
    scrollbar-width: none;
    -ms-overflow-style: none;

    .incoming-uber-eats-orders {
      display: grid;
      gap: 15px;
      padding: 10px;
      padding-top: 0;
      margin: 0 20px;
    }

    .no-incoming-uber-eats-orders {
      text-align: center;
      margin-top: 30vh;
      font-size: 20px;
      line-height: 26px;
    }
  }
}
