@use "../common/colors";
@use "../common/elements";

.uber-eats-pickup-time-selector-widget {
  padding: 16px 32px 18px;
  max-width: 850px;
  width: 70vw;

  .header {
    margin-bottom: 16px;
    margin-top: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    > .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;

      color: colors.$blue-gray-900;
    }

    > .close-button {
      @include elements.icon(36px);

      position: absolute;
      right: 32px;

      cursor: pointer;
    }
  }

  .pickup-time-options {
    padding: 32px 0;
    display: grid;
    grid-auto-flow: row;
    gap: 32px;

    > .option {
      padding: 40px 16px;
      display: flex;
      align-items: center;

      border-radius: 6px;
    }

    > .uber-eats-recommended-time {
      border: 2px solid colors.$teal-500; 
      box-shadow: 0px 4px 6px 0px rgba(49, 48, 62, 0.15), 0px 0px 5px 0px rgba(49, 48, 62, 0.15);

      > .uber-eats-logo {
        margin-right: 8px;
      }

      > .title {
        flex-grow: 1;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        > .time-since-label {
          font-size: 18px;
          font-weight: 600;
        }

        > .time-of-day {
          font-weight: 600;
          letter-spacing: 0.08px;
        }
      }

      > .label {
        justify-self: end;
        margin-right: 8px;

        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.035px;

        color: colors.$teal-600;
      }

      > .continue-button {
        padding: 9.5px 24px;

        display: flex;
        justify-self: end;
        justify-content: center;
        align-items: center;

        border-radius: 4px;
        background-color: colors.$teal-600;
        color: colors.$white;

        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.035px;

        cursor: pointer;
      }
    }

    > .schedule-another-pickup-time {
      justify-content: space-between;
      border-radius: 6px;
      box-shadow: 0px 4px 6px 0px rgba(49, 48, 62, 0.15), 0px 0px 5px 0px rgba(49, 48, 62, 0.15);
      
      > .title {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0.09px;
      }

      > .continue-button {
        display: flex;
        padding: 9.5px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 4px;
        border: 1px solid colors.$teal-300;

        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.035px;

        color: colors.$teal-600;

        cursor: pointer;
      }
    }
  }

  .actions {
    margin-top: 18px;
    > .back-button {
      padding: 14px 28px;
      text-align: center;

      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.08px;

      color: colors.$blue-gray-500;

      cursor: pointer;
    }
  }
}