@use '../common/typography';
@use '../common/colors';
@use '../common/scrollbar';

@mixin table {
  .header {
    @include typography.h3;
    font-size: 18px;
    color: colors.$blue-gray-900;
  }

  table {
    width: 100%;
    table-layout: fixed;
  }

  .covers-table {
    border-radius: 8px;

    * {
      background: unset;
      @include typography.p;
      white-space: normal;
      @include scrollbar.hide();
    }

    td,
    th {
      &:not(:first-child),
      &:not(:last-child) {
        border-right: none;
        border-left: none;
      }
    }
    td:first-child {
      border-left: 1px solid colors.$blue-gray-100;
    }
    td:last-child {
      border-right: 1px solid colors.$blue-gray-100;
    }

    .covers-header {
      font-weight: 500;
      color: colors.$blue-gray-500;

      > th {
        padding: 12px;
      }

      .covers-header-cell {
        padding: 14px 12px;
        text-align: right;
        &.unsorted::after {
          content: '\25b2\25bc';
          color: colors.$blue-gray-200;
          writing-mode: vertical-rl;
          font-size: 10px;
          position: absolute;
        }
        &.sort-ascending > span::after,
        &.sort-descending > span::after {
          color: colors.$blue-500;
        }
        &:first-child {
          text-align: left;
          width: 30%;
        }
        border-bottom: none;
      }
    }

    .covers-row {
      > td {
        padding: 12px;
        text-align: right;
      }

      &:first-child td {
        border-top: 1px solid colors.$blue-gray-100;
      }

      &:first-child td:first-child {
        border-top-left-radius: 8px;
      }
      &:first-child td:last-child {
        border-top-right-radius: 8px;
      }
      &:last-child td:first-child {
        border-bottom-left-radius: 8px;
      }
      &:last-child td:last-child {
        border-bottom-right-radius: 8px;
      }
    }

    .covers-cell {
      padding: 14px 12px;
      text-align: center;
      font-weight: 400;

      &:first-child {
        text-align: left;
      }
      &:nth-child(3) {
        font-weight: 500;
      }
      &:last-child {
        color: colors.$blue-500;
      }
    }
  }
}

.dine-in-guest-count-covers-report-table-overview-widget {
  @include table();

  padding: 12px;
  margin-top: 12px;

  .title {
    @include typography.h3;
  }
}
