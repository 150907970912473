@use "../common/cta";
@use "../common/typography";
@use "../common/colors";

.order-in-use-warning-widget {
  display: grid;
  justify-items: center;
  width: 376px;
  padding: 24px;
  text-align: center;

  img {
    margin-bottom: 16px;
  }

  .title {
    @include typography.h1;
    margin-bottom: 12px;
  }

  .subtitle {
    @include typography.body-2-400;
    color: colors.$blue-gray-700;
    margin-bottom: 24px;
  }

  .actions {
    display: grid;
    justify-items: center;
    gap: 16px;
    width: 100%;

    button {
      width: 100%;
    }

    .cancel-button {
      @include cta.primary-cta;
      @include typography.body-2-400;
    }

    .continue-button {
      @include cta.link-cta;
      color: colors.$blue-gray-700;
      border: 1px solid colors.$blue-gray-500;
      border-radius: 5px;
      font-size: 15px;
    }
  }
}