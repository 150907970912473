@use '../common/elements';
@use '../common/colors';

.table-map-widget {
  position: relative;

  > .actions-toolbar {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);

    display: grid;
    grid-template-columns: repeat(4, max-content);
    align-items: stretch;
    grid-auto-rows: 1fr;

    background-color: white;
    border: 1px solid colors.$blue-gray-100;
    border-radius: 8px;

    box-shadow: 0px 2px 8px rgb(0 0 0 / 8%);

    .action {
      display: grid;
      align-items: center;
      border-right: 1px solid colors.$blue-gray-100;

      > .icon {
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      > button {
        margin: 8px 12px;
        border-radius: 4px;
        padding: 9.5px 24px;
        background-color: colors.$teal-600;
        color: colors.$white;

        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
      }

      &:last-child {
        border: none;
      }

      cursor: pointer;
    }

    .zoom-control {
      display: grid;
      grid-template-columns: min-content 1fr min-content;
      gap: 12px;
      align-items: center;

      padding: 0 16px;

      font-size: 14px;

      .icon {
        cursor: pointer;
        @include elements.icon(24px);
      }
    }
  }
}
