@use '../common/banner';
@use "../common/colors";

.tyro-incomplete-transaction-banner-widget {
  @include banner.base;
  background-color: colors.$rose-100;

  > .text > .highlight {
    color: colors.$rose-800;
  }
}

.swal2-container.void-transaction-modal {
  .swal2-confirm {
    background-color: colors.$rose-800 !important;
  }
}
