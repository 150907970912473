@use '../common/colors';
@use '../common/buttons';

.order-error-widget {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  text-align: center;
  align-items: center;
  width: 50vw;
  min-width: 357px;
  max-width: 573px;
  box-sizing: border-box;
  z-index: 1001;

  > .error-icon {
    width: 64px;
  }

  > .title {
    color: colors.$blue-gray-900;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 16px;
  }

  > .content {
    margin-top: 16px;
    color: colors.$blue-gray-700;
    font-size: 16px;
    font-weight: 500;
  }

  a {
    color: colors.$teal-600;
    text-decoration: none;
  }

  > .confirm-button {
    @include buttons.primary;
    width: 100%;
    height: min-content;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
  }
}