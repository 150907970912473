@use "../common/typography";
@use "../common/colors";
@use "../common/scrollbar";
@use "../common/employee-time-clock" as employeeTimeClock;

.employee-time-clock-staff-records-widget {
  @include employeeTimeClock.container;

  > .labels {
    @include employeeTimeClock.labels;
    grid-column-gap: 2px;

    > :last-child {
      justify-self: center;
    }

    &.staff-records {
      display: grid;
      grid-template-columns: 4fr repeat(3, 1fr);
    }

    &.timesheet {
      grid-template-columns: 0.9fr .7fr 0.2fr .7fr 1.2fr repeat(4, 1fr);
    }
  }

  > .staff-records-list-widget {
    @include employeeTimeClock.list;

    .footer {
      padding: 24px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.0025em;
      color: colors.$blue-gray-600;
    }

    .staff-records-list-item {
      @include employeeTimeClock.list-item;
      display: grid;
      grid-template-columns: 4fr repeat(3, 1fr);
      cursor: pointer;

      > :last-child {
        justify-self: center;
      }

      &:first-child:nth-last-child(2) {
        border-bottom: 1px solid colors.$blue-gray-100;
        border-radius: 8px;
      }

      &:nth-last-child(2) {
        border-bottom: 1px solid colors.$blue-gray-100;
        border-radius: 0 0 8px 8px;
      }

      &.loading {
        grid-template-columns: 4fr 3fr;
        
        .spinner-container {
          grid-column: unset;
        }
      }
    }
  }
}
