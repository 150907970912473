@use '../common/default-theme' as default-theme;
@use "../common/cta" as cta;
@use '../common/buttons' as buttons;
@use '../common/typography' as typography;
@use '../common/colors' as colors;

.item-custom-price-widget {
  width: 430px;
  margin: 60px 160px;

  .title {
    @include typography.h1;
    font-weight: bold;
    text-align: center;
  }

  // --- PRICE INPUT BOX --- //
  .update-price-input-box {
    border-radius: 8px;
    display: grid;
    padding: 12.75px 20px;
    grid-template-columns: min-content 1fr;
    border: 1px solid #{default-theme.$form-element-border};

    .dollar-sign-icon {
      @include typography.h1;
      padding-top: 10px;
    }

    .update-item-price-value {
      display: grid;
      align-items: center;

      input {
        outline: none;
        border: none;
        background: none;
        text-align: right;
        font-size: 25px;
        font-weight: normal;
        font-style: normal;
        line-height: 35px;
        overflow: hidden;
        width: 100%;
      }
    }
  }

  .summary {
    margin: 24px 0;
    background: #{default-theme.$secondary-bg};
    border-radius: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #{default-theme.$secondary-fg};
    

    .item-custom-price-info {
      padding: 15px 15px;

      .item-info {
        display: grid;
        margin-bottom: 5px;
        grid-template-areas:
          'name   .    original-price';
    
        .name {
          grid-area: name;
        }
    
        .original-price {
          grid-area: original-price;
          text-align: right;
        }
    
      }

      .discount-info {
        .value {
          color: #{colors.$rose-700}
        }
      }

      .extra-charge-info {
        .value {
          color: #{colors.$teal-500}
        }
      }

      .discount-info,
      .extra-charge-info {
        display: grid;
        margin-bottom: 5px;
        grid-template-areas:
          'label   .    value';
        
    
        .label {
          grid-area: label;
        }
    
        .value {
          grid-area: value;
          text-align: right;
        }
      }
    }

    .new-price-info {
      grid-area: new-price-info;
      display: flex;
      justify-content: space-between;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      padding: 15px 15px;
      color: #{default-theme.$fg};
      border-top: 1px solid #{default-theme.$border-light};
    }
  }

  

  /**** CUSTOM PRICE ACTIONS ****/
  .add-customization {
    display: grid;
    grid-template-areas:
      'confirm'
      'cancel';
    grid-gap: 20px;
  }

  .edit-customization {
    display: grid;
    grid-template-areas:
      'revert confirm'
      'cancel cancel';
    grid-gap: 20px 10px;
  }

  .actions {
    .confirm-button {
      grid-area: confirm;
      @include cta.primary-cta;
      font-size: 17px;
      line-height: 23px;
      height: 50px;
    }
  
    .revert-button {
      grid-area: revert;
      @include buttons.primary-transparent;
      font-size: 17px;
      line-height: 23px;
      height: 50px;
    }
  
    .cancel-button {
      grid-area: cancel;
      @include cta.link-cta;
      line-height: 22px;
      height: 40px;
    }
  }

  /*--- Hide number-type input spin box ---*/
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  /*--------------------------------------*/
}
