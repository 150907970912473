@use "../common/card";
@use "../common/typography";
@use "../common/constants";
@use "../common/cta";
@use "../common/scrollbar";
@use "../common/colors";

.start-dine-in-order-widget {
  background-color: colors.$blue-gray-50;
  color: #{colors.$blue-gray-900};

  display: grid;
  place-items: center;


  .table-selector-widget {
    @include card.container;

    padding: 48px;
    width: 80vw;

    box-shadow: 0px 0px 3px rgba(46, 47, 77, 0.05), 0px 10px 25px rgba(89, 88, 123, 0.15);
    border-radius: 20px;

    .table-presets-widget {
      .actions {
        display: grid;
        grid-auto-flow: column;
        gap: 14px;

        button {
          width: 100%;
        }
      }
    }
  }
}
