@use '../common/colors';
@use '../common/scrollbar';
@use '../common/typography';

@mixin first-column-width($width) {
  table {
    th:first-of-type,
    td:first-of-type {
      width: $width;
    }
  }
}

.sales-trend-tabular-view-widget {
  .table-container {
    display: none;

    &.visible {
      display: block;
    }

    // unset/customise some default styles from Google Charts library
    .sales-trend-table {
      * {
        @include typography.p;
        @include scrollbar.hide;
      }

      table {
        thead,
        tr {
          display: table;
          width: 100%;
          table-layout: fixed;
        }

        tbody,
        th,
        td {
          overflow: scroll;
        }

        thead {
          background-color: #fbfdff;

          tr > th {
            color: colors.$blue-gray-700;
            padding: 12px 24px;
            @include typography.caption-1-400;
            font-weight: 500;
            border: none;

            &:first-child {
              text-align: left;
            }
          }
        }

        tbody {
          display: block;
          table-layout: fixed;
          max-height: 400px;
          background-color: colors.$white;

          tr > td {
            padding: 13.5px 24px;
            border: none;
            text-align: center;
            border-top: 1px solid colors.$blue-gray-100;
            @include typography.caption-1-400;

            &:first-child {
              border-left: 1px solid colors.$blue-gray-100;
              text-align: left;
              @include typography.body-2-500;
            }

            &:last-child {
              border-right: 1px solid colors.$blue-gray-100;
            }
          }

          :first-child {
            td {
              &:first-child {
                border-top-left-radius: 8px;
              }

              &:last-child {
                border-top-right-radius: 8px;
              }
            }
          }

          :last-child {
            td {
              border-bottom: 1px solid colors.$blue-gray-100;

              &:first-child {
                border-bottom-left-radius: 8px;
              }

              &:last-child {
                border-bottom-right-radius: 8px;
              }
            }
          }
        }
      }

      &.daily {
        @include first-column-width(25%);
      }

      &.monthly {
        @include first-column-width(30%);
      }

      &.weekly,
      &.hourly {
        @include first-column-width(35%);
      }
    }
  }
}
