@use "../common/colors";
@use "../common/scrollbar";
@use '../common/typography';
@use '../common/drop-down-input' as drop-down-input;

.report-time-interval-filter-widget {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .time-interval-selector {
    margin-right: 24px;

    .form-label {
      @include typography.caption-2-400;
      color: colors.$blue-gray-500;
    }

    .form-input {
      padding-top: 8px;
    }

    .drop-down-input {
      @include typography.body-2-500;
      @include drop-down-input.form-drop-down-input;
      min-width: 200px;
      padding: 12px 14px;
      border: 1px solid colors.$blue-gray-100;
      border-radius: 8px;
      z-index: 1;

      .value {
        padding: 0;
      }

      .down-arrow-icon {
        margin: 0;
      }

      .options {
        margin-top: 5px;
      }
    }
  }
}
