@use "viewport";
@use "scrollbar";
@use "constants";
@use "typography";
@use "z-indexes";

#modal-container {
  > .ReactModalPortal {
    > .ReactModal__Overlay {
      z-index: z-indexes.$modal;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(12, 13, 52, 0.45) !important;

      > .ReactModal__Content {
        padding: 0 !important;
        border-radius: constants.$border-radius-heavy !important;

        .modal {
          overflow: auto;
          max-height: calc(100vh - 56px);
        }
      }
    }
  }
}

@mixin container {
  @include viewport.mobile {
    max-width: 80vw;
    max-height: 80vh;
    overflow: scroll;
  }

  @include viewport.tablet {
  }
}

@mixin custom-item {
  display: grid;
  grid-template-areas:
    'spec-info  body'
    'selections body'
    'actions    body';
  grid-template-columns: min-content auto;
  grid-template-rows: min-content 1fr min-content;
  grid-gap: 20px;
  width: 90vw;
  height: 75vh;

  > .spec-info {
    grid-area: spec-info;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 33px;
    }

    .description {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
    }
  }

  > .selections {
    @include scrollbar.hide;
    grid-area: selections;
    overflow-y: auto;

    > ul {
      padding-left: 20px;
    }
  }

  > .actions {
    grid-area: actions;
  }

  > .body {
    @include scrollbar.hide;
    grid-area: body;
    overflow-y: auto;
  }
}

.modal {
  .close {
    position: absolute;
    width: 27px;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }
}

@mixin header {
  display: flex;

  .title {
    @include typography.h1-headline;
    text-align: center;
    flex-grow: 1;
    margin-bottom: 40px;
  }

  .close-button {
    margin-left: -32px; /* explicitly set to negative to let the title be in the center relative to modal */
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}
