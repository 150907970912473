@use '../common/colors' as colors;
@use "../common/typography" as typography;
@use "../common/ghost" as ghost;

.table-booking-view-details-widget {
  box-shadow: 0px 0px 3px 0px #2e2f4d0d;
  box-shadow: 0px 10px 25px 0px #59637b26;
  border: 1px solid colors.$blue-gray-100;
  background-color: colors.$white;
  border-radius: 10px;
  display: flex;
  margin: 0px 26px 33.5px 26px;

  .table-booking-details {
    display: grid;
    grid-template-columns: minmax(100px, 193px) minmax(100px, 193px) minmax(100px, 321px);
    padding: 24px;
    gap: 24px 32px;
  }

  .detail {
    color: colors.$blue-gray-900;

    .label {
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      color: colors.$blue-gray-500;
    }

    .value {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: colors.$blue-gray-900;
      overflow-wrap: break-word;
      margin-top: 4px;
      max-width: 220px;

      &.seating {
        font-weight: 400;
      }
    }

    &.status {
      .accepted {
        @include ghost.green;
      }
      .new {
        @include ghost.yellow;
      }
      .cancelled {
        @include ghost.red;
      }
    }
  }

  .actions {
    margin: 24px;
    display: flex;
    align-items: flex-start;
    margin-left: auto;

    button {
      @include typography.button-1;
      width: 100%;
      background-color: colors.$white;
      border-radius: 4px;
      .label {
        font-weight: 500;
      }
    }

    .edit-button {
      border: 1px solid colors.$blue-400;
      padding: 14px 32.25px;
      white-space: nowrap;

      .label {
        color: colors.$blue-800;
      }
    }

    .cancel-button {
      border: 1px solid colors.$rose-500;
      padding: 14px 32.5px;
      white-space: nowrap;
      margin-left: 24px;

      .label {
        color: colors.$rose-800;
      }
    }
  }
}
