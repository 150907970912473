@use "../common/default-theme";
@use "../common/cta";
@use '../common/typography';
@use '../common/buttons';

.pos-order-comment-widget {
  padding: 60px 160px 20px 160px;
  border-radius: 20px;
  min-width: 748px;

  .title {
    @include typography.h1;
    text-align: center;
  }

  .comments-form {
    > form textarea {
      border-radius: 8px;
      border: none;
      background-color: #{default-theme.$secondary-bg};
      font-size: 16px;
      padding-top: 14px;
    }
  }

  .add-comment.actions {
    display: grid;
    grid-template-areas:
      'confirm'
      'cancel';
    grid-gap: 20px;
  }
  .edit-comment.actions {
    display: grid;
    grid-template-areas:
      'clear confirm'
      'cancel cancel';
    grid-gap: 20px 10px;
  }

  button.confirm {
    grid-area: confirm;
    @include cta.primary-cta;
    font-size: 17px;
    line-height: 23px;
  }
  button.clear {
    grid-area: clear;
    @include buttons.primary-transparent;
    font-size: 17px;
    line-height: 23px;
  }
  button.cancel {
    grid-area: cancel;
    @include cta.link-cta;
    line-height: 22px;
  }
}
