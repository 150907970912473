@use "../common/colors";
@use "../common/scrollbar";
@use "../common/typography";

@mixin template {
  position: relative;
  margin-top: 40px;
  padding-bottom: 12px;
  width: inherit;

  .recharts-responsive-container {
    align-self: flex-end;

    .line-chart {
      .recharts-surface {
        // this displays the x-axis title
        overflow: visible;

        .recharts-xAxis {
          .x-axis-title {
            @include typography.body-2-500;
            font-weight: 400;
            text-align: center;
          }
        }

        .recharts-brush {
          .recharts-brush-traveller {
            display: none;
          }

          .recharts-brush-texts {
            display: none;
          }
        }
      }

      .recharts-tooltip-wrapper {
        .tooltip {
          border-radius: 8px;
          box-shadow: 0px 2px 16px 0px #0000001f;
          padding: 12px 13.5px;
          text-align: center;
          background-color: colors.$white;
          transform: translate(-50%);
          border: 1px solid colors.$blue-gray-100;

          .period {
            padding-bottom: 12px;
            color: colors.$blue-gray-500;
            @include typography.caption-1-400;
          }

          .datum {
            display: grid;
            grid-template-columns: minmax(auto, 130px) 1fr;
            gap: 8px;

            .datum-name {
              @include typography.caption-1-400;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .datum-sales {
              font-weight: 500;
              text-align: right;
            }
          }
        }
      }

      .recharts-legend-wrapper {
        max-width: 240px;
        padding-left: 32px;
        height: 80% !important;

        .chart-legend {
          background-color: colors.$white;
          border-radius: 8px;
          border: 1px solid colors.$blue-gray-100;
          padding: 24px;
          height: 100%;

          .title {
            @include typography.body-2-500;
            padding-bottom: 16px;
          }

          .data {
            height: 70%;
            overflow: scroll;
            @include scrollbar.hide;

            .datum {
              display: grid;
              gap: 12px;
              align-items: baseline;
              grid-template-columns: max-content 1fr;

              .datum-color {
                width: 12px;
                height: 12px;
                border-radius: 50%;
              }

              .datum-label {
                color: colors.$blue-gray-700;
                min-width: 135px;
                @include typography.caption-1-400;
              }
            }
          }

          .edit-button {
            width: fit-content;
            color: colors.$teal-600;
            text-decoration: underline;
            padding: 9.5px 16px;
            cursor: pointer;
            position: absolute;
            bottom: 24px;
            border-radius: 4px;
            @include typography.button-2;

            &:hover {
              background-color: colors.$teal-50;
            }
          }
        }
      }
    }
  }
}
