@use "../common/colors";
@use "../common/card";
@use "../common/scrollbar";

@mixin toggle {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url('../images/icon-chevron-right-active.svg');
  margin-right: 6px;
  background-repeat: no-repeat;
  background-position: center;
}

.sales-charts-view-widget {
  @include card.container;
  background: colors.$modifier-gradient-bg;
  padding: 36px 24px;
  box-shadow: colors.$card-shadow;

  .view-selectors {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    gap: 20px;
    border-bottom: 1px colors.$blue-gray-100 solid;

    .option {
      cursor: pointer;
      opacity: 0.5;
      padding-bottom: 8px;
      &.selected {
        font-weight: 600;
        opacity: 1;
        border-bottom: 2px colors.$blue-500 solid;
      }
    }
  }

  .bar-chart {
    padding-top: 12px;
  }
}
