@use "../common/typography"as typography;
@use "../common/cta" as cta;
@use "../common/default-theme"as default-theme;

.table-custom-edit-widget {
  padding: 60px 160px;

  .title {
    @include typography.h1;
    text-align: center;
    margin-bottom: 38px;
  }

  form {
    margin-bottom: 24px;
  }

  .actions {
    display: grid;
    gap: 10px;

    .confirm-button {
      @include cta.primary-cta;
    }

    .back-button,
    .skip-button,
    .continue-button {
      @include cta.link-cta;
      border: 1px solid #{default-theme.$secondary-fg};
      border-radius: 5px;
      font-size: 17px;
    }

    .cancel-button {
      @include cta.link-cta;
    }
  }
}
