@use "../common/typography.scss";

.popout-table-map-more-actions-widget {
  margin-top: 8px;
  @include typography.h3;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;

  > .edit-tables-button {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 12px;
    cursor: pointer;

    > .icon {
      display: grid;
      align-items: center;
    }
  }
}
