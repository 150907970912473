@use '../common/gap';
@use '../common/colors';

@mixin _button {
  width: 100%;
  padding: 14px 0;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  cursor: pointer;
}

.anz-worldline-order-split-bill-refund-widget {
  width: calc(100vw - 32px);
  max-width: 844px;
  padding: 32px 24px;

  > .header {
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  > .split-payments {
    border-radius: 8px;
    background-color: colors.$blue-gray-50;
    margin-top: 16px;

    &.partial-refund {
      margin-top: 8px;
    }

    > *:not(:first-child) {
      border-top: 1px solid colors.$blue-gray-100;
    }

    > .split-payment {
      display: grid;
      grid-template-columns: max-content 1fr max-content;
      grid-template-areas:
        'radio label                 amount               '
        '.     transaction-reference transaction-reference'
        '.     surcharge-label       surcharge-amount     '
        '.     items                 items                ';
      padding: 16px 20px;
      align-items: center;

      &:not(:first-child) {
        border-top: 1px solid colors.$blue-gray-100;
      }

      &.disabled {
        color: colors.$blue-gray-500;
        cursor: not-allowed;
      }

      > .radio {
        grid-area: radio;

        -webkit-appearance: none;
        appearance: none;

        width: 18px;
        height: 18px;
        margin-right: 14px;

        border: 1.2px solid colors.$blue-gray-100;
        border-radius: 50%;
        cursor: pointer;

        &:checked {
          border: 5px solid colors.$teal-500;
        }
      }

      > .label {
        grid-area: label;
        display: flex;
        align-items: center;

        > .payment-order {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }

        > .bullet {
          margin: 0 8px;
        }

        > .payment-type {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
      }

      > .amount {
        grid-area: amount;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        &.refund {
          color: colors.$rose-800;
        }
      }

      > .transaction-reference {
        grid-area: transaction-reference;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.035px;
        color: colors.$blue-gray-500;
      }

      > .surcharge {
        display: contents;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.035px;
        color: colors.$blue-gray-500;

        > .label {
          grid-area: surcharge-label;
          justify-self: start;
        }

        > .amount {
          grid-area: surcharge-amount;
          justify-self: end;
        }
      }

      > .split-bill-items {
        grid-area: items;
        color: colors.$blue-gray-500;
        margin-top: 8px;

        > .split-bill-item {
          display: list-item;
          margin-left: 40px;

          > .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > .name {
              color: colors.$blue-gray-900;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0.08px;
            }

            > .amount {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: 0.035px;
            }
          }
        }
      }
    }
  }

  > .actions {
    display: flex;
    @include gap.horizontal(16px);
    margin-top: 24px;

    > .back-button {
      @include _button;
      border: 1px solid colors.$blue-gray-500;
      background-color: transparent;
      color: colors.$blue-gray-700;
    }

    > .refund-button {
      @include _button;
      background-color: colors.$teal-600;
      color: colors.$white;

      &.disabled {
        color: colors.$teal-300;
        cursor: not-allowed;
      }
    }
  }
}
