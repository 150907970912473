@use "../common/colors";
@use "../common/scrollbar";
@use '../common/typography';

@mixin nav-button {
  cursor: pointer;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 23px;

  &.hide {
    visibility: hidden;
  }
}

.report-date-range-filter-widget {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  background-color: colors.$blue-gray-50;
  align-items: center;
  margin-top: 18px;
  padding: 8px;

  .selector {
    position: relative;
  }

  .form-input {
    input {
      @include typography.caption-1-400;
      cursor: pointer;
      padding: 13.5px 14px;
      border: 1px solid colors.$blue-gray-100;
      border-radius: 8px;
      background-image: url('../images/icon-calendar.svg');
      background-repeat: no-repeat;
      background-position: 95% center;
      min-width: 200px;
      outline: none;
    }
  }

  .options {
    @include scrollbar.hide;
    position: absolute;
    margin-top: 5px;
    width: 100%;
    border-radius: 8px;
    background-color: colors.$white;
    border: solid 1px colors.$blue-gray-100;
    max-height: 280px;
    overflow-y: scroll;
    z-index: 1;
    font-weight: 400;
    @include typography.body-2-500;

    .option {
      cursor: pointer;
      padding: 12px;
      border-bottom: 1px solid colors.$blue-gray-100;
      text-align: left;

      &.selected {
        background-image: url('../images/icon-check-alt.svg');
        background-repeat: no-repeat;
        background-position: right 14px top 50%;
        background-size: 20px;
      }

      &.disabled {
        color: colors.$blue-gray-100;
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }
  }

  .report-year-quarter-selector,
  .report-year-month-selector {
    .year-filter {
      padding: 12px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      border-bottom: 1px solid colors.$blue-gray-100;

      .year {
        cursor: pointer;
        background-color: colors.$light-gray-100;
        font-weight: 500;
        font-size: 18px;
        padding: 6px 8px;
        border-radius: 8px;
        line-height: 27px;
      }

      .previous-button {
        @include nav-button;
        background-image: url('../images/icon-chevron-down-filled.svg');
        transform: rotate(90deg);
      }

      .next-button {
        @include nav-button;
        background-image: url('../images/icon-chevron-down-filled.svg');
        transform: rotate(270deg);
      }
    }
  }

  .report-year-month-selector {
    .year-month-filter-options {
      max-height: fit-content;

      .month-filter-options {
        max-height: 400px;
        overflow-y: scroll;
        @include scrollbar.hide;
      }
    }
  }

  .report-form-iso-date-picker-input {
    .DayPickerInput > input {
      @include typography.caption-1-400;
      min-width: 200px;
      padding: 13.5px 14px;
    }
  }
}
