@use "../common/linkly-transaction-styles";

.linkly-recovery-mode-transaction-widget {
  @include linkly-transaction-styles.request-transaction-widget;
  width: 573px;
  padding: 32px 24px;

  > img {
    padding-bottom: 0;
    margin-bottom: 16px;
  }

  > .actions {
    margin: 30px 24px 0;
  }
}
