@use '../common/typography' as typography;
@use "../common/constants" as constants;
@use "../common/colors" as colors;

.manage-sold-out-items-page {
  input {
    @include typography.p;
    border: 1px solid colors.$blue-gray-100;
    padding: 12px 15px;
    border-radius: constants.$curve-padding;
    outline: none;
    text-indent: 30px;
    background-image: url('../images/icon-search.svg');
    background-repeat: no-repeat;
    background-position: 15px center;
    width: 380px;
  }

  > .header {
    .wrapper {
      padding: 24px 30px;
      align-items: end;
    }
  }

  .store-filter-widget {
    top: 24px;

    .form-input {
      border-radius: constants.$border-radius-medium;
    }
  }
}
