@use "../common/colors";
@use "../common/scrollbar";
@use "../common/typography";
@use "../common/sales-trends-line-chart";

.breakdown-sales-trend-line-chart-view-widget {
  @include sales-trends-line-chart.template();

  .recharts-cartesian-axis-ticks {
    font-size: 12px;
  }
}
