@use '../common/colors';
@use '../common/z-indexes';

.uber-eats-manage-order-widget {
  padding: 40px 32px 32px;
  color: colors.$blue-gray-900;

  .title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.36px;
  }

  .actions {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.08px;
  }

  > .select-step {
    display: grid;
    grid-auto-flow: row;
    row-gap: 40px;

    .steps {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;

      padding: 0px 20px;

      .step {
        display: grid;
        row-gap: 24px;
        justify-items: center;
        align-items: center;

        padding: 40px;

        border-radius: 8px;
        border: 1px solid colors.$blue-gray-100;
        background: colors.$white;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);

        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0.09px;

        cursor: pointer;
      }
    }

    .actions {
      width: 100%;
      padding: 0px 24px;

      .cancel {
        padding: 14px 32px;

        border-radius: 4px;
        border: 1px solid colors.$blue-gray-500;

        text-align: center;
        color: colors.$blue-gray-700;

        cursor: pointer;
      }
    }
  }

  > .delay-order {
    display: grid;
    grid-auto-flow: row;
    row-gap: 40px;

    min-width: 780px;

    .eta-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;

      .eta {
        position: relative;

        text-align: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.035px;

        padding: 16px;

        border-radius: 6px;
        background: colors.$modifier-gradient-bg;
        box-shadow: colors.$card-shadow;

        &.selected {
          background: colors.$blue-gray-900;
          color: colors.$white;
        }

        .check-icon {
          position: absolute;
          top: -6px;
          right: -6px;
        }
      }
    }

    .labels {
      display: grid;
      row-gap: 16px;

      > .info {
        display: flex;
        align-items: center;

        color: colors.$blue-gray-500;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.08px;

        // Fix for vertical alignment
        > img {
          margin-bottom: 2px;
          margin-right: 8px;
        }
      }

      > .pickup-time-labels {
        background-color: colors.$blue-gray-50;
        border-radius: 8px;

        .key-value-pair {
          padding: 12px 20px;

          display: flex;
          justify-content: space-between;

          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0.09px;

          &:first-child {
            border-bottom: 1px solid colors.$blue-gray-100;
          }
        }

        .new-pickup-time {
          font-weight: 600;
        }
      }
    }

    .actions {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      padding: 0 24px;

      .back {
        padding: 14px 32px;
        text-align: center;
        color: colors.$blue-gray-700;

        border-radius: 4px;
        border: 1px solid colors.$blue-gray-500;
      }

      .confirm {
        padding: 14px 32px;
        text-align: center;

        border-radius: 4px;
        background: colors.$teal-600;

        color: colors.$white;
      }
    }
  }
}

.uber-eats-manage-order-alert {
  z-index: z-indexes.$uber-eats-manage-order-alert !important;
}
