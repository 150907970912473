@use '../common/colors';
@use "../common/scrollbar";
@use "../common/typography";

.sales-trend-multi-line-chart-filter-widget {
  padding: 40px 32px 32px 32px;
  height: 90vh;
  display: grid;
  grid-template-rows: auto auto 1fr auto;

  .header {
    display: grid;
    grid-template-areas:
      'title title'
      'message clear-selection-button';
    gap: 8px;
    align-items: center;
    padding-bottom: 24px;

    .title {
      grid-area: title;
      color: colors.$blue-gray-900;
      @include typography.h1-headline;
    }

    .message {
      grid-area: message;
      color: colors.$blue-gray-500;
      @include typography.caption-1-400;
    }

    .clear-selection-button {
      grid-area: clear-selection-button;
      justify-self: end;
      color: colors.$blue-800;
      position: relative;
      text-decoration: underline;
      @include typography.button-2;
      cursor: pointer;
    }
  }

  .filter-container {
    border: 1px solid colors.$blue-gray-100;
    border-radius: 12px;
    padding: 16px;
    position: relative;
    overflow: hidden;

    .search-box {
      input {
        width: 100%;
        border: none;
        color: colors.$blue-gray-700;
        padding: 8px 0;
        outline: none;
        text-indent: 30px;
        background-image: url('../images/icon-search.svg');
        background-repeat: no-repeat;
        background-position: left;
        @include typography.body-2-500;

        &::placeholder {
          color: colors.$blue-gray-200;
        }
      }
    }

    .options {
      height: 90%;
      overflow-y: scroll;
      @include scrollbar.hide;

      .no-results {
        color: colors.$blue-gray-500;
        width: max-content;
        padding-top: 10%;
        margin: 0 auto;
      }
    }
  }

  .actions {
    display: grid;
    padding: 40px 16px 0 24px;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    .button {
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
    }

    .cancel-button {
      border: 1px solid colors.$blue-gray-500;
      color: colors.$blue-gray-700;
      padding: 14px 101.5px;
    }

    .confirm-button {
      background-color: colors.$teal-600;
      color: colors.$white;
      padding: 14px 50px;

      &.disabled {
        pointer-events: none;
        background-color: colors.$teal-300;
      }
    }
  }

  .error-message {
    color: colors.$rose-600;
    padding-bottom: 8px;
    @include typography.caption-1-400;
  }
}

.swal2-container {
  .cancel-edit-sales-trend-dialog {
    .swal2-html-container {
      color: colors.$blue-gray-700;
      @include typography.body-2-500;
    }
    .swal2-actions {
      button {
        text-transform: capitalize;
      }
      .confirm-button {
        background-color: colors.$rose-800;
      }

      .cancel-button {
        border: 1px solid colors.$blue-gray-500;
        color: colors.$blue-gray-700;
      }
    }
  }
}
