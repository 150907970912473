@use '../common/typography';
@use '../common/card';
@use '../common/constants';
@use '../common/colors';
@use '../common/cta';

.pos-more-widget {
  .store-pos-info {
    @include card.container;
    height: min-content;
    color: colors.$blue-gray-900;
    padding: 20px;
    display: grid;
    background: colors.$modifier-gradient-bg;
    box-shadow: colors.$card-shadow;
    grid-template-columns: min-content 1fr 1fr;
    grid-template-areas:
      'logo   store-name     actions'
      'logo   name           actions'
      'logo   id             actions';

    .store-name {
      grid-area: store-name;
      @include typography.h1;
    }

    .pos-terminal-name {
      grid-area: name;

      span {
        font-weight: bolder;
      }
    }

    .pos-terminal-id {
      grid-area: id;

      span {
        font-weight: bolder;
      }
    }

    .image {
      grid-area: logo;
      margin-right: 15px;

      img {
        border-radius: constants.$border-radius;
        width: 80px;
      }
    }

    .actions {
      grid-area: actions;
      justify-self: flex-end;
      margin-top: 20px;
      .logout {
        @include cta.danger-cta;
        font-size: 15px;
        padding: 10px 15px;
        border-width: 1px;
      }
    }
  }

  .quick-actions,
  .reports,
  .miscellaneous,
  .settings,
  .color-settings {
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      letter-spacing: 0;
      color: colors.$blue-gray-900;
      line-height: 60px;
      margin-bottom: 10px;
      margin: 30px 0px 10px 0px;
    }
    .card {
      display: grid;
      grid-template-columns: repeat(auto-fit, 260px);

      gap: 24px;
      width: 100%;
      .link {
        * {
          all: unset;
        }
        margin: 0px;
        padding: 40px 0px;
        width: 260px;
        height: 170px;
        @include card.container;
        display: grid;
        background: colors.$modifier-gradient-bg;
        box-shadow: colors.$card-shadow;
        text-align: center;
        .icon img {
          width: 42px;
        }
        .label {
          @include typography.h1;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          margin-top: 16px;
        }
      }
    }
  }
}
