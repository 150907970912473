@use "../common/colors";
@use "../common/typography";
@use "../common/cta";
@use "../common/scrollbar" as scroll;
@use "../common/constants" as constants;
@use '../common/elements';

.updated-cart-items-confirmation-widget {
  display: grid;
  grid-template-rows: repeat(2, min-content) 1fr min-content;
  padding: 40px 32px 0 32px;
  height: max-content;
  max-height: 80vh;
  min-width: 75vw;
  max-width: 92vw;
  overflow: scroll;
  @include scroll.hide;

  // for multiple updates, set fixed height
  &.multiple-updates {
    height: 80vh;
  }

  .header {
    @include typography.h1;
    text-align: center;
    font-size: 24px;
    margin-bottom: 40px;
  }

  .updated-cart-items {
    @include scroll.hide();
    max-height: 50vh;
    overflow: scroll;

    .description {
      font-size: 16px;
      margin-bottom: 12px;
      color: colors.$blue-gray-700;
    }

    .gst-inclusive-items, .gst-free-items, .sold-out-items {
      border: 1px solid colors.$blue-gray-100;
      border-radius: 12px;
      padding: 12px;
      margin-top: 8px;

      .header {
        display: flex;
        margin-bottom: 0;
    
        .icon {
          @include elements.icon(20px);
        }
    
        .title {
          font-size: 16px;
          font-weight: 600;
          color: colors.$black;
        }
    
        .chevron {
          @include elements.icon(24px);
          margin-left: auto;
        }
      }
     
  
      .items {
        color: colors.$blue-gray-700;
        padding-left: 20px;
        margin-top: 8px;
        display: none;
        line-height: 24px;

        &.visible {
          display: block;
        }
      }
    }
  }

  .actions {
    display: flex;
    height: fit-content;
    align-self: self-end;
    padding: 32px 24px;

    > button {
      flex-basis: 0;
      flex: 1 1 0px;
    }

    .proceed-button {
      @include cta.primary-cta;
    }

    .cancel-button {
      @include cta.tertiary-cta;
      padding: 14px;
      margin-right: 15px;

      &:focus {
        border: 1px solid colors.$blue-gray-500;
      }
    }
  }
}
