@use "../common/typography";
@use "../common/constants";
@use "../common/cta";
@use "../common/scrollbar";
@use "../common/colors";

.table-presets-widget {
  .title {
    @include typography.h1;
    text-align: center;
    margin-bottom: 40px;
  }

  .tables-container {
    max-height: 50vh;
    overflow: hidden scroll;
    @include scrollbar.hide;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
    margin-bottom: 40px;

    .tables {
      padding: 4px;
      gap: 10px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      grid-auto-rows: 1fr;

      .table,
      .add-table {
        border-radius: constants.$border-radius;
        background: colors.$modifier-gradient-bg;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        text-align: center;
        padding: 20px 0;
        @include typography.p;

        display: grid;
        grid-auto-flow: row;
        place-items: center;

        &.taken {
          background: colors.$yellow-100;

          .label {
            margin-top: 4px;
          }
        }
      }

      .add-table {
        display: flex;
        justify-content: center;

        .icon {
          width: 21px;
          height: 21px;
        }
      }
    }
  }

  .actions {
    display: grid;
    justify-items: center;
    gap: 16px;

    button {
      width: 430px;
    }

    .skip-button{
      @include cta.primary-cta;
    }

    .cancel-button {
      @include cta.link-cta;
      color: colors.$blue-gray-700;
      border: 1px solid colors.$blue-gray-500;
      border-radius: 5px;
      font-size: 15px;
    }

    .continue-button {
      @include cta.primary-cta;
    }
  }
}