@use '../common/banner';
@use "../common/colors";

.anz-worldline-incomplete-transaction-banner-widget {
  @include banner.base;
  background-color: colors.$rose-100;

  > .text {
    > .highlight {
      color: colors.$rose-800;
    }
  }

  > .actions {
    > .view-status-button {
      @include banner.button;
      background-color: colors.$teal-600;
      border: solid 1px colors.$teal-600;
      color: colors.$white;
    }
  }
}

.swal2-container {
  .anz-worldline-incomplete-transaction-banner-widget {
    width: 100% !important;
    max-width: 375px;
    padding: 24px 0 !important;

    .swal2-header {
      padding: 0 24px;

      .swal2-title {
        color: colors.$blue-gray-900;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .swal2-content {
      padding: 0 24px;
      margin-top: 12px;

      color: colors.$blue-gray-500;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    .swal2-actions {
      padding: 0 24px;
      margin-top: 24px;
      flex-direction: column;
      gap: 16px;

      .confirm-button {
        width: 100%;
        background-color: colors.$rose-800;
        border-radius: 4px;
        padding: 14px 32px;
        margin: 0;

        text-transform: capitalize;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .cancel-button {
        width: 100%;
        background-color: colors.$white !important;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
        border-radius: 4px;
        padding: 14px 32px;
        margin: 0;

        text-transform: capitalize;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
