@use "../common/colors";
@use '../common/typography';

.pizza-sales-reports-page {
  position: relative;
  min-height: 100vh;

  > .spinner-container {
    position: absolute;
    top: 0;
  }

  > .header {
    @include typography.h1;
    display: grid;
    grid-template-columns: 1fr min-content;
    padding: 22px 24px 16px;
    align-items: center;
    margin-bottom: 0;
  }

  > .display-selector {
    border-bottom: 1px solid colors.$light-gray-100;
    display: flex;
    padding-left: 24px;

    > .option {
      cursor: pointer;
      padding: 12px;
      color: colors.$blue-gray-700;
      @include typography.button-2;
      letter-spacing: 0.005em;
      text-align: center;

      &.active {
        font-weight: 600;
        color: colors.$teal-600;
        border-bottom: 4px solid colors.$teal-600;
      }
    }
  }

  .pizza-size-sales-report-widget {
    > .header {
      display: none;
    }

    .filters {
      display: flex;
      justify-content: space-between;
    }

    .help-text {
      @include typography.caption-1-400;
      display: flex;
      align-items: center;
      color: colors.$blue-gray-700;
      img {
        padding-right: 8px;
      }
    }
  }

  .pizza-item-sales-report-widget {
    > .header {
      display: none;
    }

    .order-source-and-pizza-size-filters {
      display: flex;
      gap: 14px;
    }

    .help-text {
      @include typography.caption-1-400;
      display: flex;
      align-items: center;
      color: colors.$blue-gray-700;
      img {
        padding-right: 8px;
      }
    }
  }
}
