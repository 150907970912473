.home-page {
  height: inherit;

  > .header {
    margin: 0 !important;
    width: 0;
  }

  > .body {
    height: inherit;
  }


  .home {
    display: grid;
    grid-template-areas: 'menu-sidebar-widget menu-items-widget cart-widget';
    grid-template-columns: min-content 1fr min-content;
    height: inherit;
    overflow: hidden;
  }

}
