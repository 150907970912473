@use '../common/colors';
@use '../common/typography';
@use '../common/drop-down-input' as drop-down-input;

.pos-terminal-sales-report-widget {
  .filters {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 24px;

    .pos-terminal-selector {
      .form-label {
        @include typography.caption-2-400;
        color: colors.$blue-gray-500;
      }

      .form-input {
        padding-top: 8px;
      }

      .drop-down-input {
        @include typography.body-2-500;
        @include drop-down-input.form-drop-down-input;
        z-index: 0;
        min-width: 200px;
        padding: 12px 14px;
        border: 1px solid colors.$blue-gray-100;
        border-radius: 8px;

        .value {
          padding: 0;
        }

        .down-arrow-icon {
          margin: 0;
        }

        .options {
          margin-top: 5px;
        }
      }
    }
  }
}
