@use "../common/typography";
@use "../common/colors";
@use "../common/constants";

%customization {
  display: flex;
  justify-content: space-between;
  border-radius: constants.$border-radius-small;
  cursor: pointer;
  padding-block: 4px;

  > .label {
    word-wrap: break-word;
  }

  > .value {
    word-wrap: break-word;
  }
}

@keyframes highlightSurcharge {
  0%,
  100% {
    background-color: #{colors.$rose-500}40;
  }

  50% {
    background-color: #{colors.$rose-500}80;
  }
}

.cart-amount-widget {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-top: solid 1px colors.$blue-gray-800;

  > .amount {
    @include typography.h5;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    color: #{colors.$white}99;
    padding-inline: 8px;
    margin-bottom: 8px;

    > .value {
      color: colors.$white;
    }
  }

  > .public-holiday-surcharge {
    @extend %customization;
    background-color: #{colors.$rose-500}40;
    animation-name: highlightSurcharge;
    animation-duration: 2s;
  }

  > .custom-surcharge {
    @extend %customization;
    background-color: #{colors.$rose-500}40;
  }

  > .custom-discount {
    @extend %customization;
    background-color: #{colors.$yellow-600}40;
  }

  > .loyalty-discount {
    @extend %customization;
    background-color: #{colors.$blue-600}40;
  }

  > .grand-total {
    @include typography.h2;
    margin-bottom: 8px;
    color: colors.$white;

    > .value {
      color: colors.$yellow-600;
      border-bottom: 1px solid #{colors.$yellow-600}80;
    }
  }
}
