// common
@use "common/typography";
@use "common/default-theme";
@use "common/viewport";
@use "common/card";
@use "common/buttons";
@use "common/spinner";
@use "common/switch";
@use "common/toast";
@use "common/modal";
@use "common/alert";
@use "common/constants";
@use "common/scrollbar";
@use "common/colors";
@use "common/daypicker";
@use "common/cta";
@use "common/input";
@use "common/page";
@use "common/sweetalert";
@use "common/actions";
@use "common/aspect_ratio";
@use "common/order-list";
@use "common/popout";
@use "common/pill";
@use "common/start-order-iso-date-picker";
@use "common/start-order-time-of-day-picker";
@use 'common/error-boundary';
@use 'common/loading-widget';
@use 'common/linkly-transaction-styles';
@use 'common/employee-time-clock';
@use 'common/duration-input';
@use 'common/drop-down-input';
@use 'common/text-input';
@use 'common/sales-trends-line-chart';
@use 'common/payment-summary';
@use 'common/refund-summary';
@use 'common/banner';
@use 'common/removable-tag';

// elements
@use "elements/employee-time-clock-header";
@use "elements/employee-time-clock-date-range-picker";
@use "elements/employee-time-clock-start-end-shift-confirmation";
@use "elements/employee-time-clock-start-end-break-confirmation";
@use "elements/report-form-iso-date-picker-input";
@use "elements/period-coverage-form-iso-date-picker-input";
@use "elements/orders-list-item-store-name";

// forms
@use "forms/form";
@use "forms/form-input";
@use "forms/form_select";
@use "forms/form-multi-select-checkbox";
@use "forms/form_single_select_pill";
@use "forms/form_multi_select_pill";
@use "forms/form-color-picker";
@use "forms/form-select-pax";

// layout
@use "layout/pos-layout";

// pages
@use "pages/home-page";
@use "pages/ordering-page";
@use "pages/order-history-page";
@use "pages/pos-customer-list-page";
@use 'pages/print-station-list-page';
@use 'pages/report-page';
@use 'pages/manage-sold-out-items-page';
@use 'pages/lock-screen-page';
@use 'pages/pos-more-page';
@use 'pages/category-colors-page';
@use 'pages/item-colors-page';
@use 'pages/third-party-deliveries-page';
@use 'pages/third-party-completed-deliveries-page';
@use 'pages/new-table-bookings-page';
@use 'pages/table-bookings-page';
@use 'pages/integrations-page';
@use 'pages/employee-time-clock-staff-records-page';
@use 'pages/employee-time-clock-timesheet-page';
@use 'pages/pizza-sales-reports-page';
@use 'pages/incoming-online-orders-page';
@use 'pages/pos-customer-view-page';
@use 'pages/dine-in-page';
@use 'pages/customer-reviews-page';

// widgets
@use "widgets/link";
@use "widgets/header";
@use "widgets/sidebar";
@use "widgets/search-widget";
@use "widgets/home-widget";
@use "widgets/ordering-widget";
@use "widgets/menu-items-widget";
@use "widgets/menu-item-widget";
@use "widgets/reorder-list-widget";
@use "widgets/pos-reorder-list-item-widget";
@use "widgets/website-reorder-list-item-widget";
@use "widgets/google-reorder-list-item-widget";
@use "widgets/reorder-item-widget";
@use "widgets/pizza-half-widget";
@use "widgets/basic-item-widget";
@use "widgets/custom-item-widget";
@use "widgets/pizza-widget";
@use "widgets/deal-widget";
@use "widgets/customer-notes-edit-widget";
@use "widgets/customer-info-and-requested-for-edit-widget";
@use "widgets/half-and-half-widget";
@use "widgets/cart-items-widget";
@use "widgets/cart-amount-widget";
@use "widgets/pagination-widget";
@use "widgets/pos-time-range-select-widget";
@use "widgets/suburb-list-widget";
@use "widgets/iso-date-picker-widget";
@use "widgets/iso-date-range-picker-widget";
@use "widgets/time-of-day-list-widget";
@use 'widgets/item-custom-discount-widget';
@use 'widgets/item-custom-price-widget';
@use "widgets/item-comments-widget";
@use 'widgets/cart-item-widget';
@use "widgets/pos-order-summary-widget";
@use "widgets/pos-custom-surcharge-widget";
@use "widgets/pos-custom-discount-widget";
@use "widgets/menu-sidebar-widget";
@use "widgets/menu-main-widget";
@use "widgets/requested-for-preset-selector-widget";
@use 'widgets/customer-info-edit-widget';
@use "widgets/dine-in-customer-info-edit-widget";
@use "widgets/collection-time-schedule-edit-widget";
@use "widgets/incoming-online-orders-list-widget";
@use "widgets/incoming-uber-eats-orders-from-selected-store-list-widget";
@use "widgets/incoming-online-orders-list-item-widget";
@use "widgets/eta-selector-widget";
@use "widgets/estimated-prep-complete-iso-date-time-selector-widget";
@use "widgets/pos-order-list-widget";
@use "widgets/pos-customer-list-widget";
@use "widgets/pos-customer-list-item-widget";
@use "widgets/pos-customer-view-widget";
@use "widgets/dine-in-info-widget";
@use "widgets/table-selector-widget";
@use "widgets/table-presets-widget";
@use "widgets/table-custom-edit-widget";
@use "widgets/online-order-list-widget";
@use "widgets/website-order-list-widget";
@use 'widgets/google-order-list-item-widget';
@use "widgets/pos-order-list-item-widget";
@use "widgets/website-list-item-widget";
@use "widgets/pos-order-view-widget";
@use "widgets/website-order-view-widget";
@use 'widgets/google-order-view-widget';
@use "widgets/pos-order-comment-widget";
@use "widgets/cash-or-card-refund-widget";
@use "widgets/refund-type-select-widget";
@use "widgets/order-versions-payment-refund-widget";
@use "widgets/partial-refund-widget";
@use "widgets/start-pickup-order-widget";
@use "widgets/start-dine-in-order-widget";
@use "widgets/start-delivery-order-widget";
@use "widgets/start-saved-orders-widget";
@use "widgets/start-make-payment-widget";
@use "widgets/type-select-widget";
@use "widgets/payment-type-select-widget";
@use "widgets/refund-payment-type-select-widget";
@use "widgets/cash-payment-collect-widget";
@use "widgets/card-payment-collect-widget";
@use "widgets/card-payment-anz-worldline-moto-collect-widget";
@use "widgets/card-refund-payment-widget";
@use "widgets/split-type-select-widget";
@use "widgets/split-equally-count-select-widget";
@use "widgets/split-equally-payment-type-select-widget";
@use "widgets/split-equally-payment-summary-widget";
@use "widgets/split-by-item-select-widget";
@use "widgets/split-by-item-payment-type-select-widget";
@use "widgets/split-by-item-payment-summary-widget";
@use "widgets/custom-delivery-charge-widget";
@use "widgets/google_autocomplete";
@use 'widgets/print-station-list-widget';
@use "widgets/print-station-list-item-widget";
@use 'widgets/print-station-edit-widget';
@use 'widgets/category-colors-list-widget';
@use 'widgets/item-colors-list-widget';
@use 'widgets/quantity-edit-widget';
@use 'widgets/saved-orders-widget';
@use 'widgets/order-view-widget';
@use 'widgets/report-widget';
@use 'widgets/sales-trend-multi-line-chart-filter-widget';
@use 'widgets/sales-trend-line-chart-view-widget';
@use 'widgets/report-trend-table-view-toggle';
@use 'widgets/sales-trend-table-view-widget';
@use 'widgets/docket-type-select-widget';
@use 'widgets/key-value-pair';
@use 'widgets/custom-sales-date-picker-widget';
@use 'widgets/manage-item-routing-widget';
@use 'widgets/terminal-settings-widget';
@use 'widgets/date-range-picker-widget';
@use 'widgets/website-order-delivery-channel-selector-widget';
@use 'widgets/website-order-delivery-status-widget';
@use 'widgets/order-source-filter-selector-widget';
@use 'widgets/pos-terminal-sales-report-widget';
@use 'widgets/notification-settings-widget';
@use 'widgets/breakdown-sales-trend-line-chart-view-widget';
@use 'widgets/store-sales-report-breakdown-widget';
@use 'widgets/requesting-uber-delivery-widget';
@use 'widgets/uber-delivery-request-for-website-order-success-widget';
@use 'widgets/uber-delivery-request-for-pos-order-failed-widget';
@use 'widgets/uber-delivery-request-for-website-order-failed-widget';
@use 'widgets/uber-delivery-cancel-payment-confirmation-widget';
@use 'widgets/uber-delivery-proof-of-delivery-picture-widget';
@use 'widgets/time-picker';
@use 'widgets/time-period-edit-widget';
@use 'widgets/pos-trading-hours-edit-widget';
@use 'widgets/print-station-print-option-widget';
@use 'widgets/manage-sold-out-items-widget';
@use 'widgets/item-print-priority-widget';
@use "widgets/login-widget";
@use 'widgets/employee-time-clock-actions-widget';
@use "widgets/lock-screen-widget";
@use "widgets/employee-discount-pin-widget";
@use "widgets/employee-pin-widget";
@use 'widgets/tag-along-print-stations-widget';
@use 'widgets/permissions-manager';
@use 'widgets/weather-info-widget';
@use 'widgets/current-date-and-time-info-widget';
@use 'widgets/pos-more-widget';
@use 'widgets/pos-grand-total-edit-widget';
@use "widgets/split-by-amount-input-widget";
@use "widgets/split-by-amount-payment-type-select-widget";
@use "widgets/split-by-amount-payment-summary-widget";
@use 'widgets/item-summary-description-view-widget';
@use 'widgets/deal-suggestions-widget';
@use 'widgets/deal-suggestion-card-widget';
@use 'widgets/print-docket-settings-widget';
@use "widgets/new-table-booking-notification-widget";
@use "widgets/new-table-booking-list-widget";
@use "widgets/new-table-booking-list-item-widget";
@use "widgets/add-new-table-booking-widget";
@use "widgets/table-bookings-list-widget";
@use "widgets/_table-booking-list-item-widget";
@use "widgets/table-booking-reject-reason-selector-widget";
@use "widgets/table-booking-view-details-widget";
@use "widgets/table-booking-back-link-header-widget";
@use 'widgets/popout-cart-item-quantity-widget';
@use 'widgets/popout-cart-item-more-actions-widget';
@use 'widgets/popout-collection-type-selector-widget';
@use 'widgets/popout-cart-header-more-actions-widget';
@use 'widgets/customer-algolia-index-record-list-widget';
@use 'widgets/cart-widget';
@use 'widgets/cart-header-widget';
@use 'widgets/cart-header-info-widget';
@use 'widgets/cart-requested-for-widget';
@use 'widgets/cart-dine-in-info-widget';
@use 'widgets/cart-customer-info-widget';
@use 'widgets/cart-main-widget';
@use 'widgets/cart-actions-widget';
@use "widgets/service-worker-update-banner-widget";
@use "widgets/audio-playback-banner-widget";
@use 'widgets/offline-widget';
@use 'widgets/error-widget';
@use 'widgets/order-history-widget';
@use 'widgets/period-coverage-filter-widget';
@use 'widgets/loyalty-credits-widget';
@use 'widgets/pos-order-delivery-status-widget';
@use 'widgets/uber-delivery-cancel-order-confirmation-widget';
@use 'widgets/uber-delivery-payment-over-phone-confirmation-widget';
@use 'widgets/uber-delivery-request-for-pos-order-success-widget';
@use 'widgets/uber-delivery-collect-payment-for-pos-order-widget';
@use 'widgets/table-booking-edit-widget';
@use 'widgets/table-booking-edit-form-widget';
@use "widgets/table-booking-activity-logs-widget";
@use 'widgets/table-booking-activity-logs-list-widget';
@use 'widgets/table-booking-activity-logs-list-item-widget';
@use 'widgets/table-booking-iso-date-picker-widget';
@use 'widgets/table-booking-time-of-day-list-widget';
@use 'widgets/uber-delivery-failed-view-widget';
@use 'widgets/split-bill-payment-summary-widget';
@use 'widgets/pos-order-version-payment-summary-widget';
@use 'widgets/order-history-list-widget';
@use "widgets/pos-order-history-list-item-widget";
@use "widgets/website-order-history-list-item-widget";
@use 'widgets/google-order-history-list-item-widget';
@use 'widgets/integrations-widget';
@use 'widgets/uber-proof-of-delivery-widget';
@use 'widgets/tyro-settings-widget';
@use 'widgets/tyro-iclient-logs-widget';
@use 'widgets/split-bill-refund-widget';
@use 'widgets/tyro-terminal-info-widget';
@use "widgets/employee-time-clock-staff-records-widget";
@use "widgets/employee-time-clock-shift-list-edit-widget";
@use "widgets/employee-time-clock-shift-list-item-edit-widget";
@use "widgets/employee-time-clock-timesheet-widget";
@use "widgets/employee-time-clock-shift-list-widget";
@use 'widgets/employee-time-clock-shift-break-presets-widget.scss';
@use 'widgets/loyalty-card-widget.scss';
@use 'widgets/loyalty-credits-redeemable-widget.scss';
@use 'widgets/loyalty-card-rewards-count-widget.scss';
@use 'widgets/item-summary-view-widget';
@use 'widgets/split-bill-item-summary-view-widget';
@use 'widgets/item-description-view-widget';
@use 'widgets/custom-payment-collect-widget';
@use 'widgets/tyro-payment-summary-widget';
@use 'widgets/tyro-sales-report-widget';
@use 'widgets/tyro-reconciliation-report-summary-widget';
@use 'widgets/tyro-reconciliation-report-detail-widget';
@use 'widgets/radio-group-input-widget';
@use 'widgets/date-picker-widget';
@use 'widgets/tyro-incomplete-transaction-banner-widget';
@use 'widgets/refresh-menu-banner';
@use 'widgets/report-charts-template';
@use 'widgets/sales-charts-view-widget';
@use 'widgets/sales-trend-tabular-view-widget';
@use 'widgets/report-time-interval-filter-widget';
@use 'widgets/report-date-range-filter-widget';
@use 'widgets/report-time-range-filter-widget';
@use 'widgets/sales-report-table-overview-widget';
@use 'widgets/store-filter-widget';
@use 'widgets/connected-store-manage-sold-out-items-widget';
@use 'widgets/sales-report-store-multi-select-widget';
@use 'widgets/pizza-size-filter-selector-widget';
@use 'widgets/table-taken-warning-widget';
@use 'widgets/order-in-use-warning-widget';
@use 'widgets/order-error-widget';

@use 'widgets/linkly-payment-summary-widget';
@use 'widgets/linkly-settings-widget';
@use 'widgets/linkly-pinpad-pairing-widget';
@use 'widgets/linkly-refund-transaction-request-success-widget';
@use 'widgets/linkly-purchase-transaction-request-success-widget';
@use 'widgets/linkly-transaction-request-failed-widget';
@use 'widgets/linkly-recovery-mode-transaction-widget';
@use 'widgets/linkly-incomplete-transaction-banner-widget';

@use 'widgets/uber-eats-order-history-list-item-widget';
@use 'widgets/uber-eats-order-list-item-widget';
@use 'widgets/uber-eats-reject-reason-selector-widget';
@use 'widgets/uber-eats-order-delivery-status-widget';
@use 'widgets/uber-eats-store-status-banner-widget';
@use 'widgets/uber-eats-order-view-widget';
@use 'widgets/uber-eats-pickup-time-selector-widget';
@use 'widgets/uber-eats-manage-order-widget';
@use 'widgets/uber-eats-error-modal';
@use 'widgets/invalid-items-widget';

@use "widgets/new-order-notification-widget";
@use "widgets/failed-order-notification-widget";
@use 'widgets/cancelled-order-notification-widget';

@use 'widgets/troubleshooting-options-widget';
@use 'widgets/restart-instructions-widget';

@use 'widgets/anz-worldline-payment-summary-widget';
@use 'widgets/help-instructions-widget';
@use 'widgets/anz-worldline-settings-widget';
@use 'widgets/anz-worldline-request-progress-widget';
@use 'widgets/updated-cart-items-confirmation-widget';
@use 'widgets/pos-home-quick-actions-colors-widget';
@use 'widgets/alert-widget';
@use 'widgets/anz-worldline-incomplete-transaction-banner-widget';
@use 'widgets/anz-worldline-refund-widget';
@use 'widgets/anz-worldline-order-split-bill-refund-widget';
@use 'widgets/anz-worldline-ongoing-split-bill-refund-widget';

@use 'widgets/marketing-campaign-widget';

@use 'widgets/third-party-deliveries-widget';
@use 'widgets/third-party-completed-deliveries-widget';
@use 'widgets/order-delivery-info-list-widget';
@use 'widgets/order-delivery-info-list-item-widget';

@use 'widgets/dine-in-widget';
@use 'widgets/table-map-widget';
@use 'widgets/table-map-table-edit-widget';
@use 'widgets/popout-table-map-more-actions-widget';
@use 'widgets/popout-table-map-add-tables-widget';
@use 'widgets/popout-zoom-options-widget';
@use 'widgets/dine-in-guest-count-edit-widget';
@use 'widgets/dine-in-guest-count-covers-report-widget';
@use 'widgets/dine-in-guest-count-covers-report-breakdown-widget';
@use 'widgets/dine-in-guest-count-covers-trend-line-chart-view-widget';
@use 'widgets/dine-in-guest-count-covers-trend-table-view-widget';
@use 'widgets/dine-in-guest-count-covers-report-table-overview-widget';

@use 'widgets/customer-reviews-widget.scss';
@use 'widgets/customer-reviews-list-widget.scss';

@font-face {
  font-family: 'Sailec-Medium';
  src: url('../fonts/Sailec-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Sailec-Regular';
  src: url('../fonts/Sailec-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Sailec-Bold';
  src: url('../fonts/Sailec-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'kredit';
  src: url('../fonts/kredit.ttf') format('truetype');
}

@font-face {
  font-family: 'larabie';
  src: url('../fonts/larabie.ttf') format('truetype');
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #{default-theme.$bg};
  font-size: 15px;
  line-height: 1.6;
  overscroll-behavior-y: none;
  @include typography.p;

  // Hide refresh menu banner if the user is in lock screen
  #root {
    &:has(.pos-layout > .lock-screen-widget) {
      .refresh-menu-banner {
        display: none;
      }
    }
  }
}

/*Temporary css declaration to hide emulator warning until internal bug is fixed and `disableWarnings` flag can be passed in without typescript warnings*/
.firebase-emulator-warning {
  display: none;
}

// Google autocomplete
.pac-container {
  max-height: 135px;
  overflow: auto;
}
