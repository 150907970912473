@use '../common/colors';
@use '../common/scrollbar';

@mixin _list-item {
  border: solid 1px colors.$blue-gray-100;
  margin-top: -1px;

  &.highlighted {
    border: 1px solid colors.$orange-500 !important;
    background: rgba(255, 251, 235, 0.5);

    // remove the top border of the next sibling
    // as it blocks the bottom orange border
    + div {
      border-top: none;
    }
  }

  &:first-child {
    margin-top: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  // Select the second to the last child
  // because the last child is always the
  // label element: 'You've reached the end of the list.'
  &:nth-last-child(2) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

@mixin _no-orders {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 69px;
  border: solid 1px colors.$blue-gray-100;
  border-radius: 8px;

  > .label {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: colors.$blue-gray-700;

    margin-top: 8px;
  }
}

.order-history-list-widget {
  @include scrollbar.hide;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 0 24px;
  margin-top: -122px;

  &.show-date-time-filter {
    > .orders {
      transform: translateY(122px);
    }

    > .no-orders,
    > .no-order-search-results {
      margin-top: 122px;
    }
  }

  &.transform-none {
    > .orders {
      transform: none;
      margin-top: 122px;
      transition: none;
    }
  }

  > .orders {
    @include scrollbar.hide;
    display: flex;
    flex-direction: column;
    overflow: auto;
    transition: transform 0.3s ease-out;

    > .order-history-list-item-widget {
      padding: 24px;

      &.connected-stores {
        padding: 16px 24px;
      }
    }

    > .end-of-list {
      color: colors.$blue-gray-500;
      text-align: center;
      font-size: 14px;
      padding: 24px 0;
    }

    > .pos.order-history-list-item-widget {
      @include _list-item;
    }

    > .website.order-history-list-item-widget {
      @include _list-item;
    }

    > .google.order-history-list-item-widget {
      @include _list-item;
    }

    > .uber-eats.order-history-list-item-widget {
      @include _list-item;
    }
  }

  > .no-orders {
    @include _no-orders;
    transition: margin 0.3s ease-out;
  }

  > .no-order-search-results {
    @include _no-orders;
    transition: margin 0.3s ease-out;

    > .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      color: colors.$blue-gray-900;

      margin-top: 8px;

      > .subtext {
        font-weight: 500;
      }

      > .customer-page-link {
        color: colors.$teal-500;
        cursor: pointer;
      }
    }
  }

  > .back-to-top-button {
    position: fixed;
    top: 82vh;
    right: 20px;
    padding: 13px;
    background-color: colors.$white;
    border: 1px solid colors.$blue-gray-100;
    border-radius: 30px;
    box-shadow: 0 2px 8px #687a9b40;
  }
}
