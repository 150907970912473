@use "../common/typography";
@use "../common/colors";

.dine-in-guest-count-edit-widget {
  width: 446px;
  border-radius: 20px;
  padding: 32px;
  gap: 40px;

  .title {
    @include typography.h1-headline;
    text-align: center;
    color: colors.$blue-gray-900;
    margin-bottom: 40px;
  }

  .quantity {
    display: flex;
    justify-content: space-between;
    padding: 4px 12px 7.5px 12px;
    width: 100%;

    button {
      cursor: pointer;
      background: transparent;
      border: none;
      box-shadow: none;
      z-index: 99;

      &.decrement-button {
        padding: 8.5px 3px;
      }

      &.increment-button {
        padding: 2.5px;
      }
    }
  }

  .custom-guest-count-box {
    border: 1px solid colors.$blue-gray-100;
    border-radius: 8px;

    &.invalid {
      border-color: #f43f5e;
    }

    .header {
      color: colors.$blue-gray-500;
      padding: 7.5px 12px 0;
    }

    .custom-guest-count-input {
      @include typography.body-2-400;
      width: 50%;
      border: none;
      text-align: center;
      background-color: transparent;

      &:focus {
        outline: none;
      }

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .validation {
    margin-bottom: 40px;
    color: #9f1239;
    font-size: 12px;
    line-height: 18px;
    text-align: right;

    &.invalid {
      margin-top: 6px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    gap: 12px;

    .cancel {
      @include typography.body-2-400;
      border-radius: 4px;
      border: 1px solid colors.$blue-gray-500;
      padding: 14px 32px;
      color: colors.$blue-gray-700;
      background-color: colors.$white;
      cursor: pointer;
    }

    .confirm {
      @include typography.body-2-400;
      border-radius: 4px;
      padding: 14px 32px;
      background-color: colors.$teal-600;
      color: colors.$white;
      font-weight: 500;
      border: none;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;

      &:disabled {
        background-color: colors.$teal-300;
        cursor: not-allowed;
      }
    }
  }
}
