@use '../common/colors';
@use '../common/typography';
@use "../common/scrollbar";

.sales-trend-table-view-widget {
  .sales-trend-table {
    * {
      // unset/customise some default styles from Google Charts library
      background: unset;
      @include typography.p;
      white-space: normal;
      @include scrollbar.hide;

      td,
      th {
        &:not(:first-child),
        &:not(:last-child) {
          border-right: none;
          border-left: none;
        }
      }

      th {
        border-top: 1px solid colors.$blue-gray-100;;
        border-bottom: none;
      }

      td:first-child,
      th:first-child {
        border-left: 1px solid colors.$blue-gray-100;;
      }
      td:last-child,
      th:last-child {
        border-right: 1px solid colors.$blue-gray-100;;
      }
    }

    table {
      width: 100%;
      table-layout: fixed;
      box-shadow: colors.$card-shadow;

      .header-row {
        color: colors.$blue-gray-700;

        .header-cell {
          padding: 12px 24px;
          border: 1px solid colors.$white;
          text-align: right;
          background-color: colors.$white;
          @include typography.body-3-500;
          font-weight: 600;

          &:first-child {
            text-align: left;
          }
        }
      }

      tBody {
        :first-child {
          td {
            border-top: 1px solid colors.$blue-gray-100;;
            &:first-child {
              border-top-left-radius: 8px;
            }
          }

          td:last-child {
            border-top-right-radius: 8px;
          }
        }

        :last-child {
          &:last-child td:first-child {
            border-bottom-left-radius: 8px;
          }
          &:last-child td:last-child {
            border-bottom-right-radius: 8px;
          }
        }

        tr {
          .table-cell {
            padding: 12px 32px;
            text-align: right;
            @include typography.caption-1-400;

            &:first-of-type {
              text-align: left;
              @include typography.body-2-500;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
