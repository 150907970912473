@use '../common/colors';

.restart-instructions-widget {
  display: grid;
  gap: 24px;
  padding: 32px 24px;
  
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: colors.$blue-gray-900;
  }

  .instructions {
    display: grid;
    gap: 20px;
    max-width: 40vw;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: colors.$blue-gray-700;
    padding-left: 32px;
  }
}