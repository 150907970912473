@use '../common/colors';
@use '../common/typography';

.help-instructions-widget {
  background: colors.$light-gray-50;
  border-radius: 12px;
  padding: 24px;
  display: grid;
  gap: 12px;

  > .header {
    display: flex;
    align-items: center;
    font-size: 14px;
    > .title {
      margin-left: 8px;
    }
    > .icon {
      height: 16px;
    }
  }

  .body {
    display: grid;
    gap: 24px;

    .help-instruction-widget {
      > .title {
        cursor: pointer;
        @include typography.h5;
        background: url('../images/icon-chevron-down-grey.svg') no-repeat right center;
        padding-right: 25px;
      }

      &.open > .title {
        background: url('../images/icon-chevron-up-grey.svg') no-repeat right center;
      }

      ol {
        padding-top: 17.5px;
        padding-left: 16px;
      }
      &:not(:last-child) ol {
        margin-bottom: 17.5px;
      }
    }
  }
}
