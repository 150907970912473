@use "../common/typography";
@use "../common/cta";
@use "../common/constants";
@use "../common/colors";
@use "./type-select-widget" as type-select;
@use "../common/default-theme";
@use "../common/scrollbar" as scroll;

.custom-payment-collect-widget {
  display: flex;
  flex-direction: column;
  padding: 40px;
  max-width: 72vw;
  .header {
    text-align: center;
    @include typography.h1;
    font-size: 24px;
  }

  .custom-payments {
    @include scroll.hide();
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    gap: 15px;
    margin-top: 20px;
    padding: 10px;
    max-width: 40vw;
    max-height: 55vh;
    overflow: scroll;
    .custom-payment {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      text-align: center;
      cursor: pointer;

      display: grid;
      grid-gap: 15px;
      padding: 70px 0px;

      border-radius: 8px;
      border: 1px solid colors.$blue-gray-100;
      background: colors.$white;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);

      &:hover {
        outline: 2px solid default-theme.$border;
        background: colors.$modifier-gradient-bg;
      }
    }
  }

  .action {
    margin-top: 20px;
    > .back-button {
      @include cta.link-cta;
      border: 1px solid colors.$blue-gray-500;
      width: 100%;
      border-radius: 4px;
      color: colors.$blue-gray-700;
      padding: 15px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
