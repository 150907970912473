@use "../common/colors";
@use "../common/elements";
@use '../common/scrollbar';

@mixin _button {
  width: 100%;
  padding: 14px 32px;
  border: solid 1px;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 14px;

  text-align: center;
}

.uber-eats-reject-reason-selector-widget {
  padding: 40px 32px 32px;
  display: grid;
  gap: 24px;

  max-width: 760px;
  width: 55vw;
  max-height: 90vh;
  overflow: auto;
  @include scrollbar.hide;

  color: colors.$blue-gray-900;

  .header {
    display: flex;
    justify-content: center;
    align-items: baseline;

    font-size: 20px;
    font-weight: 600;

    line-height: 24px;
    letter-spacing: 0.1px;

    > span {
      font-size: 18px;
      font-weight: 400;

      line-height: 27px;
      letter-spacing: 0.09px;

      color: colors.$blue-gray-700;

      margin-left: 6px;
    }
  }

  .reject-reasons {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-auto-flow: column;
    column-gap: 64px;
    row-gap: 16px;

    .reject-reason {
      display: grid;
      grid-template-columns: max-content 1fr;
      align-items: center;

      .checkbox {
        @include elements.icon($size: 24px);
      }

      .label {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0.09px;

        margin-left: 8px;
      }
    }
  }

  .additional-info {
    display: grid;
    gap: 4px;

    .form-item {
      .meta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 0;

        .help {
          margin-left: auto;
        }
      }
    }

    input {
      border-radius: 8px;
      border-color: colors.$blue-gray-100;

      padding: 20px 12px;

      &::placeholder {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.08px;

        color: colors.$blue-gray-400;
      }
    }

    .character-count {
      text-align: end;
      color: colors.$blue-gray-500;
    }
  }

  > .actions {
    padding: 0 24px;

    display: grid;
    grid-auto-flow: column;
    gap: 16px;
    height: min-content;

    .button {
      @include _button;
    }

    .cancel {
      border-color: colors.$blue-gray-500;
      background-color: transparent;
      color: colors.$blue-gray-700;
    }

    .reject-order {
      border-color: colors.$rose-800;
      background-color: colors.$rose-800;
      color: colors.$white;

      &.disabled {
        border-color: colors.$blue-gray-100;
        background-color: colors.$blue-gray-100;
        color: colors.$blue-gray-500;
      }
    }
  }
}
