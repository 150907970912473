@use "../common/colors";
@use "../common/elements";
@use "../common/payment-summary";

@mixin transaction {
  display: grid;
  grid-template-areas:
    'transaction-label     amount           '
    'transaction-reference .                '
    'surcharge-label       surcharge-amount '
    'tip-label             tip-amount       '
    'items                 items            ';
  color: colors.$blue-gray-900;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: center;
  gap: 0 8px;
  padding-top: 12px;
  margin-top: 12px;
  border-top: 1px solid colors.$blue-gray-100;

  &:first-child {
    border-top: 0;
    padding-top: 0;
  }

  > .transaction-label {
    grid-area: transaction-label;
    display: flex;
    justify-self: start;
    align-items: center;

    color: colors.$blue-gray-900;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    > .bullet {
      margin: 0 8px;
      color: colors.$blue-gray-700;
    }
  }

  > .amount {
    grid-area: amount;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    &.refund {
      color: colors.$rose-800;
    }
  }

  > .transaction-reference {
    grid-area: transaction-reference;
    justify-self: start;
    color: colors.$blue-gray-500;
  }

  > .surcharge {
    display: contents;
    color: colors.$blue-gray-500;

    > .label {
      grid-area: surcharge-label;
      justify-self: start;
    }

    > .amount {
      grid-area: surcharge-amount;
      justify-self: end;
    }
  }

  > .tip {
    display: contents;
    color: colors.$blue-gray-500;

    > .label {
      grid-area: tip-label;
      justify-self: start;
    }

    > .amount {
      grid-area: tip-amount;
      justify-self: end;
    }
  }
}

.anz-worldline-payment-summary-widget {
  > .header {
    display: flex;
    align-items: center;
    cursor: pointer;

    > .label {
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      color: colors.$blue-gray-900;
    }

    > .icon {
      margin-left: 5px;
      @include elements.icon;
    }
  }

  > .payment-summary {
    > .payment {
      @include transaction;
    }
  }

  > .refund-summary {
    > .payment {
      @include transaction;
    }

    > .refund {
      @include transaction;

      > .amount {
        color: colors.$rose-800;

        &::before {
          content: '-';
        }
      }
    }
  }
}
