@use '../common/card'as card;
@use '../common/colors'as colors;
@use '../common/constants'as constants;

.form-color-picker .form-input {
  @include card.container;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
  min-width: 251px;
  max-width: 283px;
  height: 100%;

  .header {
    @include card.header;
    display: flex;
    min-height: 80px;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    border-bottom: 1px solid #f1f1f5;
    border-top-left-radius: constants.$border-radius;
    border-top-right-radius: constants.$border-radius;
    color: #{colors.$blue-gray-900};

    > .icon {
      img {
        width: 40px;
        padding-top: 12px;
        margin-bottom: 18px;
      }
    }

    > .label {
      @include card.header-font;
    }

    > .sub-text {
      margin-top: 15px;
    }
  }

  .picker {
    @include card.content;

    .color-palette {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      place-items: center;
      gap: 10px;
      margin-bottom: 10px;

      .color {
        position: relative;
        display: inline-block;
        aspect-ratio: 1 / 1;
        height: 35px;
        border: solid 1px;
        border-radius: 4px;
        cursor: pointer;

        &.selected {
          border: solid 2px #000000 !important;
          background-image: url('../images/icon-check-black.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}
