@use '../common//colors';
@use '../common/elements';

.anz-worldline-request-progress-widget {
  display: grid;
  gap: 16px;
  justify-items: center;

  max-width: 573px;
  width: 40vw;

  padding: 32px 24px;

  .icon {
    @include elements.icon(64px);
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: colors.$blue-gray-900;
  }

  .subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: colors.$blue-gray-700;
    text-align: center;

    span {
      color: colors.$teal-600;
    }
  }

  button {
    width: 100%;
    background-color: colors.$teal-600;
    border-radius: 4px;
    color: colors.$white;
  }
}