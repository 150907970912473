@use '../common/order-view';
@use '../common/colors';
@use '../common/pill';

.uber-eats.order-view-widget {
  @include order-view.container;
  @include order-view.content;

  .ready-for-pickup-label {
    @include pill.secondary-blue(large);
    grid-area: requested-for;
    align-self: flex-start;
    margin-left: auto;
  }

  .uber-eats-order-view-actions-widget {
    display: grid;
    gap: 16px;
    margin-top: auto;
    padding-top: 24px;

    .button {
      width: 100%;
      padding: 14px 32px;
      border: solid 1px;
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      &.ready-for-pickup {
        background-color: transparent;
        color: colors.$blue-800;
        border-color: colors.$blue-400;
      }

      &.accept-order,
      &.make-payment {
        border-color: colors.$teal-600;
        background-color: colors.$teal-600;
        color: colors.$white;
      }
      &.print-order {
        background-color: transparent;
        color: colors.$blue-gray-500;
      }
      &.reject-order {
        background-color: transparent;
        color: colors.$rose-800;
        text-decoration: underline;
        border: none;
      }
      &.manage-order {
        background-color: colors.$blue-gray-100;
        color: colors.$blue-gray-900;
        border: none;
      }
    }
  }
}
