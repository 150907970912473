@use '../common/order-history-list-item.scss';
@use '../common/colors';
@use '../common/elements';
@use '../common/ghost';

.website.order-history-list-item-widget {
  .order-details-info {
    @include order-history-list-item.container;
    @include order-history-list-item.content;
  }

  .orders-list-item-order-status {
    &.processing-payment,
    &.to-be-confirmed {
      @include ghost.yellow;
    }

    &.accepted,
    &.completed {
      @include ghost.green;
    }

    &.failure,
    &.cancelled,
    &.rejected {
      @include ghost.red;
    }
  }
}
