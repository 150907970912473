@use '../common/colors' as colors;
@use "../common/cta" as cta;

.table-booking-edit-form-widget {
  display: grid;


  &.extend {
    padding-bottom: 50vh;
  }

  .items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px 16px;
    grid-template-areas:
      'customer-number customer-name'
      'customer-email date-time'
      'pax-seating-area pax-seating-area'
      'special-request special-request';

    .customer-number {
      grid-area: customer-number;
    }

    .customer-name {
      grid-area: customer-name;
    }

    .customer-email {
      grid-area: customer-email;
    }

    .date-time {
      grid-area: date-time;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
    }

    .pax-seating-area {
      grid-area: pax-seating-area;
      display: grid;
      grid-template-columns: 141px 1fr;
      column-gap: 16px;

      .seating-areas {
        position: relative;
        .seating-area-options {
          overflow: auto;
          max-height: 230%;
          border-radius: 8px;
          position: absolute;
          top: 105%;
          left: 0;
          width: 100%;
          float: right;
          background-color: colors.$white;
          border: solid 1px colors.$blue-gray-100;
          border-radius: 4px;
          z-index: 1;
          > .option {
            cursor: pointer;
            padding: 16px;
            border-bottom: 1px solid colors.$blue-gray-100;
            display: flex;
            align-items: center;

            &:last-child {
              border-bottom: 0;
            }

            &.selected {
              background-color: colors.$light-gray-100;
              font-weight: 500;
            }
          }
        }
      }
    }

    .special-request {
      grid-area: special-request;

      .notes-character-limit {
        float: right;
        color: #{colors.$blue-gray-400};
        margin-top: 8px;
      }
    }
  }

  .form-items:has(.invalid) {
    > div {
      padding: 12px 0px 0px 0px;
    }
    > .actions {
      padding: unset;
    }
  }

  .form-items {
    margin: 0px 26px 0px 26px;
    column-gap: 0px;
    row-gap: 0px;

    .meta {
      margin-top: 5px;
      margin-bottom: 4px;
      position: unset !important;
    }

    .form-select-pax {
      padding: 12px;
    }

    .form-select-icon input {
      background-image: url('../images/icon-chevron-down-grey.svg');
      background-repeat: no-repeat;
      background-position: right 20px top 10px;
      background-position-y: 60%;
      background-size: 20px !important;
    }

    .form-item {
      position: relative;

      label {
        padding: 5px 0px 10px 0px;
        position: absolute;
        top: 5px;
        left: 6px;
        font-size: 15px;
        line-height: 30px;
        color: #{colors.$blue-gray-500};
      }

      .meta {
        position: absolute;
        top: 78px;
        .error {
          font-size: 14px;
          color: #{colors.$rose-500};
        }
      }

      > .form-input > div > input,
      textarea {
        border-radius: 8px;
        padding: 40px 10px 15px 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5em;
        letter-spacing: 0.005em;
        color: colors.$blue-gray-900;
        &.invalid {
          border: 1px solid colors.$rose-500 !important;
        }
      }

      ::placeholder {
        color: colors.$blue-gray-600;
      }
    }

    .actions {
      margin-top: 16px;
      margin-bottom: 16px;
      padding: unset;
      display: flex;

      .label {
        padding: 13px 33px;
      }

      .cancel-button {
        max-width: 120px;
        border: 1px solid colors.$blue-gray-500;
        border-radius: 4px;
        background-color: colors.$white;
        color: colors.$blue-gray-500;
        margin-left: auto;
        margin-right: 16px;
        padding: unset;
        max-height: 50px;
      }

      .save-button {
        max-width: 102px;
        background-color: colors.$teal-600;
        color: colors.$white;
        padding: unset;
        border-radius: 4px;
        max-height: 50px;
      }

      .disabled-save-button {
        max-width: 102px;
        background-color: colors.$teal-300;
        padding: unset;
        border-radius: 4px;
        max-height: 50px;
      }
    }
  }
}

.table-booking-edit-form-confirmation {
  .swal2-html-container {
    max-width: 210px;
  }
}

.table-booking-iso-date-picker {
  padding: 20px;
}

.table-booking-time-of-day-picker {
  padding: 20px;
}
