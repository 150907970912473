@use '../common/colors';
@use '../common/typography';
@use '../common/elements';
@use '../common/pill';
@use '../common/ghost';

// 'CUSTOMER' 'COLLECTION TYPE' 'TOTAL' 'ORDER STATUS' 'PAYMENT STATUS' 'DOCKET #' 'TIME' 'ACTION'
$grid-template-columns: 1.25fr 1.25fr 0.5fr 1.25fr 1fr 0.5fr 1fr 0.35fr;

@mixin item {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: $grid-template-columns;
  align-items: center;
  gap: 12px;

  padding: 26px 24px;

  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  white-space: nowrap;
  color: colors.$blue-gray-900;

  cursor: pointer;

  > .customer-info {
    min-width: 0;

    > .name {
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
    }

    > .mobile {
      color: colors.$blue-gray-500;
    }
  }

  > .collection-info {
    > .type {
      display: flex;
      font-weight: 500;

      > .uber-icon {
        margin-left: 8px;
        @include elements.icon(24px);
      }
    }

    > .requested-for {
      color: colors.$blue-gray-500;
      font-size: 12px;
      line-height: 18px;
    }
  }

  > .order-status {
    &.new {
      @include ghost.yellow;
    }

    &.accepted,
    &.completed,
    &.success {
      @include ghost.green;
    }

    &.cancelled,
    &.rejected,
    &.failure {
      @include ghost.red;
    }
  }

  > .payment-status {
    display: flex;
    align-items: center;

    > .paid {
      @include pill.primary-green;
    }

    > .not-paid {
      @include pill.primary-red;
    }

    > .refunded {
      @include pill.primary-yellow;
    }

    > .bullet {
      margin: 0 4px;
    }

    > .double-charge-order-warning-icon {
      margin-left: 5px;
    }
  }

  > .actions {
    > .print-order-button {
      @include elements.icon(22px);
    }
  }
}
