@use '../common/default-theme' as default-theme;
@use '../common/typography' as typography;
@use "../common/cta" as cta;
@use '../common/buttons' as buttons;
@use '../common/colors' as colors;
@use "../common/scrollbar";

@mixin check {
  background-image: url('../images/icon-check.svg');
  background-repeat: no-repeat;
  background-size: 30px;
  height: 30px;
  width: 30px;
  position: absolute;
}

.pos-surcharge-presets {
  width: 500px;
  margin: 60px 160px;
  display: grid;

  .title {
    @include typography.h1;
    font-weight: bold;
    text-align: center;
    margin-bottom: 38px;
  }

  .sub-title {
    @include typography.h5;
    margin: 0 0 5px 5px;
  }

  .surcharges {
    max-height: 50vh;
    overflow-y: auto;
    padding: 10px 5px 0 5px;
    @include scrollbar.hide;

    .public-holiday-surcharge {
      border-radius: 6px;
      padding: 16px 24px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
      position: relative;
      cursor: pointer;
  
      &.selected {
        background-color: colors.$blue-gray-900;
        position: relative;
  
        .info {
          color: colors.$blue-300;
        }
  
        .value {
          .amount, .label {
            color: colors.$white;
          }
        }
  
        .check {
          @include check();
          right: -2px;
          top: -10px;
        }
      }
  
      .info {
        display: flex;
        align-items: center;
        font-size: 10px;
        color: colors.$blue-600;
  
        .icon {
          width: 17px;
          height: 14px;
          margin-right: 4px;
        }
  
        .label {
          line-height: 15px;
        }
      }
  
      .value {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-top: 8px;
        
        .amount {
          margin-right: 12px;
          color: colors.$teal-500;
          font-size: 14px;
          line-height: 21px;
        }
  
        .label {
          font-weight: 600;
          line-height: 21px;
          color: colors.$blue-gray-800;
        }
      }
    }
    
    .list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
      grid-gap: 12px;
      margin-bottom: 24px;
     
      .add-custom-surcharge {
        font-size: 30px;
        align-items: center;
      }
  
      .item {
        display: grid;
        grid-gap: 8px;
        background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%);
        box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
        border-radius: 6px;
        padding: 14px;
        height: 100px;
        cursor: pointer;
        text-align: center;
  
        .name {
          font-size: 15px;
          font-weight: 600;
          line-height: 20px;
          color: #{default-theme.$restoplus-gray-dark};
        }
  
        .value {
          font-size: 14px;
          line-height: 19px;
          color: #{default-theme.$primary};
        }
      }
  
      .item.selected {
        color: #fff;
        background: #1c1d3e;
        position: relative;
  
        .check {
          @include check();
          left: 130px;
          top: -10px;
        }
  
        .name {
          color: #fff;
        }
  
        .value {
          color: #fff;
        }
      }
    }

    .divider {
      height: 1px;
      background-color: colors.$blue-gray-100;
      margin: 20px 0;
    }
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 16px;
    padding-top: 15px;

    &.has-surcharge {
      grid-template-columns: 1fr 1fr;
    }

    button {
      padding: 14px 32px;
      border-radius: 4px;
    }

    > .clear-button {
      @include buttons.primary-transparent;
      border-color: colors.$teal-300;
      color: colors.$teal-600;
    }
  
    > .cancel-button {
      @include buttons.secondary-transparent;
      border-color: colors.$blue-gray-500;
      color: colors.$blue-gray-700;
      grid-column-start: 1;
      grid-row-start: 1;
    }
  }
 
}

.pos-surcharge-user-defined {
  width: 430px;
  margin: 60px 160px;

  .title {
    @include typography.h1;
    font-weight: bold;
    text-align: center;
  }

  /**** USER INPUT ****/
  .form {
    margin-bottom: 24px;
    // --- TOGGLE --- //
    .toggle {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-radius: 7px;
      margin: 24px 0;
      box-sizing: border-box;
      background: #{default-theme.$secondary-bg};
      .knob {
        @include typography.h3;
        font-weight: 400;
        cursor: pointer;
        display: grid;
        align-items: center;
        padding: 10px;
        text-align: center;
        color: #{default-theme.$fg};
        &.selected {
          border-radius: 7px;
          background: #{default-theme.$fg};
          color: #{default-theme.$bg};
        }
      }
    }
    // --- SURCHARGE INPUT BOX --- //
    .surcharge-input-box {
      border-radius: 8px;
      border: 1px solid #{default-theme.$form-element-border};
      display: grid;
      padding: 12.75px 20px;
      margin-bottom: 10px;
      grid-template-columns: min-content 1fr;
      .surcharge-type-icon {
        @include typography.h1;
        padding-top: 10px;
      }
      .surcharge-name,
      .surcharge-value {
        display: grid;
        align-items: center;
        input {
          outline: none;
          border: none;
          text-align: right;
          font-size: 25px;
          overflow: hidden;
          width: 100%;
        }
      }
    }
  }

  /**** CUSTOM SURCHARGE ACTIONS ****/
  .actions {
    button {
      border-radius: 4px;
    }
  }

  .add-surcharge.actions {
    display: grid;
    grid-template-areas:
      'confirm'
      'back'
      'cancel';
    grid-gap: 10px;
  }

  .edit-surcharge.actions {
    display: grid;
    grid-template-areas:
      'clear confirm'
      'back back'
      'cancel cancel';
    grid-gap: 10px;
  }

  .confirm-button {
    grid-area: confirm;
    @include cta.primary-cta;
    font-size: 17px;
  }

  .back-to-presets-button {
    grid-area: back;
    @include buttons.secondary-transparent;
    border-color: colors.$blue-gray-500;
    color: colors.$blue-gray-700;
    font-size: 17px;
    padding: 14px 0px;
  }

  .clear-button {
    grid-area: clear;
    @include buttons.primary-transparent;
    font-size: 17px;
    border-color: colors.$teal-300;
    color: colors.$teal-600;
  }

  .cancel-button {
    grid-area: cancel;
    text-decoration: underline;
    @include cta.link-cta;
  }

  /* Hide number-type input spin box */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.swal2-container {
  .remove-surcharge-dialog {
    .swal2-header {
      .swal2-title {
        margin-top: 0 !important;
      }
    }

    .swal2-content {
      .swal2-html-container {
        color: colors.$blue-gray-700;
        .public-holiday-surcharge-text {
          font-weight: 600;
        }
      }
    }

    .swal2-actions {
      .confirm-button {
        background: #{colors.$rose-800} !important;
        border: 1px solid #{colors.$rose-800} !important;
        border-radius: 4px !important;
        text-transform: inherit !important;
      }
      .cancel-button {
        border: 1px solid #{colors.$blue-gray-500} !important;
        border-radius: 4px !important;
        text-transform: inherit !important;
      }
    }
  }
}