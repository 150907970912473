@use "../common/colors";
@use "../common/elements";
@use "../common/payment-summary";

.split-bill-payment-summary-widget {
  margin-top: 16px;

  > .header {
    display: flex;
    align-items: center;
    cursor: pointer;

    > .label {
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      color: colors.$blue-gray-900;
    }

    > .icon {
      margin-left: 5px;
      @include elements.icon;
    }

    > .split-type {
      margin-left: auto;
      font-weight: 700;
      text-transform: capitalize;
    }
  }

  > .split-payments {
    margin-top: 12px;

    > .split-payment {
      @include payment-summary.payment;

      > .split-bill-items {
        @include payment-summary.split-bill-items;
      }
    }
  }
}
