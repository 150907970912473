@use "../common/colors";
@use "../common/elements";
@use "../common/scrollbar";
@use "../common/cta";

@mixin payment {
  display: grid;
  grid-template-areas:
    'label                 amount           '
    'transaction-reference .                '
    'surcharge-label       surcharge-amount '
    'tip-label             tip-amount       '
    'items                 items            ';
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: center;
  gap: 0 8px;
  padding-top: 12px;
  margin-top: 12px;
  border-top: 1px solid colors.$blue-gray-100;

  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: colors.$blue-gray-500;

  &:first-child {
    padding-top: 0;
    margin-top: 0;
    border-top: 0;
  }

  > .payment {
    display: contents;
    color: colors.$blue-gray-900;

    > .label {
      grid-area: label;
      display: flex;
      justify-self: start;
      align-items: center;

      > .type {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      > .bullet {
        margin: 0 8px;
        color: colors.$blue-gray-700;
      }
    }

    > .amount {
      grid-area: amount;

      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      &.refund {
        color: colors.$rose-800;
      }
    }
  }

  > .transaction-reference {
    grid-area: transaction-reference;
    justify-self: start;
    color: colors.$blue-gray-500;
  }

  > .surcharge {
    display: contents;
    color: colors.$blue-gray-500;

    > .label {
      grid-area: surcharge-label;
      justify-self: start;
    }

    > .amount {
      grid-area: surcharge-amount;
      justify-self: end;
    }
  }

  > .tip {
    display: contents;
    color: colors.$blue-gray-500;

    > .label {
      grid-area: tip-label;
      justify-self: start;
    }

    > .amount {
      grid-area: tip-amount;
      justify-self: end;
    }
  }
}

@mixin base {
  width: 65vw;
  max-width: 900px;
  padding: 40px 32px 32px;

  > * {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  > .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .icon {
      @include elements.icon(64px);
    }

    > .label {
      margin-top: 24px;

      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
    }
  }

  > .actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'cancel-button        finalise-button'
      'back-to-cart-button  finalise-button'
      'cancel-order-button  cancel-order-button';

    > .cancel-button {
      grid-area: cancel-button;
      @include cta.link-cta;
      border-radius: 4px;
      border: 1px solid colors.$blue-gray-500;
      margin-right: 15px;
    }

    > .back-to-cart-button {
      grid-area: back-to-cart-button;
      @include cta.link-cta;
      border-radius: 4px;
      border: 1px solid colors.$blue-gray-500;
      margin-right: 15px;
    }

    > .cancel-order-button {
      grid-area: cancel-order-button;
      text-decoration: underline;
      color: colors.$rose-800;
      font-weight: 500px;
      font-size: 16px;
      text-align: center;
      margin-top: 30px;
      cursor: pointer;
    }

    > .finalise-button {
      grid-area: finalise-button;
      @include cta.primary-cta;
      border-radius: 4px;
    }
  }
}

@mixin items {
  padding: 16px;
  margin-top: 12px;
  background: colors.$blue-gray-50;
  border-radius: 8px;

  overflow-y: scroll;
  @include scrollbar.hide;
  max-height: calc(60vh - 200px);

  > * {
    padding-top: 12px;
    margin-top: 12px;
    border-top: 1px solid colors.$blue-gray-100;

    &:first-child {
      padding-top: 0;
      margin-top: 0;
      border-top: 0;
    }
  }
}

@mixin split-bill-items {
  grid-area: items;
  width: 100%;
  margin-top: 8px;
  padding: 0 0 12px 32px;

  > .item {
    display: list-item;
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }
  }
}
