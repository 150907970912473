@use '../common/colors';

.orders-list-item-store-name {
  margin-bottom: 4px;
  display: flex;
  border: 1px solid colors.$blue-gray-100;
  border-radius: 4px;
  padding: 2px 12px;
  color: colors.$blue-gray-700;
  font-size: 12px;
  font-weight: 600;
  max-width: max-content;

  .store-icon {
    margin-right: 8px;
  }
}
