@use "../common/elements";
@use "../common/colors";

.popout-table-map-add-tables-widget {
  margin-top: -8px;
  padding: 0;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 20px 24px;

    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
    }

    .close-button {
      @include elements.icon(24px);
    }
  }

  .content {
    .table {
      display: grid;
      grid-template-columns: max-content 1fr;
      align-items: center;
      gap: 20px;

      padding: 16px 24px;
      border-bottom: 1px solid colors.$blue-gray-100;

      font-size: 14px;
      font-weight: 400;
      line-height: 21px;

      .icon {
        height: 36px;
      }

      &:first-child {
        border-top: 1px solid colors.$blue-gray-100;
      }

      &.isDragging {
        background-color: colors.$light-gray-200;
        cursor: grab;
      }
    }

    .rectangle {
      .icon {
        height: 32px;
      }
    }
  }

  .info {
    padding: 16px 24px;
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    gap: 4px;

    .icon {
      @include elements.icon();
    }

    .label {
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      color: colors.$blue-gray-500;
    }
  }
}
