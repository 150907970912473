@use '../common/colors';

.popout-zoom-options-widget {
  margin-top: -24px;
  padding: 4px 0;

  display: grid;
  grid-template-columns: 110px;
  grid-auto-flow: row;

  .zoom-option {
    padding: 12px 16px;

    &.fit {
      border-bottom: 1px solid colors.$blue-gray-100;
    }
  }
}
