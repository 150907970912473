.ordering-page {
  height: inherit;

  > .header {
    margin: 0 !important;
    width: 0;
  }

  > .body {
    height: inherit;
  }
}
