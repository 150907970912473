@use '../common/order-view';
@use '../common/colors';

.reverse-last-transaction,
.cancel-uber-direct-order-prompt {
  > .swal2-title {
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 26px !important;
    margin-top: 0 !important;
  }

  > .swal2-content {
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 21px !important;
  }

  > .swal2-actions {
    padding-top: 20px !important;

    > button {
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      text-transform: none !important;
    }

    > .confirm-button {
      background-color: colors.$rose-800 !important;
      border-radius: 5px !important;
    }

    > .cancel-button {
      color: colors.$blue-gray-500 !important;
      border: 1px solid colors.$blue-gray-500;
    }
  }
}

@mixin _button {
  width: 100%;
  padding: 14px 32px;
  border: solid 1px;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 14px;
}

.pos.order-view-widget {
  @include order-view.container;
  @include order-view.content;

  .pos-order-view-actions-widget {
    display: grid;
    margin-top: auto;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;

    &.refundable:not(.cancelled) {
      > .print-order-button {
        grid-column: 1 / 2;
      }

      > .refund-order-button {
        grid-column: 2 / 2;
      }
    }

    &.cancelled {
      > .refund-order-button {
        grid-column-start: 1;
        grid-row-start: 1;
      }
    }

    > * {
      grid-column: 1 / -1;
      @include _button;
      margin-top: 16px;
    }

    > .accept-order-button {
      border-color: colors.$teal-600;
      background-color: colors.$teal-600;

      .label {
        color: colors.$white;
      }
    }

    > .make-payment-button {
      border-color: colors.$teal-600;
      background-color: colors.$teal-600;

      > .label {
        color: colors.$white;
      }
    }

    > .edit-order-button {
      border-color: colors.$blue-gray-100;
      background-color: colors.$blue-gray-100;

      > .label {
        color: colors.$blue-gray-900;
      }
    }

    > .print-order-button {
      background-color: transparent;
      border-color: colors.$blue-gray-500;

      > .label {
        color: colors.$blue-gray-700;
      }
    }

    > .refund-order-button {
      background-color: transparent;
      border-color: colors.$blue-gray-500;

      > .label {
        color: colors.$blue-gray-500;
      }
    }

    > .reject-order-button {
      background-color: transparent;
      border-color: transparent;

      > .label {
        color: colors.$rose-800;
        text-decoration: underline;
      };
    }

    > .cancel-order-button {
      background-color: transparent;
      border-color: transparent;

      > .label {
        color: colors.$rose-800;
        text-decoration: underline;
      };
    }

    > .reverse-transaction-button {
      border-color: colors.$blue-gray-100;
      background-color: colors.$blue-gray-100;

      > .label {
        color: colors.$blue-gray-900;
      }
    }
  }

  &.loading {
    display: block;
  }
}
