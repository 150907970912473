@use "../common/cta";
@use "../common/typography";
@use "../common/colors";

.table-taken-warning-widget {
  display: grid;
  justify-items: center;
  width: 357px;
  padding: 24px;
  text-align: center;

  .title {
    @include typography.h1;
    margin-bottom: 12px;
  }

  .subtitle {
    @include typography.body-2-400;
    color: colors.$blue-gray-700;
    margin-bottom: 24px;
  }

  .actions {
    display: grid;
    gap: 16px;
    width: 100%;

    .merge-orders-button {
      @include cta.primary-cta;
      @include typography.body-2-400;
    }

    .choose-table-button {
      @include typography.body-2-400;
      border-color: colors.$blue-gray-100;
      background-color: colors.$blue-gray-100;
      color: colors.$blue-gray-900;
      padding: 15px;
      border-radius: 5px;
    }

    .back-to-cart-button {
      @include cta.link-cta;
      @include typography.body-2-400;
    }
  }
}