@use '../common/colors';

.third-party-completed-deliveries-widget {
  display: grid;
  grid-auto-rows: min-content 1fr;
  overflow: hidden;
  padding: 0 24px;

  > .labels {
    display: grid;
    grid-template-columns: 3fr repeat(3, 1fr) 1.5fr;
    gap: 12px;

    padding: 12px 24px;
    margin-bottom: 10px;

    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: colors.$blue-gray-500;
  }
}
