@use "../common/default-theme" as default-theme;
@use "../common/colors" as colors;
@use "../common/typography"as typography;

.tag-along-print-stations-widget,
.connected-store-tag-along-print-stations-widget {
  .empty {
    margin: 40px auto;
    width: max-content;
    color: #{colors.$blue-gray-500};
  }

  .form-items {
    grid-row-gap: 0;

    .inline-title {
      background-color: #{colors.$blue-gray-50};

      label {
        @include typography.h2;
        margin-left: 2px;
        color: #{default-theme.$fg};
      }
    }
  }

  .items {
    margin-bottom: 10px;
    .item {
      padding: 10px 20px;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}
