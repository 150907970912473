@use "../common/colors";

.cart-widget {
  grid-area: cart-widget;
  display: flex;
  flex-direction: column;
  background-color: colors.$blue-gray-900;
  width: 26vw;
  min-width: 330px;
  height: 100vh;
  max-height: 100vh;
}

.swal2-container {
  .leave-cart-confirmation-dialog {
    .swal2-header {
      .swal2-title {
        margin-top: 0 !important;
      }
    }

    .swal2-content {
      .swal2-html-container {
        color: #{colors.$blue-gray-700};
      }
    }

    .swal2-actions {
      .confirm-button {
        background: #{colors.$rose-800} !important;
        border: 1px solid #{colors.$rose-800} !important;
        border-radius: 4px !important;
        text-transform: inherit !important;
      }
      .cancel-button {
        border: 1px solid #{colors.$blue-gray-500} !important;
        border-radius: 4px !important;
        text-transform: inherit !important;
      }
    }
  }
}
