@use "../common/elements";
@use "../common/colors";

.table-map-table-edit-widget {
  width: max(500px, 30vw);
  display: grid;

  .header {
    position: relative;
    padding: 32px 32px 24px;

    > .title {
      text-align: center;
      font-size: 24px;
      line-height: 29px;
      font-weight: 500;
    }

    > .close-button {
      position: absolute;
      right: 32px;
      top: 32px;
      @include elements.icon(28px);

      cursor: pointer;
    }

    border-bottom: 1px solid colors.$blue-gray-100;
  }

  .content {
    padding: 32px;

    display: grid;
    gap: 10px;

    .form-item {
      display: grid;
      gap: 8px;

      > label,
      > .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: colors.$blue-gray-500;
      }

      input {
        border: 1px solid colors.$blue-gray-100;
        border-radius: 8px;
        padding: 8px 12px;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.005em;
        color: colors.$blue-gray-900;

        width: 100%;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: colors.$blue-gray-600;
        }
      }

      .meta {
        text-align: right;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: colors.$blue-gray-500;
      }
    }

    .seating-capacity-input .form-input {
      display: grid;
      grid-template-columns: max-content 1fr max-content;
      grid-template-areas:
        'label    label       label'
        'icon1    input       icon2';
      gap: 8px;
      align-items: center;
      justify-content: center;

      .icon {
        @include elements.icon(24px);
        cursor: pointer;

        &.disabled {
          opacity: 0.35;
        }
      }

      .minus {
        grid-area: icon1;
      }

      .plus {
        grid-area: icon2;
      }

      > .label {
        grid-area: label;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: colors.$blue-gray-500;
      }

      input {
        text-align: center;
        grid-area: input;
      }

      // remove arrows from number input
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .meta {
        display: none;
      }
    }
  }

  .actions {
    padding: 24px 24px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: colors.$light-gray-50;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    cursor: pointer;

    .delete-button {
      padding: 9.5px 16px;

      color: colors.$rose-800;
      text-decoration: underline;

      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
    }

    .save-button {
      padding: 9.5px 24px;

      background-color: colors.$teal-600;
      color: colors.$white;

      font-size: 14px;
      line-height: 21px;
      font-weight: 500;

      border-radius: 4px;
    }
  }
}
