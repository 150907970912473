@use "./type-select-widget" as type-select;
@use '../common/colors';

.payment-type-select-widget {
  width: 80vw;
  max-width: 1100px;
  @include type-select.type-select-widget;

  .payment-types {
    @include type-select.types;
  }

  .payment-type {
    @include type-select.type;
  }
}

#toasts-container {
  .wrapper {
    .linkly-retry-limit-reached {
      .message {
        max-width: 700px !important;
      }
    }
  }
}

.cancel-linkly-transaction-prompt {
  > .swal2-title {
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 26px !important;
    margin-top: 0 !important;
  }

  > .swal2-content {
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 21px !important;
  }

  > .swal2-actions {
    padding-top: 20px !important;

    > button {
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      text-transform: none !important;
    }

    > .confirm-button {
      background-color: colors.$rose-800 !important;
      border-radius: 5px !important;
    }

    > .cancel-button {
      color: colors.$blue-gray-500 !important;
      border: 1px solid colors.$blue-gray-500;
    }
  }
}
