@use '../common/typography'as typography;

.item-colors-list-widget {
  .category {
    // Add top margin except the first .category
    &:not(:first-child) {
      margin-top: 24px;
    }

    > .title {
      @include typography.h2;
      font-size: 24px;
    }

    form {
      .form-items {
        grid-template-columns: repeat(auto-fill, minmax(251px, 1fr));
        gap: 14px;
      }
    }
  }

  > .no-items {
    @include typography.h3;
    text-align: center;
  }
}
