@use '../common/viewport';
@use '../common/colors';
@use '../common/scrollbar';
@use '../common/lozenge';
@use '../common/pill';
@use '../common/elements';
@use '../common/ghost';
@use '../common/typography';

@mixin container {
  display: flex;
  height: 90vh;
  width: 90vw;
  max-width: 1200px;
  color: colors.$blue-gray-900;
}

@mixin content {
  .order-view-main-panel-widget {
    flex: 1;
    overflow-y: auto;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    @include scrollbar.hide;

    > * {
      margin-top: 30px;
      &:first-child {
        margin-top: 0;
      }
    }

    .order-view-main-panel-header-widget {
      @include _main-panel-header();
    }

    .order-view-main-panel-body-widget {
      @include _main-panel-body();
    }
  }

  .order-view-info-panel-widget {
    @include scrollbar.hide;
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    background-color: colors.$blue-gray-50;
    border-radius: 0 24px 24px 0;
    overflow: hidden scroll;
    padding: 23px;

    > * {
      margin-top: 24px;
      &:first-child {
        margin-top: 0;
      }
    }

    @include _info-panel-content();
  }
}

@mixin _main-panel-header {
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  grid-template-areas:
    'collection-type    order-source     requested-for'
    'order-id           order-id         requested-for';
  align-items: center;

  .collection-type {
    grid-area: collection-type;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  .order-source {
    grid-area: order-source;
    @include lozenge.secondary-light-gray;
    margin-left: 10px;
  }

  .requested-for {
    grid-area: requested-for;
    align-self: flex-start;
    margin-left: auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    .schedule > .label {
      margin: auto;
    }

    .date-time.schedule > .value {
      @include pill.secondary-green(large);
      margin-left: 10px;
    }
    .asap.schedule > .value {
      @include pill.secondary-red(large);
      margin-left: 10px;
    }
    .eta.schedule > .value {
      @include pill.secondary-red(large);
      margin-left: 10px;
    }
  }

  .time-since-label-widget {
    margin-top: 4px;
    color: colors.$blue-gray-700;
    text-align: right;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .order-id {
    grid-area: order-id;
    margin-top: 8px;

    color: colors.$blue-gray-700;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.035px;

    span {
      text-transform: uppercase;
    }
  }
}

@mixin _main-panel-body {
  .order-view-item-list-widget {
    > .items {
      > .header {
        display: flex;
        align-items: center;

        > .icon {
          @include elements.icon(24px);
        }

        > .label {
          margin-left: 10px;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
        }

        > .missing-items {
          display: flex;
          justify-items: center;
          align-items: center;
          margin-left: 10px;
          color: colors.$yellow-600;

          &::before {
            content: '';
            display: block;
            margin-right: 6px;
            width: 4px;
            height: 4px;
            border-radius: 9999px;
            background-color: colors.$yellow-600;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }

      > * {
        padding-top: 12px;
        margin-top: 12px;
        border-top: 1px solid colors.$blue-gray-100;

        &:first-child {
          padding-top: 0;
          margin-top: 0;
          border-top: 0;
        }
      }
    }
  }

  .order-view-amount-list-widget {
    margin-top: 16px;
    border-top: 1px solid colors.$blue-gray-100;
    padding-top: 12px;
    color: colors.$blue-gray-700;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    .amount {
      display: flex;
      justify-content: space-between;
      margin-top: 2px;
    }

    .grand-total {
      border-top: 1px solid colors.$blue-gray-100;
      padding-top: 12px;
      margin: 12px 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      .value {
        color: colors.$teal-600;
      }
    }

    .gst-amount {
      font-weight: 500;
    }
  }

  .tyro-payment-summary-widget,
  .linkly-payment-summary-widget,
  .anz-worldline-payment-summary-widget {
    margin-top: 16px;
  }
}

@mixin _info-panel-content {
  .order-view-payment-info-widget {
    .header {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-areas: 'title close-button';
      border-bottom: 1px solid colors.$blue-gray-100;

      .title {
        grid-area: title;
        display: grid;
        grid-template-columns: repeat(2, max-content);
        grid-template-areas:
          'failed-status-tag   failed-status-tag'
          'currency-icon        title-text';

        .failed-status-tag {
          grid-area: failed-status-tag;
          @include ghost.red;
          margin-bottom: 8px;
        }
        .currency-icon {
          grid-area: currency-icon;
          width: 16px;
          height: 16px;
          background-color: colors.$blue-gray-500;
          color: colors.$white;
          border-radius: 16px;
          text-align: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          margin-top: 2px;
        }
        .text {
          grid-area: title-text;
          margin-left: 10px;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
      }
      .close-button {
        grid-area: close-button;
      }
    }

    .grand-total {
      margin-top: 8px;
      font-weight: 500;
      font-size: 31px;
      line-height: 38px;
    }

    .payment-info {
      display: flex;
      align-items: center;
      margin-top: 8px;
      .paid {
        @include pill.primary-green;
      }
      .not-paid {
        @include pill.primary-red;
      }
      .refunded {
        @include pill.primary-yellow;
      }
      .bullet {
        margin: 0 4px;
      }
    }

    .tax-invoice-number {
      margin-top: 16px;
      padding: 4px 12px;
      width: max-content;

      border: 1px solid colors.$light-gray-400;
      border-radius: 4px;
      color: colors.$blue-gray-700;

      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }
  }
  .info-container {
    overflow-y: scroll;

    .label {
      color: colors.$blue-gray-500;
    }

    @media (max-height: 752px) {
      scrollbar-width: thin;
      scrollbar-color: auto;

      &::-webkit-scrollbar {
        width: auto;
      }

      &::-webkit-scrollbar-track {
        background: auto;
      }

      &::-webkit-scrollbar-thumb {
        background-color: auto;
      }
    }
  }

  .order-view-table-info-widget {
    @include _info();

    .table-info {
      display: none;

      &.visible {
        display: block;
      }
    }

    .table-info > .key-value-pair {
      @include _info-content();
      display: flex;
    }
  }

  .order-view-customer-info-widget {
    @include _info();

    .customer-info {
      display: none;

      &.visible {
        display: block;
      }
    }

    .customer-info > .key-value-pair {
      @include _info-content();
      align-items: unset;

      &.uber-eats {
        .value {
          color: colors.$blue-gray-500;
        }
      }
    }

    .dropoff-notes {
      margin-top: 8px;
      display: block;
      background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%), #f6f8fb;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      padding: 10px;
      @include typography.h5;
      font-weight: 400;
      line-height: 21px;
      color: colors.$blue-gray-800;

      > div {
        display: inline;
        font-weight: 600;
      }
    }
  }

  .order-view-employee-info-widget {
    @include _info();

    .employee-info {
      display: none;

      &.visible {
        display: block;
      }
    }

    .employee-info > .key-value-pair {
      @include _info-content();
    }
  }

  .order-view-uber-courier-info-widget {
    @include _info;

    > *:not(.header) {
      display: none;
      grid-template-columns: 0.5fr 1fr;
      gap: 12px;
      margin-top: 4px;

      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      // element after `.header`
      &:nth-child(2) {
        margin-top: 10px;
      }

      &.visible {
        display: grid;
      }

      > .label {
        color: colors.$blue-gray-500;
      }

      > .value {
        word-break: break-word;
        color: colors.$blue-gray-900;
      }
    }

    > .header .icon {
      margin-top: 2px;
    }

    .courier-details {
      align-items: center;
    }

    .courier-image {
      width: 100%;
      border-radius: 50%;
    }

    .courier-name {
      color: colors.$blue-gray-900;
      font-size: 16px;
      font-weight: 500;
    }

    .courier-vehicle {
      color: colors.$blue-gray-700;
      font-size: 14px;
      font-weight: 400;
    }

    .courier-contact-number {
      text-decoration: none;
      color: colors.$blue-gray-700;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .order-view-driver-info-widget {
    @include _info();

    > .header {
      > .additional-info {
        align-self: center;
        margin-left: 8px;

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.048px;
        color: colors.$green-600;

        &.ARRIVED_AT_PICKUP {
          color: colors.$blue-500;
        }

        &.EN_ROUTE_TO_DROPOFF {
          color: colors.$yellow-600;
        }

        &.FAILED {
          color: colors.$rose-500;
        }
      }
    }

    > .header .icon {
      margin-top: 2px;
    }

    // For toggle hide/show content
    .driver-info {
      display: none;

      &.visible {
        display: flex;
      }
    }

    // For content styles
    .driver-info {
      align-items: center;
      margin-top: 12px;

      .icon {
        @include elements.icon(74px);

        img {
          border-radius: 50%;
          margin-right: 12px;
        }
      }

      .details {
        .driver-name {
          color: colors.$blue-gray-900;
          font-size: 16px;
          font-weight: 500;
        }

        .vehicle-info {
          color: colors.$blue-gray-700;
          font-size: 14px;
          font-weight: 400;
        }

        .driver-contact-number {
          text-decoration: none;
          color: colors.$blue-gray-700;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .delivery-status {
      display: none;

      &.visible {
        display: block;
      }

      > .key-value-pair {
        @include _info-content();

        .text {
          color: colors.$blue-gray-500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
        }

        > .value {
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
          letter-spacing: 0.25px;
          color: colors.$green-600;
        }

        &.ARRIVED_AT_PICKUP {
          > .value {
            color: colors.$blue-500;
          }
        }

        &.EN_ROUTE_TO_DROPOFF {
          > .value {
            color: colors.$yellow-600;
          }
        }

        &.FAILED {
          > .value {
            color: colors.$rose-500;
          }
        }
      }
    }
  }

  .order-view-rejection-info-widget {
    @include _info();

    .rejection-info {
      display: none;

      &.visible {
        display: block;
      }
    }

    .rejection-info > .key-value-pair {
      @include _info-content();
      align-items: unset;
    }
  }

  .order-view-store-info-widget {
    @include _info();

    .store-info {
      display: none;

      &.visible {
        display: block;
      }
    }

    .store-info > .key-value-pair {
      @include _info-content();
      align-items: flex-start;
    }
  }
}

// For container styles + accordion header styles
@mixin _info {
  background-color: colors.$white;
  padding: 10px 12px;
  border-left: 1px solid colors.$blue-gray-100;
  border-right: 1px solid colors.$blue-gray-100;
  border-bottom: 1px solid colors.$blue-gray-100;

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-top: 1px solid colors.$blue-gray-100;
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  > .header {
    display: flex;

    > .icon {
      @include elements.icon(20px);
    }

    > .title {
      margin-left: 8px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }

    > .chevron {
      @include elements.icon(24px);
      margin-left: auto;
    }
  }
}

// For each key-value pair in accordion body
@mixin _info-content {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: 12px;
  margin-top: 4px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  &:first-child {
    margin-top: 12px;
  }

  > .text {
    color: colors.$blue-gray-500;
  }

  > .value {
    word-break: break-word;
    color: colors.$blue-gray-900;
  }
}
