
@use '../common/colors';

.pagination-widget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  
  button {
    background-color: transparent;
    padding: 0px;

    > .label {
      display: flex;
      align-items: center;
      span {
        color: colors.$blue-gray-900;
        font-size: 16px;
        font-weight: 600;
      }
      img {
        width: 7.2px;
        height: 12px;
      }
    }

    &.disabled {
      color: colors.$blue-gray-600;
    }
  }

  .page-total-spinner-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    
    .message {
      color: colors.$blue-gray-700;
      font-size: 14px;
      padding-left: 8px;
    }
  }

  .page-label {
    color: colors.$blue-gray-700;
    font-size: 14px;
  }

  .previous-button {
    > .label {
      > span {
        padding-left: 16px;
      }
    }
  }

  .next-button {
    > .label {
      > span {
        padding-right: 16px;
      }
    }
  }
}