@use '../common/colors';

.third-party-deliveries-page {
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100vh;

  > .header {
    padding: 0 24px;
    margin-bottom: 24px;

    > .wrapper {
      border-bottom: none;
      grid-template-areas:
        'title actions'
        'subtitle empty';
      justify-content: start;
      align-items: baseline;
      column-gap: 24px;

      padding: 20px 24px;
      width: inherit;
    }

    .action-link {
      color: colors.$blue-gray-500;
      display: grid;
      grid-auto-flow: column;
      gap: 12px;
      align-items: center;

      cursor: pointer;
    }
  }

  > .body {
    overflow: hidden;
  }

  .disabled {
    margin: 30px;
  }

  &.delivery-complete {
    > .header {
      > .wrapper {
        grid-template-areas:
          'actions'
          'title'
          'subtitle';

        .action-link {
          margin-bottom: 13.5px;
        }
      }
    }
  }
}
