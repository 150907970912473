@use '../common/constants';
@use '../common/colors';

.uber-eats-order-list-item-widget {
  border-radius: constants.$border-radius;
  background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%);
  box-shadow: 0px 3px 10px -3px rgb(0 0 0 / 30%);
  padding: 13px 20px;
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  height: fit-content;

  .order-data {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 20% 15% 10% 20% 10% 20%;
    align-items: center;
    gap: 1%;
  }

  .orders-list-item-customer-info {
    padding: 0 10px;
    .customer-name {
      color: colors.$blue-gray-900;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .customer-phone-number {
      font-size: 14px;
      color: colors.$blue-gray-500;
    }
  }

  .orders-list-item-collection-info {
    .collection-type {
      span {
        color: colors.$blue-gray-500;
      }
    }

    .pickup-in-label {
      color: colors.$blue-800;
    }
  }

  .orders-list-item-grand-total,
  .orders-list-item-docket-number {
    text-align: left;
    margin: 0;
    font-size: 18px;
  }

  .orders-list-item-order-status {
    font-weight: 600;
    width: fit-content;
    padding: 5px 15px;
    border-radius: 30px;

    &.to-be-confirmed {
      color: colors.$yellow-600;
      background-color: colors.$yellow-100;
    }

    &.accepted,
    &.handed-off,
    &.delivered {
      color: colors.$green-800;
      background-color: colors.$green-100;
    }

    &.failed,
    &.cancelled,
    &.rejected,
    &.unknown {
      color: colors.$rose-800;
      background-color: colors.$rose-100;
    }
  }

  .actions {
    display: flex;
    gap: 20px;
    cursor: pointer;
  }

  .orders-list-item-print-order-button {
    justify-self: end;
    opacity: 0.8;
    padding: 0px 10px;
  }

  .edit-icon {
    margin-left: auto;
  }
}
