@use "../common/scrollbar";
@use "../common/colors";
@use "../common/cta";

.order-versions-payment-refund-widget {
  padding: 24px 32px 32px;
  width: 800px;

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.36px;

    margin: 12px 0 16px 0;
    text-align: center;
  }

  .description {
    font-size: 14px;
    margin: 16px 0 8px 0;
    .refund-amount {
      color: colors.$teal-600;
    }
  }

  .payments {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.08px;
    max-height: 50vh;
    overflow-y: auto;
    @include scrollbar.hide;

    .payment {

      &.disabled {
        color: colors.$blue-gray-500;
        cursor: not-allowed;
      }

      background: colors.$blue-gray-50;
      border-bottom: 1px solid colors.$blue-gray-100;
      padding: 16px 20px;

      display: grid;
      grid-template-columns: max-content 1fr max-content;
      grid-template-areas: 
        'radio    label                amount'
        '.        reference-number     reference-number'
        '.        tip                  tip'
        '.        surcharge            surcharge';
      
      > .radio-button {
        grid-area: radio;
        margin-right: 14px;
      }

      > .label {
        grid-area: label;
        display: flex; 
        align-items: center;
    
        .payment-order {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.08px;
        }
    
        .bullet {
          margin: 0 8px 2px;
        }
    
        .payment-type {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.035px;
        }
      }
    
      > .amount {
        grid-area: amount;
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.08px;
    
        &.refund {
          color: colors.$rose-800;
        }
      }
    
      .reference-number {
        grid-area: reference-number;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.035px;
        color: colors.$blue-gray-500;
      }
    
      .tip,
      .surcharge {
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.035px;
        color: colors.$blue-gray-500;
      }

      .tip {
        grid-area: tip;
      }

      .surcharge {
        grid-area: surcharge;
      }
    }

    :first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    :last-child {
      border-bottom: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .actions {
    margin: 24px 24px 0 24px;
    display: flex;

    button {
      width: 100%;

      &.back {
        @include cta.link-cta;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
        border-radius: 4px;

        margin-right: 10px;
      }

      &.refund {
        @include cta.primary-cta;
        border-radius: 4px;
      }
    }
  }
}