@use "../common/colors";
@use "../common/constants";
@use "../common/cta";

@mixin _button {
  width: 100%;
  padding: 14px 32px;
  border: solid 1px;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

@mixin request-transaction-widget {
  width: 570px;
  padding: 32px 48px;
  text-align: center;

  > img {
    padding-top: 16px;
    padding-bottom: 10px;
  }

  > .label {
    color: colors.$blue-gray-900;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
    font-weight: 900;
  }

  .sub-label {
    color: colors.$blue-gray-700;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
    .highlight {
      color: colors.$teal-600;
    }
    .business-days {
      text-decoration: underline;
    }
  }
  .session-id {
    color: colors.$blue-gray-500;
  }

  .use-other-payment-methods {
    cursor: pointer;
    text-decoration: underline;
  }

  .actions {
    display: grid;
    grid-auto-flow: column;
    margin-top: 30px;
    gap: 16px;
    grid-auto-columns: 1fr;

    .ok-button,
    .try-again-button,
    .go-to-linkly-cloud-button {
      @include _button;
      background: colors.$teal-600;
      border-color: colors.$teal-600;
      color: colors.$white;
    }

    .cancel-button {
      @include _button;
      background: none;
      border-color: colors.$blue-gray-700;
      color: colors.$blue-gray-700;
    }
  }

  .use-other-payment-methods {
    margin-top: 20px;
  }
}
