@use '../common/colors' as colors;
@use 'table-booking-activity-logs-list-item-widget' as activity-logs-list-item;

.table-booking-activity-logs-list-widget {
  margin: 0px 24px;

  .layout {
    display: grid;
    grid-template-columns: minmax(50px, 201px) minmax(50px, 128px) minmax(50px, 271px) minmax(50px, 539px);
    padding: 16px 24px;

    &.label {
      font-size: 11px;
      line-height: 24px;
      color: colors.$blue-gray-500;
      text-transform: uppercase;
      padding: 12px 24px;
    }
  }

  .table-booking-activity-logs {
    border: 1px solid colors.$blue-gray-100;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px #00000026;
    display: grid;
    margin-bottom: 50px;

    .cancelled {
      border-bottom: 1px solid colors.$blue-gray-100;
    }

    .information {
      border: 1px solid colors.$blue-gray-100;
      border-radius: 8px;
      box-shadow: 0px 0px 10px 0px #00000026;
      display: grid;
      margin-bottom: 50px;

      .field {
        border-bottom: 1px solid colors.$blue-gray-100;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
      }

      .date {
        font-family: 'Sailec-Medium';
      }
      .employee {
        text-transform: capitalize;
      }

      .new-value {
        font-family: 'Sailec-Medium';
      }
    }
  }
}
