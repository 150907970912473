@use "../common/default-theme" as default-theme;
@use "../common/constants" as constants;
@use "../common/typography" as typography;
@use "../common/colors" as colors;

.pos-customer-list-page {
  display: grid;

  > .header {
    input {
      @include typography.p;
      line-height: 20px;
      border: none;
      padding: 12px 15px;
      border-radius: constants.$border-radius;
      outline: none;
      border: 1px solid colors.$blue-gray-100;
      text-indent: 30px;
      background-image: url('../images/icon-search.svg');
      background-repeat: no-repeat;
      background-position: 15px center;
      width: 380px;
    }
  }

  > .body {
    grid-template-rows: min-content 1fr;

    .result-count {
      margin: 20px;
      margin-bottom: 0;
    }

    .list-header {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr 1fr 3fr 10px;
      gap: 15px;
      padding: 13px 20px;
      margin: 20px 30px;
      border-radius: constants.$border-radius constants.$border-radius;
      border: 1px solid #{default-theme.$border-light};
      border-bottom: none;
      font-weight: 600;
      background-color: #{default-theme.$fg};
      color: #{default-theme.$bg};
    }

    .empty-message {
      text-align: center;
      margin-top: 80px;
    }

    .no-results {
      text-align: center;
      margin-top: 80px;
    }

    .spinner-container {
      margin-top: 40px;
    }

    .ais-SearchBox {
      display: none;
    }

    .ais-Hits-list {
      margin: 20px 30px;
      list-style: none;

      .ais-Hits-item {
        border-radius: constants.$border-radius;
        background: #{default-theme.$modifier-gradient-bg};
        box-shadow: 0px 3px 10px -3px rgb(0 0 0 / 30%);
        padding: 10px 0;
        margin-bottom: 15px;
      }
    }

    .ais-Pagination-list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .ais-Pagination-list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .ais-Pagination {
      color: #3a4570;
    }

    .ais-Pagination-link {
      color: var(--color-primary);
      transition: color 0.2s ease-out;
      text-decoration: none;
    }

    .ais-Pagination-list {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .ais-Pagination-item + .ais-Pagination-item {
      margin-left: 0.3rem;
    }

    .ais-Pagination-link {
      padding: 0.3rem 0.6rem;
      display: block;
      border: 1px solid #c4c8d8;
      border-radius: 5px;
      transition: background-color 0.2s ease-out;
    }

    .ais-Pagination-item--disabled .ais-Pagination-link {
      opacity: 0.6;
      cursor: not-allowed;
    }

    .ais-Pagination-item--selected .ais-Pagination-link {
      color: #fff;
      background-color: #{default-theme.$primary};
      border-color: #{default-theme.$primary};
    }
  }
}
