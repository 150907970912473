@use '../common/colors';
@use '../common/typography';

%card-style {
  background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 24px;
}

.print-docket-settings-widget {
  padding: 20px;

  .form-item {
    @extend %card-style;

    label {
      @include typography.h1;
      margin-bottom: 8px;
    }

    .help {
      @include typography.p;
    }
  }

  .docket-prefix-section {
    @extend %card-style;

    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-template-areas:
    'label         prefix'
    'help          prefix';

    .label {
      grid-area: label;
      @include typography.h1;
      margin-bottom: 8px;
    }

    .help {
      grid-area: help;
      @include typography.p;
      color: #6c6c6c;
    }

    .prefix {
      @include typography.h1-headline;
      grid-area: prefix;
      align-content: center;
      justify-self: end;
      color: colors.$teal-700;
    }
  }

  .reset-docket-numeric-counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    padding: 25px;

    .label {
      color: #{colors.$blue-gray-900};
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
    }
  }
}
