@use '../common/order-history-list-item.scss';
@use '../common/colors';
@use '../common/elements';
@use '../common/ghost';

.google.order-history-list-item-widget {
  .order-details-info{
    @include order-history-list-item.container;
    @include order-history-list-item.content;
  }

  .orders-list-item-order-status {
    &.to-be-confirmed {
      @include ghost.yellow;
    }

    &.accepted,
    &.in-preparation,
    &.ready-for-pickup,
    &.in-transit,
    &.completed {
      @include ghost.green;
    }

    &.cancelled,
    &.rejected {
      @include ghost.red;
    }
  }
}
