@use "../common/default-theme" as default-theme;
@use "../common/colors" as colors;
@use "../common/cta"as cta;
@use '../common/typography' as typography;

.item-comments-widget {
  padding: 60px 160px 20px 160px;
  border-radius: 20px;
  min-width: 748px;
  z-index: 100;

  .title {
    margin: 0;
  }

  .item-info {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 23px;

    .name {
      color: #{default-theme.$gray};
      font-weight: 600;
      letter-spacing: 0.6px;
    }

    .price {
      color: #{default-theme.$primary};
      font-weight: 600;
      letter-spacing: 0.6px;
    }
  }

  .comments-form {
    width: 100%;

    > form textarea {
      border-radius: 8px;
      border: none;
      background-color: #{default-theme.$secondary-bg};
      font-size: 16px;
      padding-top: 14px;
    }
  }

  .extra-charge-input {
    margin-top: 16px;

    .label {
      margin-bottom: 6px;
      color: #{colors.$blue-gray-900};
    }

    .extra-charge-input-box {
      border-radius: 8px;
      background-color: #{default-theme.$secondary-bg};
      display: grid;
      padding: 12px;
      padding: 12.75px 20px;
      grid-template-columns: min-content 1fr;

      .extra-charge-icon {
        @include typography.h1;
        padding-top: 10px;
      }

      .extra-charge-value {
        display: grid;
        align-items: center;

        input {
          outline: none;
          border: none;
          background: none;
          text-align: right;
          font-size: 25px;
          font-weight: normal;
          font-style: normal;
          line-height: 35px;
          overflow: hidden;
          width: 100%;
        }
      }
    }
  }

  .actions {
    width: 100%;
    display: flex;
    flex-direction: column;

    .with-input {
      display: flex;
      button {
        &.clear {
          @include cta.primary-cta;
          background-color: #{default-theme.$bg};
          border: 2px solid #{default-theme.$primary};
          width: 100%;
          border-radius: 8px;
          margin-top: 16px;
          font-size: 16px;
          margin-right: 10px;
          span.label {
            color: #{default-theme.$primary};
          }
        }

        &.done {
          @include cta.primary-cta;
          width: 100%;
          border-radius: 8px;
          margin-top: 16px;
          font-size: 16px;
        }
      }
    }

    button {
      &.done {
        @include cta.primary-cta;
        border-radius: 8px;
        margin-top: 16px;
        font-size: 16px;
        width: 100%;
      }

      &.cancel {
        @include cta.link-cta;
        margin-top: 13px;
      }
    }
  }

  /* Hide number-type input spin box */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
