@use '../common/order-history-list-item.scss';
@use '../common/colors';
@use '../common/elements';
@use '../common/ghost';

.pos.order-history-list-item-widget {
  .order-details-info {
    @include order-history-list-item.container;
    @include order-history-list-item.content;
  }

  .orders-list-item-order-status {
    &.accepted,
    &.completed {
      @include ghost.green;
    }

    &.cancelled,
    &.rejected {
      @include ghost.red;
    }
  }
}
