@use "typography" as typography;
@use "colors" as colors;

@mixin primary-cta {
  @include typography.h4;
  text-align: center;
  background-color: #{colors.$teal-600};
  color: white !important;
  margin: 0;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: ease-in-out all 0.2s;
  font-size: 17px;
  line-height: 23px;

  &:focus {
    border: none;
    outline: none;
  }
}

@mixin secondary-cta {
  @include typography.h4;
  text-align: center;
  background-color: transparent;
  color: #{colors.$teal-500} !important;
  border: 1px solid #{colors.$teal-500};
  margin: 0;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: ease-in-out all 0.2s;
}

@mixin tertiary-cta {
  @include typography.h4;
  text-align: center;
  background-color: #{colors.$white};
  color: #{colors.$blue-gray-700};
  border: 1px solid #{colors.$blue-gray-500};
  margin: 0;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: ease-in-out all 0.2s;

  &:focus {
    border: none;
    outline: none;
  }
}

@mixin danger-cta {
  text-align: center;
  background-color: transparent;
  border: 2px solid #{colors.$rose-500};
  color: #{colors.$rose-500} !important;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  transition: ease-in-out all 0.2s;
  font-size: 17px;
  line-height: 23px;
}

@mixin link-cta {
  text-align: center;
  background-color: transparent;
  color: #{colors.$blue-gray-500};
  margin: 0;
  padding: 15px;
  cursor: pointer;
  transition: ease-in-out all 0.2s;
  font-size: 17px;
  line-height: 23px;
}

.cta {
  @include primary-cta;
}

.secondary-cta {
  @include secondary-cta;
}

.tertiary-cta {
  @include tertiary-cta;
}

.link-cta {
  @include link-cta;
}
