@use '../common/elements';
@use '../common/colors';
@use "../common/cta";
@use "./type-select-widget";

.troubleshooting-options-widget {
  @include type-select-widget.type-select-widget;
  padding: 40px 64px 32px;
  display: grid;
  gap: 32px;

  .header {
    display: grid;
    gap: 12px;

    .title {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
    }
  
    .subtitle {
      text-align: center;
      font-size: 18px;
      line-height: 27px;
      font-weight: 400;
      color: colors.$blue-gray-700;
      max-width: 60vw;
  
      span {
        color: colors.$teal-600;
      }
    }
  }

  .troubleshooting-options {
    @include type-select-widget.types;
    margin: 0 32px;

    .option {
      @include type-select-widget.type;

      .icon {
        @include elements.icon(48px);
      }
    }
  }

  .back-button {
    width: 100%;
    @include cta.link-cta;
    border-radius: 4px;
    color: colors.$blue-gray-700;
    border: 1px solid colors.$blue-gray-500;
  }
}
