.incoming-online-orders-page {
  > .header {
    .wrapper {
      padding: 25px 30px;
    }
  }

  .store-filter-widget {
    position: absolute;
    top: 15px;
    right: 30px;

    .form-input {
      width: 400px;
    }
  }
}
