@use '../common/colors';
@use '../common/typography';

.sales-report-store-multi-select-widget {
  width: 100%;
  position: relative;
  padding-bottom: 16px;

  > .form-item-container {
    &.close,
    &.open.empty {
      > .form-item > .form-input {
        padding: 12px 14px;
      }
    }

    > .form-meta {
      > .error {
        color: colors.$rose-800;
        padding-top: 4px;
        @include typography.caption-1-400;
      }
    }

    > .form-item {
      > .form-label {
        color: colors.$blue-gray-500;
        padding-bottom: 8px;
        @include typography.caption-2-400;
      }

      > .form-input {
        border: 1px solid colors.$blue-gray-100;
        border-radius: 8px;
        padding: 8px 14px;

        > .drop-down-input {
          display: flex;
          align-items: center;
          cursor: pointer;

          &.open:not(.empty) {
            margin: -6px;
          }

          &.close {
            flex-grow: 1;
            justify-content: space-between;
          }

          > .removable-tag-list {
            display: flex;
            flex-grow: 1;
            flex-wrap: wrap;

            > .removable-tag {
              background-color: colors.$light-gray-100;
              color: colors.$blue-gray-900;
              border-radius: 8px;
              margin: 6px;
              @include typography.body-2-400;
              cursor: default;
            }
          }

          > .input-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 95%;
            @include typography.body-2-400;
          }

          > .hidden-count {
            margin: 0 8px 0 24px;
          }

          > .down-arrow-icon {
            display: inline-flex;
          }
        }

        > .drop-down-list {
          position: absolute;
          width: 100%;
          border-radius: 8px;
          border: 1px solid colors.$blue-gray-100;
          background-color: colors.$white;
          margin-top: 13px;
          z-index: 2;
          left: 0;
          box-shadow: 0px 2px 8px 0px #00000014;

          > .options {
            max-height: 310px;
            overflow-y: scroll;

            > .option {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 12px;
              border-bottom: 1px solid colors.$blue-gray-100;
              @include typography.body-2-400;

              &.store-option {
                cursor: pointer;
              }

              &.select-all-option {
                color: colors.$blue-gray-700;
                @include typography.caption-1-400;

                > .select-all {
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  > .label {
                    padding-right: 8px;
                  }
                }
              }

              &:last-of-type {
                border-bottom: none;
              }
            }
          }

          > .actions {
            padding: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid colors.$blue-gray-100;
            @include typography.button-2;

            > .clear-selection-button {
              color: colors.$blue-800;
              text-decoration: underline;
              cursor: pointer;

              &.hide {
                visibility: hidden;
              }
            }

            .confirm-button {
              background-color: colors.$teal-600;
              color: colors.$white;
              border-radius: 4px;
              text-align: center;
              padding: 9.5px 24px;
              cursor: pointer;

              &.disabled {
                pointer-events: none;
                background-color: colors.$teal-300;
              }
            }
          }
        }
      }

      &.invalid {
        > .form-input {
          border: 1px solid colors.$rose-500;

          > .drop-down-list {
            margin-top: 42px;
          }
        }
      }
    }
  }
}
