@use "../common/cta";
@use "../common/colors";
@use "../common/default-theme";

@mixin types {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 24px;
}

@mixin type {
  // text
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.09px;
  text-align: center;

  cursor: pointer;

  // layout
  display: grid;
  grid-gap: 24px;
  padding: 40px;

  // background/container
  border-radius: 8px;
  border: 1px solid colors.$blue-gray-100;
  background: colors.$white;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);

  &:hover {
    outline: 2px solid default-theme.$border;
    background: colors.$modifier-gradient-bg;
  }

  > .icon {
    place-self: center;
  }
}

@mixin type-select-widget {
  padding: 40px 32px 32px;
  margin: 0 auto;

  display: grid;
  gap: 40px;

  color: colors.$blue-gray-900;

  .title {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.36px;
  }

  .actions {
    padding: 0 24px;

    button {
      &.cancel,
      &.back {
        width: 100%;
        @include cta.link-cta;
        border-radius: 4px;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
      }
    }
  }
}
