@use "../common/default-theme";
@use "../common/colors";
@use "../widgets/type-select-widget" as type-select;

@mixin split-payment-type-select-widget {
  width: 50vw;
  max-width: 700px;
  @include type-select.type-select-widget;

  .header {
    display: grid;
    gap: 8px;
  }

  .subtitle {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-align: center;
    color: colors.$blue-gray-500;

    span {
      color: colors.$teal-600;
    }
  }

  .payment-types {
    @include type-select.types;
  }

  .payment-type {
    @include type-select.type;
  }
}

.cancel-split-payment {
  .swal2-modal {
    width: 530px !important;
    padding: 32px 24px 0 !important;
  }

  .swal2-header {
    padding: 0 !important;
    margin-bottom: 16px;
  }

  .swal2-content {
    margin-bottom: 24px !important;
    padding: 0;
    font-size: 20px !important;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .swal2-actions {
    padding: 0 !important;

    .confirm-button {
      text-transform: none !important;
    }

    .cancel-button {
      border: 1px solid colors.$blue-gray-500;
      color: colors.$blue-gray-500;
      text-transform: none !important;
    }
  }
}

.cancel-payment {
  .confirm-button {
    background: #{colors.$rose-800} !important;
    border: 1px solid #{colors.$rose-800} !important;
    border-radius: 4px !important;
    text-transform: none !important;
  }
  .cancel-button {
    border: 1px solid #{colors.$blue-gray-500} !important;
    border-radius: 4px !important;
    text-transform: none !important;
  }
}
