@use "../common/card" as card;
@use "../common/cta" as cta;
@use "../common/ghost" as ghost;
@use "../common/colors" as colors;
@use "../common/typography" as typography;

.table-booking-list-item-widget {
  border-bottom: 1px solid colors.$blue-gray-100;
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1.5fr repeat(2, 1fr) 2fr max-content;
  align-items: center;
  padding: 16px 38px 16px 24px;
  color: #{colors.$blue-gray-900};
  font-size: 14px;
  .booked-for-time-of-day {
    font-weight: 700;
  }
  &:last-child {
    border-bottom: none;
  }
  .customer-info {
    .name {
      font-weight: 500;
    }
    .mobile {
      color: #{colors.$blue-gray-900};
    }
  }

  .status {
    font-size: 14.5px !important;
    &.accepted {
      @include ghost.green;
    }
    &.new {
      @include ghost.yellow;
    }
    &.cancelled {
      @include ghost.red;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    @mixin button {
      button {
        background-color: transparent;
        font-size: 15px;
        font-weight: 700px;
        padding: 7px 16px;
        border-radius: 3px;
      }

      button[disabled] {
        border: 1px solid #{colors.$blue-gray-100};
        color: #{colors.$blue-gray-300};
      }
    }

    .print-table-booking-stub {
      margin-right: 20px;
    }

    .edit-table-booking-button {
      @include button;
      background-color: transparent;
      border: 1px solid #{colors.$blue-400};
      color: #{colors.$blue-800};
      border-radius: 4px;
      font-size: 12px;
      line-height: 18px;
      padding: 7px 16px;
      max-width: 55px;
    }
    .cancel-table-booking-button {
      margin-left: 20px;
      @include button;
      background-color: transparent;
      border: 1px solid #{colors.$rose-500};
      color: #{colors.$rose-800};
      border-radius: 4px;
      font-size: 12px;
      line-height: 18px;
      padding: 7px 16px;
      max-width: 74px;

      &.hidden {
        display: none;
      }
    }
  }
}
