@use "../common/typography";
@use "../common/cta";
@use "../common/constants";
@use "../common/colors";
@use "../common/elements";

.refund-type-select-widget {
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin: 0 auto;
  border-radius: constants.$border-radius;
  color: colors.$blue-gray-900;

  > .label {
    @include typography.h1;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-bottom: 0;
  }

  > .refund-types {
    display: grid;
    grid-auto-flow: column;
    gap: 24px;
    padding: 40px 0;

    > .refund-type {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 22vw;
      padding: 40px;

      text-align: center;
      @include typography.h2;
      margin-bottom: 0;

      box-shadow: colors.$card-shadow-light;
      background: colors.$white;
      border: 1px solid colors.$blue-gray-100;
      border-radius: constants.$border-radius;
      cursor: pointer;

      > .icon {
        @include elements.icon(48px);
        margin-bottom: 20px;
      }
    }
  }

  > .back-button {
    @include cta.link-cta;
    border: 1px solid colors.$blue-gray-500;
    border-radius: 4px;
    color: colors.$blue-gray-900;
    padding: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0 24px;
  }
}
