@use '../common/colors';

.third-party-deliveries-widget {
  display: grid;
  grid-auto-rows: min-content min-content 1fr;
  overflow: hidden;
  padding: 0 24px;

  > .categories {
    display: flex;
    margin-bottom: 24px;

    > .category {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 8px;
      padding: 12px;
      border-radius: 8px;
      background: colors.$blue-gray-50;

      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: colors.$blue-gray-800;

      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.failed {
        background: colors.$rose-50;
        color: colors.$rose-800;

        > .count {
          background: colors.$rose-800;
          color: colors.$white;
        }
      }

      &.active {
        background: colors.$blue-gray-800;
        color: colors.$white;

        > .count {
          background: colors.$light-gray-200;
          color: colors.$blue-gray-700;
        }
      }

      > .count {
        display: flex;
        justify-content: center;
        align-items: center;
        background: colors.$light-gray-200;
        border-radius: 100%;
        width: 21px;
        height: 21px;
        margin-left: 8px;

        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  > .labels {
    display: grid;
    grid-template-columns: 2.5fr repeat(4, 1fr) 1.5fr;

    &.delivery-failed {
      grid-template-columns: 3fr repeat(3, 1fr) 1.5fr;
    }

    gap: 12px;

    padding: 12px 24px;
    margin-bottom: 10px;

    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: colors.$blue-gray-500;
  }
}
