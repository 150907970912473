@use "../common/item-option-styles" as item-option-styles;
@use "../common/constants"  as constants;
@use "../common/scrollbar" as scrollbar;
@use "../common/cta"as cta;
@use '../common/colors' as colors;

.table-booking-time-of-day-list-widget {
  position: relative;
  display: grid;
  width: 70vw;
  height: 85vh;
  grid-template-columns: min-content minmax(6fr, max-content);
  overflow: hidden;

  .header {
    display: grid;
    grid-template-columns: 1fr max-content min-content;
    align-items: center;
    padding: 10px constants.$modal-horizontal-gap;
    .title {
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      letter-spacing: 0;
    }

    > button {
      min-width: 20%;
      padding: 10px 46px;
      border-radius: 8px;
      font-size: 17px;
      letter-spacing: 0.5px;
    }
  }

  .empty-list {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 21px;

    > button {
      min-width: 20%;
      padding: 13px;
      border-radius: 8px;
      font-size: 17px;
      letter-spacing: 0.5px;
      margin-top: 27px;
    }
  }

  .times-of-day-list {
    @include item-option-styles.options;
    grid-template-columns: repeat(auto-fit, minmax(136px, 1fr));
    gap: 12px;
    padding: 10px 12px 24px;

    .option {
      padding: 22px;
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: 0.5px;

      &.unavailable {
        background: colors.$light-gray-200;
        color: colors.$blue-gray-300;
      }
    }
  }

  .action {
    margin: 30px 0px 30px 0px;
    .back-button {
      @include cta.link-cta;
      color: colors.$blue-gray-900;
      border: 2px solid colors.$blue-gray-300;
      background-color: #fff;
      font-size: 15px;
      width: 100%;
    }
  }
}
