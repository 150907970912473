@use '../common/typography';
@use '../common/colors';
@use '../common/scrollbar';

.linkly-settings-widget {
  display: grid;
  grid-template-areas:
    'sub-header           sub-header'
    'login-form           help'
    'printing-settings    printing-settings';
  gap: 32px 56px;
  grid-template-columns: 1fr max-content;

  > .sub-header {
    grid-area: sub-header;
    .title {
      @include typography.h2;
      margin-bottom: 8px;
    }
    .text {
      color: #{colors.$blue-gray-700};
    }
  }

  > .linkly-pinpad-pairing-widget {
    grid-area: login-form;
  }

  > .help {
    grid-area: help;
    background: #{colors.$light-gray-50};
    padding: 24px;
    width: 424px;

    .sub-header {
      display: flex;
      color: #{colors.$blue-gray-700};
      font-size: 14px;
      font-weight: 400;
      .help-icon {
        margin-right: 8px;
      }
    }

    .content {
      display: grid;
      padding-bottom: 16px;
      font-size: 14px;
      color: #{colors.$blue-gray-900};
      font-weight: 400;

      .label {
        padding: 17.5px 0;
        @include typography.h5;
        background: url('../images/icon-chevron-down-grey.svg') no-repeat right center;
        cursor: pointer;
      }

      .open > .label {
        background: url('../images/icon-chevron-up-grey.svg') no-repeat right center;
      }

      .help-item:not(:last-child) {
        border-bottom: 1px solid grey;
      }

      .time-limit-note {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        margin-top: 20px;
        color: #{colors.$blue-gray-700};
        .info-icon {
          margin-right: 8px;
          align-self: baseline;
        }
      }

      ol {
        padding-left: 16px;
      }
    }
  }

  > .printing-settings {
    grid-area: printing-settings;

    .title {
      @include typography.h2;
      margin-bottom: 8px;
    }

    .print-last-transaction {
      border: 1px solid #{colors.$blue-gray-100};
      border-radius: 12px;
      padding: 24;
      display: grid;
      grid-template-areas:
        'header        print-button'
        'sub-header    print-button';
      grid-template-columns: 1fr max-content;

      .header {
        grid-area: header;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: colors.$blue-gray-900;
      }

      .sub-header {
        grid-area: sub-header;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: colors.$blue-gray-700;
      }

      .print-button {
        grid-area: print-button;
        color: colors.$blue-gray-500;
        border: 1px solid colors.$blue-gray-500;
        background: none;
      }
    }
  }
}
