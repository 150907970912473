@use "../common/constants" as constants;
@use "../forms/form" as form;
@use '../common/colors' as colors;
@use "../common/cta"as cta;

.table-booking-iso-date-picker-widget {
  .header {
    padding: 7px constants.$modal-horizontal-gap 13px constants.$modal-horizontal-gap;

    .title {
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      letter-spacing: 0;
    }
  }
  .current-date {
    .form-iso-date-picker {
      .DayPicker-Day--today {
        color: colors.$blue-gray-900;
        text-align: center;
        outline: 2px solid colors.$teal-500;
        outline-offset: -10px;
        border-radius: 25%;
      }
    }
  }

  .selected-date {
    .form-iso-date-picker {
      .DayPicker-Day--today {
        color: colors.$blue-gray-900;
      }
      .DayPicker-Day--highlighted {
        color: colors.$blue-gray-900;
        text-align: center;
        outline: 2px solid colors.$teal-500;
        outline-offset: -10px;
        border-radius: 25%;
      }
    }
  }

  .form-iso-date-picker {
    .DayPicker-wrapper {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 2px solid #{colors.$blue-gray-100};
      border-radius: 8px;
    }

    .DayPicker-Month {
      border-spacing: 30px 0px;
      border-collapse: separate;
    }

    .DayPicker-NavBar {
      all: unset;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      top: 35px;
      margin: 0px 20px;
    }

    .DayPicker-NavButton {
      all: unset;
      width: 1.25em;
      height: 1.25em;
      background-repeat: no-repeat;
      background-size: 20px;
      color: #8b9898;
      cursor: pointer;
    }

    .DayPicker-NavButton--prev {
      background-image: url('../images/icon-chevron-down-grey.svg');
      transform: rotate(90deg);
    }

    .DayPicker-NavButton--next {
      background-image: url('../images/icon-chevron-down-grey.svg');
      transform: rotate(270deg);
    }

    .DayPicker-Caption {
      text-align: center;
      margin-bottom: 34px;

      > div {
        font-size: 20px;
        font-weight: 700;
      }
    }

    .DayPicker-Weekday {
      color: #8b9898;
      font-size: 16px;
      line-height: 22px;
    }

    .DayPicker-WeekdaysRow {
      text-transform: uppercase;
      .DayPicker-Weekday {
        // Sunday and Monday
        &:first-child,
        &:nth-child(2) {
          &::after {
            content: 'N';
          }
        }
        // Tuesday
        &:nth-child(3) {
          &::after {
            content: 'E';
          }
        }
        // Wednesday
        &:nth-child(4) {
          &::after {
            content: 'D';
          }
        }
        // Thursday
        &:nth-child(5) {
          &::after {
            content: 'U';
          }
        }
        // Friday
        &:nth-child(6) {
          &::after {
            content: 'I';
          }
        }
        // Saturday
        &:nth-child(7) {
          &::after {
            content: 'T';
          }
        }
      }
    }

    .DayPicker-Day {
      padding: 16px;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
    }

    .DayPicker-Day--disabled.DayPicker-Day--today {
      outline: none;
      cursor: default;
      color: #{colors.$blue-gray-100};
    }

    .DayPicker-Day--disabled {
      cursor: default;
      color: #{colors.$blue-gray-100};
    }

    .DayPicker-Day--unavailable {
      color: colors.$blue-gray-100;
      outline: 2px solid colors.$blue-gray-100;
      outline-offset: -10px;
      border-radius: 25%;
    }

    .DayPickerInput > input {
      @include form.form-input();
      cursor: pointer;
    }
  }

  .action {
    margin: 29px 0px 0px 0px;
    .back-button {
      @include cta.link-cta;
      color: colors.$blue-gray-900;
      border: 2px solid colors.$blue-gray-300;
      background-color: #fff;
      font-size: 15px;
      width: 100%;
    }
  }
}
